import * as React from 'react';
import PropTypes from 'prop-types';
import { useEffect } from 'react';

// material-ui
import OutlinedInput from '@mui/material/OutlinedInput';

// assets
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

const DebouncedInput = ({value: initialValue, onFilterChange, debounce = 500, size,
                            startAdornment = <SearchOutlinedIcon />, ...props }) => {
    const valueWasChanged = React.useRef(false);
    const firstInitialValue = React.useRef(initialValue);
    const [value, setValue] = React.useState(initialValue);

    if (!valueWasChanged.current) {
        firstInitialValue.current = initialValue;

        if (value !== initialValue) {
            setValue(initialValue);
        }
    }

    const handleInputChange = (event) => {
        if (!valueWasChanged.current && event.target.value === firstInitialValue.current) {
            return;
        }

        if (value !== event.target.value) {
            valueWasChanged.current = true;
            setValue(event.target.value);
        }
    };

    useEffect(() => {
        if (!valueWasChanged.current) {
            return;
        }

        const timeout = setTimeout(() => {
            onFilterChange(value);
        }, debounce);

        return () => clearTimeout(timeout);
    }, [value]);

    return (
        <OutlinedInput
            {...props}
            value={value}
            onChange={handleInputChange}
            sx={{ minWidth: 100, ...(props.sx || {})}}
            {...(startAdornment && { startAdornment })}
            {...(size && { size })}
        />
    );
};

export default DebouncedInput;

DebouncedInput.propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onFilterChange: PropTypes.func,
    debounce: PropTypes.number,
    size: PropTypes.any,
    startAdornment: PropTypes.any,
    SearchOutlined: PropTypes.any,
    props: PropTypes.any
};
