import React, { useRef, useReducer, useEffect, useCallback } from 'react';
import {
    Stack,
    Dialog,
    DialogContent,
    Button,
    Typography,
    Paper,
    InputBase,
    Divider,
    IconButton,
    CircularProgress,
    Box
} from '@mui/material';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';
import { useTheme } from '@mui/material/styles';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { getPresentationLinkAction } from '../../store/actions/customQuotes';
import AlertComponent from "../../components/AlertComponent";

dayjs.extend(utc);
dayjs.extend(timezone);

// Initial State and Reducer
const initialState = {
    linkInfo: null,
    isLoading: true,
    copied: false,
    refreshNeeded: false
};

function reducer(state, action) {
    switch (action.type) {
        case 'SET_LINK_INFO':
            return { ...state, linkInfo: action.payload, isLoading: false, refreshNeeded: false };
        case 'START_LOADING':
            return { ...state, isLoading: true };
        case 'SET_COPIED':
            return { ...state, copied: action.payload };
        case 'SET_REFRESH':
            return { ...state, refreshNeeded: true };
        default:
            return state;
    }
}

const copyTextFallbackWithRange = (text) => {
    const tempElement = document.createElement('div');
    tempElement.textContent = text;
    document.body.appendChild(tempElement);

    const range = document.createRange();
    range.selectNodeContents(tempElement);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);

    try {
        document.execCommand('copy');
        console.log('Text copied!');
    } catch (err) {
        console.error('Copy failed', err);
    }

    selection.removeAllRanges();
    document.body.removeChild(tempElement);
};

const SharePresentationLinkDialog = ({ currentUser, customQuoteVersion, closeHandler }) => {
    const theme = useTheme();
    const inputRef = useRef(null);
    const [state, dispatch] = useReducer(reducer, initialState);

    const { linkInfo, isLoading, copied, refreshNeeded } = state;

    useEffect(() => {
        const fetchLink = async () => {
            dispatch({ type: 'START_LOADING' });
            const result = await getPresentationLinkAction(
                customQuoteVersion.custom_quote_id,
                customQuoteVersion.id,
                refreshNeeded
            );
            dispatch({ type: 'SET_LINK_INFO', payload: result });
        };

        if (isLoading || refreshNeeded) {
            try {
                fetchLink();
            } catch (e) {
                AlertComponent.error(e.response.data.message);
            }
        }
    }, [isLoading, refreshNeeded, customQuoteVersion]);

    const copyLinkHandler = useCallback(async (closeDialog) => {
        const input = inputRef.current.querySelector('input');
        try {
            if (navigator?.clipboard?.writeText) {
                await navigator.clipboard.writeText(input.value);
            } else {
                copyTextFallbackWithRange(input.value);
            }

            dispatch({ type: 'SET_COPIED', payload: true });
            setTimeout(() => {
                dispatch({ type: 'SET_COPIED', payload: false });
                if (closeDialog) {
                    closeHandler();
                }
            }, 1000);
        } catch (err) {
            alert(`Error copying link: ${err.message}`);
        }
    }, []);


    const refreshLinkHandler = useCallback(() => {
        dispatch({ type: 'SET_REFRESH' });
    }, []);


    const expDate = linkInfo?.link_expiration_time
        ? dayjs.utc(linkInfo.link_expiration_time).tz(currentUser.timezone).format('MM/DD/YYYY hh:mm:ss')
        : null;

    const linkRefreshedAt = linkInfo?.link_refreshed_at
        ? dayjs.utc(linkInfo.link_refreshed_at).tz(currentUser.timezone)
        : null;

    const displayRefreshButton = linkRefreshedAt
        ? dayjs().diff(linkRefreshedAt, 'hour') >= 3
        : false;

    return (
        <Dialog aria-labelledby='share-dialog' open maxWidth='xs' fullWidth={true}>
            <DialogContent
                sx={{
                    padding: 4
                }}
            >
                <Stack spacing={3}>
                    <Typography variant='h3' align='center'>
                        Customer Presentation Link
                    </Typography>

                    <Typography
                        variant={'body1'}
                        sx={{
                            textAlign: 'center',
                            color: theme.palette.text.secondary,
                        }}
                    >
                        Copy the secure link to share it
                    </Typography>

                    {isLoading ? (
                        <Box textAlign='center'>
                            <CircularProgress size={40} />
                        </Box>
                    ) : (
                        <Box>
                            <Paper
                                component='form'
                                variant='outlined'
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    borderColor: theme.palette.text.secondary,
                                    mb: 2,
                                    position: 'relative'
                                }}
                            >
                                <InputBase
                                    sx={{ ml: 1, flex: 1 }}
                                    value={linkInfo?.link || ''}
                                    readOnly
                                    ref={inputRef}
                                />

                                <Divider sx={{ height: 36, ml: 1 }} orientation='vertical' />

                                <IconButton
                                    sx={{
                                        color: copied
                                            ? theme.palette.success.main
                                            : theme.palette.text.primary,
                                        transition: 'color 0.3s'
                                    }}
                                    onClick={() => copyLinkHandler(false)}
                                >
                                    <ContentCopyOutlinedIcon
                                        sx={{
                                            width: '20px'
                                        }}
                                    />
                                </IconButton>

                                <Typography
                                    variant='body2'
                                    color={theme.palette.success.main}
                                    sx={{
                                        textAlign: 'right',
                                        position: 'absolute',
                                        right: 0,
                                        bottom: '-25px'
                                    }}
                                >
                                    {copied ? 'Copied!' : ''}
                                </Typography>
                            </Paper>
                            <Stack direction='row' spacing={1} alignItems='center'>
                                <Typography>
                                    Link valid until: {expDate}
                                </Typography>

                                {displayRefreshButton && (
                                    <IconButton onClick={refreshLinkHandler}>
                                        <AutorenewOutlinedIcon />
                                    </IconButton>
                                )}
                            </Stack>
                        </Box>
                    )}

                    <Stack
                        direction={'row'}
                        spacing={2}
                    >
                        <Button
                            sx={{
                                width: '100%',
                                color: 'black',
                                borderColor: theme.palette.secondary.light
                            }}
                            variant='outlined'
                            onClick={closeHandler}>
                            Close
                        </Button>

                        <Button
                            sx={{
                                width: '100%'
                            }}
                            variant='contained'
                            onClick={() => copyLinkHandler(true)}
                        >
                            Copy Link
                        </Button>
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default SharePresentationLinkDialog;