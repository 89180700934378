import * as React from 'react';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

// material-ui
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Pagination from '@mui/material/Pagination';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

// ==============================|| TABLE PAGINATION ||============================== //

export default function TablePagination({paginationChangeHandler, paginationObject, initialPageSize }) {
    const [open, setOpen] = useState(false);
    let options = [25, 50, 100];

    if (initialPageSize) {
        options = [...options, initialPageSize]
            .filter((item, index) => [...options, initialPageSize].indexOf(item) === index)
            .sort(function (a, b) {
                return a - b;
            });
    }

    const setPageSize = (size) => {
        paginationChangeHandler({
            paramName: 'perPage',
            value: size
        });
    };

    const setPageIndex = (index) => {
        paginationChangeHandler({
            paramName: 'page',
            value: index + 1
        });
    };

    // eslint-disable-next-line
    useEffect(() => setPageSize(initialPageSize || 25), []);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleChangePagination = (event, value) => {
        setPageIndex(value - 1);
    };

    const handleChange = (event) => {
        setPageSize(Number(event.target.value));
    };

    return (
        <Grid spacing={1} container alignItems="center" justifyContent="space-between" sx={{ width: 'auto' }}>
            <Grid item>
                <Stack direction="row" spacing={1} alignItems="center">
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Typography variant="caption" color="secondary">
                            Row per page
                        </Typography>
                        <FormControl sx={{ m: 1 }}>
                            <Select
                                id="demo-controlled-open-select"
                                open={open}
                                name={'rowsPerPage'}
                                onClose={handleClose}
                                onOpen={handleOpen}
                                value={paginationObject.perPage}
                                onChange={handleChange}
                                size="small"
                                sx={{ '& .MuiSelect-select': { py: 0.75, px: 1.25 } }}
                            >
                                {options.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Stack>
                    <Typography variant="caption" color="secondary">
                        Go to
                    </Typography>
                    <TextField
                        size="small"
                        type="number"
                        value={paginationObject.page}
                        onChange={(e) => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0;
                            setPageIndex(page);
                        }}
                        sx={{ '& .MuiOutlinedInput-input': { py: 0.75, px: 1.25, width: 36 } }}
                    />
                </Stack>
            </Grid>
            <Grid item sx={{ mt: { xs: 2, sm: 0 } }}>
                <Pagination
                    sx={{ '& .MuiPaginationItem-root': { my: 0.5 } }}
                    count={Math.floor(paginationObject.total / paginationObject.perPage) + 1}
                    page={paginationObject.page}
                    onChange={handleChangePagination}
                    color="primary"
                    variant="combined"
                    showFirstButton
                    showLastButton
                />
            </Grid>
        </Grid>
    );
}

TablePagination.propTypes = {
    paginationChangeHandler: PropTypes.func,
    paginationObject: PropTypes.object,
    initialPageSize: PropTypes.number
};
