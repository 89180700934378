import { createSlice } from '@reduxjs/toolkit'
import {STATE_STATUSES} from "../../utils/helpers";

const initialState = {
    activeStore: undefined,
    items: [],
    status: STATE_STATUSES.INIT,
};

export const storesSlice = createSlice({
    name: 'stores',
    initialState,
    reducers: {
        setPendingStatus: (state) => {
            state.status = STATE_STATUSES.PENDING;
        },

        setReadyStatus: (state) => {
            state.status = STATE_STATUSES.READY;
        },

        setStores: (state, action) => {
            state.items = action.payload;
        },

        setActiveStore: (state, action) => {
            state.activeStore = action.payload;
        },

        updateActiveStoreCompany: (state, action) => {
            state.activeStore.company = action.payload;
        }
    },
});

export const {
    setPendingStatus,
    setReadyStatus,
    setStores,
    setActiveStore,
    updateActiveStoreCompany,
} = storesSlice.actions;

export default storesSlice.reducer;