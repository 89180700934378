import * as React from 'react';
import { getProductAction } from "../../store/actions/products";
import { useParams } from 'react-router-dom';
import {
    getRequiredFieldsCounts,
    getInitialStateByDefaultValues
} from "../../components/dynamicForms/utils";

import DefaultLayout from "../../components/layouts/DefaultLayout/DefaultLayout";
import { useSelector} from "react-redux";

import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import Grid from "@mui/material/Grid";
import ImageGallery from "react-image-gallery";

import CircularProgress from '@mui/material/CircularProgress';
import Box from "@mui/material/Box";
import CartWidget from "../../components/layouts/DefaultLayout/CartWidget/CartWidget";
import {createCartItemAction} from "../../store/actions/cart";

import Divider from "@mui/material/Divider";
import { useTheme } from '@mui/material/styles';
import InformationDialog from "./components/InformationDialog";
import TitleWithProductId from "./components/TitleWithProductId";
import FeaturesAndOptions from "./components/FeaturesAndOptions";
import ProductDetailsSkeleton from "./components/ProductDetailsSkeleton";
import {getJsonObject, ROLE_NAMES} from "../../utils/helpers";
import {Typography} from "@mui/material";
import Stack from "@mui/material/Stack";
import AlertComponent from "../../components/AlertComponent";

const VideoSlide = React.forwardRef(({ videoUrl }, ref) => {
    const videoRef = React.useRef(null);
    const [isPlaying, setIsPlaying] = React.useState(false);

    const handlePlay = () => {
        videoRef.current.play();
        setIsPlaying(true);
    };

    const handleEnded = () => {
        setIsPlaying(false);
    };

    React.useImperativeHandle(ref, () => ({
        stopVideo() {
            if (videoRef.current) {
                videoRef.current.pause();
                videoRef.current.currentTime = 0;
                setIsPlaying(false);
            }
        }
    }));

    return (
        <div className="video-wrapper" style={{ position: 'relative', width: '100%' }}>
            <video
                ref={videoRef}
                controls
                style={{ width: '100%' }}
                onEnded={handleEnded}
                onPause={handleEnded}
                onPlay={() => setIsPlaying(true)}
            >
                <source src={videoUrl} type="video/mp4" />
                Your browser does not support the video tag.
            </video>

            {!isPlaying && (
                <button
                    onClick={handlePlay}
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        background: 'rgba(255, 100, 100, 1)',
                        color: 'white',
                        border: 'none',
                        borderRadius: '50%',
                        width: '50px',
                        height: '50px',
                        fontSize: '20px',
                        cursor: 'pointer',
                        paddingLeft: '11px',
                        paddingBottom: '4px'
                    }}
                >
                    ▶
                </button>
            )}
        </div>
    );
});

const ProductDetailsPage = () => {
    React.useEffect(() => {
        import('react-image-gallery/styles/css/image-gallery.css');
    }, []);

    const videoPlayerRef = React.useRef(null);

    const handleSlide = () => {
        if (videoPlayerRef.current) {
            videoPlayerRef.current.stopVideo();
        }
    };

    const product = useSelector(store => store.products.selectedItem);
    const media = getJsonObject(product?.media)?.media;

    const currentUser = useSelector(store => store.auth.authenticatedUser);
    const userCanAddProducts = [ROLE_NAMES.GLOBAL_ADMIN, ROLE_NAMES.SALES_MANAGER, ROLE_NAMES.CUSTOM_SPECIALIST, ROLE_NAMES.SALESPERSON].includes(currentUser?.role?.name);

    const images = media?.map(obj => {
        return (!obj.video) ?
            ({
                original: obj.original,
                thumbnail: obj.thumbnail || obj.original,
            }) :
            ({
                original: obj.thumbnail,
                thumbnail: obj.thumbnail,
                renderItem: () => (<VideoSlide ref={videoPlayerRef} videoUrl={obj.video} />)
            });
    }) || [
        {
            original: '/no-image.png',
            thumbnail: '/no-image.png'
        },
    ];

    const { items } = useSelector(store => store.cart);

    const options = React.useMemo(() => getJsonObject(product?.options)?.options, [product?.options]);

    const [enteredOptions, setEnteredOptions] = React.useState({});
    const [isCartWidgetOpened, setIsCartWidgetOpened] = React.useState(false);
    const [isInfoDialogOpen, setIsInfoDialogOpen] = React.useState(false);
    const [productWasAdded, setProductWasAdded] = React.useState(false);

    const [isProductLoaded, setIsProductLoaded] = React.useState(false);

    const navigate = useNavigate();
    const theme = useTheme();

    const { id } = useParams();

    React.useEffect(() => {
        const getProductFunction = async() => {
            try {
                await getProductAction(id);
                setIsProductLoaded(true);
            } catch (e) {
                AlertComponent.error(e.response.data.message);
            }
        };
        getProductFunction();
    }, [id]);

    React.useEffect(() => {
        setEnteredOptions(getInitialStateByDefaultValues(options));
    }, [product, options]);

    const requiredFieldsCounts = getRequiredFieldsCounts(enteredOptions, options);

    const putToCartHandler = async () => {
        try {
            setProductWasAdded(true);

            const cartItems = items.filter(item => item.product_id === product.id);
            let isEqual = false;

            cartItems.forEach(cartItem => {
                if (isEqual) {
                    return;
                }

                const cartItemOptions = JSON.parse(cartItem.options);
                const cartItemOptionsKeys = Object.keys(cartItemOptions);

                if (cartItemOptionsKeys.length !== Object.keys(enteredOptions).length) {
                    return;
                }

                let flag = true;

                cartItemOptionsKeys.forEach(key => {
                    if (cartItemOptions[key] !== enteredOptions[key]) {
                        flag = false;
                    }
                });

                isEqual = flag;
            });

            if (cartItems.length > 0 && isEqual) {
                setIsInfoDialogOpen(true);
                return;
            }

            const addressOption = options.find(option => option.type === 'address');

            if (addressOption?.name) {
                const address = enteredOptions[addressOption.name];
                const regex = /\(lat: ([\d.-]+), lng: ([\d.-]+)\)/;
                const match = address.match(regex);
                const lat = parseFloat(match[1]);
                const lng = parseFloat(match[2]);

                enteredOptions['Lat'] = lat;
                enteredOptions['Lng'] = lng;
                enteredOptions['Zoom'] = addressOption.zoom || 10;
            }

            await createCartItemAction({
                product_id: id,
                quantity: 1,
                options: JSON.stringify(enteredOptions)
            });

            setIsCartWidgetOpened(true);
        } catch (e) {
            AlertComponent.error(e.response.data.message);
        }
    };

    const optionChangeHandler = (value) => {
        setProductWasAdded(false);
        setEnteredOptions(value);
    };

    return (
        <DefaultLayout>
            <Box
                sx={{
                    padding: "16px",
                    maxWidth: "1488px",
                    margin: "0 auto",
                }}
            >
                <Button
                    variant="contained"
                    color={'secondary'}
                    onClick={() => navigate("/")}
                    sx={{
                        marginBottom: "32px",
                        width: "80px"
                    }}
                >
                    Back
                </Button>

                {!isProductLoaded &&
                    <ProductDetailsSkeleton/>
                }

                {isProductLoaded &&
                <Grid container spacing={8}>
                    <Grid
                        item md={4} xs={12}
                        sx={{
                            '& .image-gallery-content.image-gallery-thumbnails-bottom:not(.fullscreen) .image-gallery-thumbnails-container': {
                                marginTop: "20px",
                                'button': {
                                    margin: "0 5px",
                                    width: 'calc(33.33% - 8px)'
                                }
                            },

                            '& .image-gallery-content.image-gallery-thumbnails-bottom:not(.fullscreen) .image-gallery-slide img': {
                                width: '80%'
                            },

                            '& .image-gallery-content .image-gallery-slide .image-gallery-image': {
                                maxHeight: 'calc(100vh - 110px)'
                            },
                            '& .image-gallery-right-nav .image-gallery-svg': {
                                width: '30px',
                                height: '60px'
                            },
                            '& .image-gallery-left-nav .image-gallery-svg': {
                                width: '30px',
                                height: '60px'
                            },

                            '& button.image-gallery-icon': {
                                padding: 0
                            }
                        }}
                    >
                        <ImageGallery
                            items={images}
                            showNav={true}
                            showPlayButton={false}
                            onSlide={handleSlide}
                        />
                    </Grid>

                    <Grid
                        item md={8} xs={12}
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between"
                        }}
                    >
                        <TitleWithProductId
                            product={product}
                        />

                        <FeaturesAndOptions
                            product={product}
                            options={options}
                            enteredOptions={enteredOptions}
                            optionChangeHandler={optionChangeHandler}
                        />

                        <Divider
                            sx={{
                                borderColor: theme.palette.secondary.light,
                                marginBottom: '16px'
                            }}
                        />
                        <Box
                            display={"flex"}
                            justifyContent={"space-between"}
                            flexWrap={'wrap'}
                        >
                            <Box
                                display={"flex"}
                                alignItems={"center"}
                                justifyContent={"center"}
                            >
                                <CircularProgress
                                    size={20}
                                    variant="determinate"
                                    value={requiredFieldsCounts.filled * 100 / requiredFieldsCounts.required}
                                    sx={{
                                        marginRight: "10px"
                                    }}
                                />
                                {requiredFieldsCounts.filled}/{requiredFieldsCounts.required} Mandatory Fields Filled
                            </Box>

                            <Stack
                                direction={'row'}
                                sx={{
                                    alignItems: 'center',
                                    flexWrap: 'wrap'
                                }}
                            >
                                <Typography
                                    variant={'h5'}
                                    sx={{
                                        marginRight: 2
                                    }}
                                >
                                    $ {product.price}
                                </Typography>

                                <Button
                                    variant="contained"
                                    disabled={requiredFieldsCounts.filled < requiredFieldsCounts.required || productWasAdded || !userCanAddProducts}
                                    onClick={putToCartHandler}
                                >
                                    Add to Cart
                                </Button>
                            </Stack>
                        </Box>

                        {
                            !userCanAddProducts &&
                            <Typography
                                color={'secondary'}
                                sx={{
                                    textAlign: 'right',
                                    marginTop: '5px'
                                }}
                            >
                                You cannot add the product due to role restrictions.
                            </Typography>
                        }

                    </Grid>
                </Grid>
                }
            </Box>

            <CartWidget
                isOpened={isCartWidgetOpened}
                setIsOpened={setIsCartWidgetOpened}
            />

            <InformationDialog
                isOpen={isInfoDialogOpen}
                handleClose={() => setIsInfoDialogOpen(false)}
            />
        </DefaultLayout>
    )
};

export default ProductDetailsPage;
