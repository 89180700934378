import { configureStore, combineReducers } from '@reduxjs/toolkit';
import {logger} from 'redux-logger'
import auth from './reducers/auth';
import user from './reducers/user';
import products from './reducers/products';
import cart from './reducers/cart';
import settings from './reducers/settings';
import stores from './reducers/stores';
import orders from './reducers/orders';
import customQuotes from './reducers/customQuotes';
import checkout from './reducers/checkout';
import company from './reducers/company';

const combinedReducer = combineReducers({
    auth,
    user,
    products,
    cart,
    settings,
    stores,
    orders,
    customQuotes,
    checkout,
    company,
});

const store = configureStore({
    reducer: combinedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
            immutableCheck: false,
        })
            .concat(logger),
});


const { dispatch } = store;

export {store, dispatch}