import {authApi} from './apiService'

const changePassword = async (userId, data) => authApi.patch(`users/${userId}/password`, data);
const setActiveStore = async (storeId) => authApi.put(`users/me/set-active-store/${storeId}`, {});
const getActiveStore = async () => authApi.get(`users/me/get-active-store`);

const apiCalls = {
    changePassword,
    setActiveStore,
    getActiveStore
};

export default apiCalls;