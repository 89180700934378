import * as React from 'react';
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material";

const OrderCanceledDialog = ({ mainText, bottomText, closeDialogHandler }) => {
    const theme = useTheme();

    React.useEffect(() => {
        setTimeout(() => {
            closeDialogHandler();
        }, 2000);
    }, [closeDialogHandler]);

    return (
        <Dialog
            open={true}
        >
            <Box
                padding={'32px'}
                textAlign={'center'}
                maxWidth={'470px'}
            >
                <Box
                    sx={{
                        margin: '30px auto 10px auto',
                        '& img': {
                            width: '80px'
                        }
                    }}
                >
                    <img src={'/cancel.png'} alt={'cancel'}/>
                </Box>
                <Typography
                    variant={'h4'}
                    sx={{
                        marginBottom: '16px'
                    }}
                >
                    {mainText}
                </Typography>
                <Typography
                    variant={'body1'}
                    sx={{
                        margin: '0 auto 32px auto',
                        maxWidth: '320px',
                        textAlign: 'center',
                        color: theme.palette.text.secondary
                    }}
                >
                    {bottomText}
                </Typography>
            </Box>
        </Dialog>
    );
};

export default OrderCanceledDialog;