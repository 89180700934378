import productsService from "../../services/productsService";
import {
    setProducts,
    setSelectedProduct,
    setPendingStatus,
    setReadyStatus
} from "../reducers/products";
import { dispatch } from "../index";

// export const getAllProductsAction = async () => {
//     dispatch(setPendingStatus());
//
//     const response = await productsService.getAllProducts();
//
//     dispatch(setProducts(response.data.data.products));
//     dispatch(setReadyStatus());
// };

export const getAllStoreProductsAction = async (storeId) => {
    dispatch(setPendingStatus());

    const response = await productsService.getAllStoreProducts(storeId);

    dispatch(setProducts(response.data.data.products));
    dispatch(setReadyStatus());
};

export const getProductAction = async (productId) => {
    dispatch(setPendingStatus());

    const response = await productsService.getProduct(productId);

    dispatch(setSelectedProduct(response.data.data.product));
    dispatch(setReadyStatus());
};