import * as React from 'react';
import {CUSTOM_QUOTE_STATUSES, CUSTOM_QUOTE_VERSION_STATUSES, ROLE_NAMES} from "../../utils/helpers";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import {useTheme} from "@mui/material";

const CustomQuoteActionButtons = ({ selectedVersion, currentUserRole, customQuoteStatus, feedbacks, isCustomQuoteLoaded, setOpenOriginalSubmissionDialog, setOpenSharePresentationLinkDialog, setOpenFeedbackDialog, setOpenShippingAddressDialog, setOpenCancelSubmissionDialog}) => {

    const theme = useTheme();

    const shouldShowAddFeedbackButton =
        (customQuoteStatus !== CUSTOM_QUOTE_STATUSES.ORDERED) &&
        selectedVersion &&
        (![CUSTOM_QUOTE_VERSION_STATUSES.APPROVED, CUSTOM_QUOTE_VERSION_STATUSES.REJECTED].includes(selectedVersion.status)) &&
        (feedbacks?.length === 0);

    const shouldShowApproveButton =
        ![CUSTOM_QUOTE_STATUSES.ORDERED, CUSTOM_QUOTE_STATUSES.CANCELED].includes(customQuoteStatus) &&
        ![CUSTOM_QUOTE_VERSION_STATUSES.REJECTED, CUSTOM_QUOTE_VERSION_STATUSES.APPROVED].includes(selectedVersion?.status) &&
        ![ROLE_NAMES.COMPANY_ADMIN, ROLE_NAMES.FINANCIAL_MANAGER].includes(currentUserRole?.name) &&
        selectedVersion;

    const shouldShowCancelSubmissionButton = ![CUSTOM_QUOTE_STATUSES.CANCELED, CUSTOM_QUOTE_STATUSES.ORDERED].includes(customQuoteStatus);

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '10px 16px',
                alignItems: 'center'
            }}
        >
            <Typography
                variant={'h4'}
            >
                {selectedVersion ? `Viewing: Version ${selectedVersion.name}` : 'No Version Selected'}
            </Typography>
            <Stack spacing={1} direction={'row'}>
                {shouldShowCancelSubmissionButton &&
                    <Button
                        variant={'outlined'}
                        color={'secondary'}
                        sx={{
                            color: 'black',
                            borderColor: theme.palette.secondary.light
                        }}
                        disabled={!isCustomQuoteLoaded}
                        onClick={() => {
                            setOpenCancelSubmissionDialog(true);
                        }}
                    >
                        Cancel Submission
                    </Button>
                }

                <Button
                    variant={'outlined'}
                    color={'secondary'}
                    sx={{
                        color: 'black',
                        borderColor: theme.palette.secondary.light
                    }}
                    disabled={!isCustomQuoteLoaded}
                    onClick={() => {
                        setOpenOriginalSubmissionDialog(true);
                    }}
                >
                    View Original Submission
                </Button>

                {selectedVersion &&
                    <Button
                        variant={'outlined'}
                        color={'secondary'}
                        sx={{
                            color: 'black',
                            borderColor: theme.palette.secondary.light
                        }}
                        disabled={!isCustomQuoteLoaded}
                        onClick={() => {
                            setOpenSharePresentationLinkDialog(true);
                        }}
                    >
                        Share Presentation Link
                    </Button>
                }

                {shouldShowAddFeedbackButton &&
                    <Button
                        variant={'outlined'}
                        color={'secondary'}
                        sx={{
                            color: 'black',
                            borderColor: theme.palette.secondary.light
                        }}
                        disabled={!isCustomQuoteLoaded}
                        onClick={() => {
                            setOpenFeedbackDialog(true);
                        }}
                    >
                        Submit Feedback
                    </Button>
                }

                {shouldShowApproveButton &&
                    <Button
                        variant={'contained'}
                        disabled={!isCustomQuoteLoaded}
                        onClick={() => {
                            setOpenShippingAddressDialog(true);
                        }}
                    >
                        Approve & Order
                    </Button>
                }
            </Stack>
        </Box>
    );
};

export default CustomQuoteActionButtons;