import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {generateSelectedOptionsWithValues} from "../../../dynamicForms/utils";
import ProductQuantity from "../../../ProductQuantity";
import {getProductMainImage} from "../../../../utils/helpers";

const SingleItem = ({item, setSelectedItemId }) => {
    const optionsWithValues = generateSelectedOptionsWithValues(item);

    return (
        <Box
            margin={"20px 0"}
        >
            <Box
                display={'flex'}
                flexWrap={'wrap'}
                justifyContent={'center'}
            >
                <Box
                    minWidth={'110px'}
                    maxWidth={'20%'}
                    flexGrow={1}
                    margin={"0 16px"}
                >
                    <img
                        src={getProductMainImage(item.product)}
                        alt={'Main product\'s representation'}
                        loading="lazy"
                        width={'100%'}
                    />
                </Box>

                <Box
                    margin={"0 16px"}
                    flexGrow={1}
                >
                    <Typography component={"div"} variant={"h5"}>
                        {item.product.title}
                    </Typography>

                    <Typography component={"div"} variant={"h8"}>
                        {item.product.sku}
                    </Typography>

                    {optionsWithValues.length > 0 &&
                    <Typography
                        component={"div"}
                        variant={"h8"}
                    >
                        <Box
                            margin={'20px 0 10px 0'}
                            fontWeight={600}
                        >
                            Options:
                        </Box>

                        {optionsWithValues.map(option => {
                            let value = option.value;
                            const isBoolean = typeof(value) === 'boolean';

                            if (isBoolean) {
                                value = value ? 'Yes' : 'No';
                            }

                            return <div key={option.title}>{option.title}: {value}</div>
                        })}
                    </Typography>
                    }
                </Box>
            </Box>

            <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                flexWrap={"wrap-reverse"}
                marginTop={"20px"}

            >
                <Button
                    variant="outlined"
                    onClick={() => {
                        setSelectedItemId(item.id)
                    }}
                >
                    Remove item
                </Button>

                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center"
                    }}
                >
                    <Typography>
                        Quantity:
                    </Typography>

                    <ProductQuantity
                        item={item}
                    />
                </Box>

            </Box>
        </Box>
    );
};

export default SingleItem;