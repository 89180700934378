import React, {useEffect} from "react";
import {getAuthenticatedUserAction} from "../store/actions/auth";
import {useDispatch, useSelector} from "react-redux";

export default function AuthProvider ({children}) {
    const dispatch = useDispatch();
    const { authenticatedUser } = useSelector(store => store.auth);

    useEffect(() => {
        (async () => {
            if (authenticatedUser) {
                return;
            }

            try {
                const authToken = window.localStorage.getItem('auth_token');

                if (authToken) {
                    await getAuthenticatedUserAction();
                }
            } catch (err) {
                console.error(err);
            }
            finally {
            }
        })();
    }, [dispatch, authenticatedUser]);

    return (
        <>
            {children}
        </>
    )
};