import * as React from 'react';
import Button from "@mui/material/Button";
import { useNavigate } from 'react-router-dom';
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTheme } from '@mui/material/styles';
import {convertDraftOrderToCartItemsAction, deleteOrderAction} from "../../store/actions/orders";
import AlertComponent from "../../components/AlertComponent";

const OrderActionDialogs = ({ order, handleCancelOrderClick, openEditDraftDialog, setOpenEditDraftDialog,
                                openWarningDialog, setOpenWarningDialog, openConfirmationDialog, setOpenConfirmationDialog,
                                openConvertDraftDialog, setOpenConvertDraftDialog}) => {
    const navigate = useNavigate();
    const theme = useTheme();

    return (
        <>
            {openEditDraftDialog &&
            <Dialog
                open={true}
                onClose={() => setOpenEditDraftDialog(false)}
            >
                <Box
                    padding={'32px'}
                    textAlign={'center'}
                    maxWidth={'470px'}
                >
                    <Box
                        sx={{
                            margin: '30px auto 10px auto',
                            '& img': {
                                width: '80px'
                            }
                        }}
                    >
                        <img src={'warning.png'} alt={'warning'}/>
                    </Box>
                    <Typography
                        variant={'h4'}
                        sx={{
                            marginBottom: '32px'
                        }}
                    >
                        You have items in your cart!
                    </Typography>
                    <Typography
                        variant={'body1'}
                        sx={{
                            margin: '0 auto 32px auto',
                            maxWidth: '320px',
                            textAlign: 'center',
                            color: theme.palette.text.secondary
                        }}
                    >
                        <strong>1. Checkout the items in your cart.</strong> Your saved draft order will stay the same.
                        <br/><br/>
                        <strong>2. Add some items from your draft to your cart.</strong> The remaining items in the draft will be deleted.
                    </Typography>

                    <Button
                        sx={{
                            width: '180px',
                            margin: '0 8px'
                        }}
                        variant="outlined"
                        color={'secondary'}
                        onClick={() => {
                            navigate(`/convert-draft-order/${order?.id}`);
                        }}
                    >
                        Add Draft Items To Cart
                    </Button>
                    <Button
                        sx={{
                            width: '180px',
                            margin: '0 8px'
                        }}
                        variant="contained"
                        color={'primary'}
                        onClick={() => {navigate('/checkout')}}
                    >
                        Checkout Cart Items
                    </Button>
                </Box>
            </Dialog>
            }

            {openWarningDialog &&
            <Dialog
                open={true}
                onClose={() => setOpenWarningDialog(false)}
            >
                <Box
                    padding={'32px'}
                    textAlign={'center'}
                    maxWidth={'470px'}
                >
                    <Box
                        sx={{
                            margin: '16px auto 10px auto',
                            '& img': {
                                width: '80px'
                            }
                        }}
                    >
                        <img src={'warning.png'} alt={'warning'}/>
                    </Box>

                    <Typography
                        variant={'h4'}
                        sx={{
                            marginBottom: '32px',
                        }}
                    >
                        This draft order belongs to a different store location.
                    </Typography>
                    <Typography
                        variant={'body1'}
                        sx={{
                            margin: '0 auto 32px auto',
                            maxWidth: '320px',
                            textAlign: 'center',
                            color: theme.palette.text.secondary
                        }}
                    >
                        <p>
                            Please switch to the correct store location before proceeding.
                        </p>
                    </Typography>

                    <Button
                        sx={{
                            width: '50%'
                        }}
                        variant="contained"
                        color={'primary'}
                        onClick={() => setOpenWarningDialog(false)}
                    >
                        OK
                    </Button>
                </Box>
            </Dialog>
            }

            {openConfirmationDialog &&
            <Dialog
                open={true}
            >
                <Box
                    padding={'32px'}
                    textAlign={'center'}
                    width={'470px'}
                >
                    <Box
                        sx={{
                            margin: '16px auto 10px auto',
                            '& img': {
                                width: '80px'
                            }
                        }}
                    >
                        <img src={'warning.png'} alt={'warning'}/>
                    </Box>

                    <Typography
                        variant={'h4'}
                        sx={{
                            marginBottom: '32px',
                        }}
                    >
                        Do you really want to cancel this order?
                    </Typography>

                    <Button
                        sx={{
                            width: '180px',
                            margin: '0 8px',
                            backgroundColor: theme.palette.secondary.light,
                            color: theme.palette.secondary.dark
                        }}
                        variant="contained"
                        size='small'
                        onClick={() => setOpenConfirmationDialog(false)}
                    >
                        No
                    </Button>
                    <Button
                        sx={{
                            width: '180px',
                            margin: '0 8px'
                        }}
                        variant="contained"
                        size='small'
                        color={'error'}
                        onClick={handleCancelOrderClick}
                    >
                        Yes
                    </Button>
                </Box>
            </Dialog>
            }

            {openConvertDraftDialog &&
            <Dialog
                open={true}
                onClose={() => setOpenConvertDraftDialog(false)}
            >
                <Box
                    padding={'32px'}
                    textAlign={'center'}
                    maxWidth={'470px'}
                >
                    <Box
                        sx={{
                            margin: '16px auto 10px auto',
                            '& img': {
                                width: '80px'
                            }
                        }}
                    >
                        <img src={'rotate.png'} alt={'rotate'}/>
                    </Box>
                    <Typography
                        variant={'h4'}
                        sx={{
                            marginBottom: '16px'
                        }}
                    >
                        Convert Draft Order
                    </Typography>
                    <Typography
                        variant={'body1'}
                        sx={{
                            margin: '0 auto 32px auto',
                            maxWidth: '320px',
                            textAlign: 'center',
                            color: theme.palette.text.secondary
                        }}
                    >
                        Converting this draft order will move the draft items to your cart. If you proceed, the draft order will be deleted.
                        <br/><br/>
                        To save it as a draft again, you will need to resave it on the checkout page.
                    </Typography>

                    <Button
                        sx={{
                            width: '180px',
                            margin: '0 8px'
                        }}
                        variant="outlined"
                        color={'secondary'}
                        onClick={() => {
                            setOpenConvertDraftDialog(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        sx={{
                            width: '180px',
                            margin: '0 8px'
                        }}
                        variant="contained"
                        color={'primary'}
                        onClick={async () => {
                            try {
                                await convertDraftOrderToCartItemsAction(order?.id);
                                await deleteOrderAction(order?.id);
                                navigate('/checkout');
                            } catch (e) {
                                AlertComponent.error(e.response.data.message);
                            }
                        }}
                    >
                        Proceed
                    </Button>
                </Box>
            </Dialog>
            }
        </>
    )
};

export default OrderActionDialogs;