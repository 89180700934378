import * as React from 'react';
import {useSearchParams} from "react-router-dom";
import DefaultLayout from "../../components/layouts/DefaultLayout/DefaultLayout";
import {useSelector} from "react-redux";
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import NotInterestedOutlinedIcon from '@mui/icons-material/NotInterestedOutlined';

import IconButton from "@mui/material/IconButton";
import {getJsonObject, getProductMainImage, parseSortingParam, prepareUrlParams} from "../../utils/helpers";
import {
    setFiltersAction,
    getProductStoresAction,
    getProductsAction,
    setReadyStatusAction
} from "../../store/actions/settings";
import TabsComponent from "./TabsComponent";
import ProductsTable from "./ProductsTable";

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import Button from "@mui/material/Button";
import UpdateProductPriceDialog from "./UpdateProductPriceDialog";
import CustomizedSnackbar from "../../components/CustomizedSnackbar";
import AlertComponent from "../../components/AlertComponent";

dayjs.extend(utc);
dayjs.extend(timezone);

const getProductColumns = (setSelectedProduct, setProductUpdatedContent, userTimezone) => {
    return [
        {
            id: 'expander',
            enableSorting: false,
            header: () => null,
            cell: ({ row }) => {
                return row.getCanExpand() ? (
                    <IconButton color={row.getIsExpanded() ? 'primary' : 'secondary'} onClick={row.getToggleExpandedHandler()} size="small">
                        {row.getIsExpanded() ? <ExpandMoreOutlinedIcon /> : <KeyboardArrowRightIcon />}
                    </IconButton>
                ) : (
                    <IconButton color="secondary" size="small" disabled>
                        <NotInterestedOutlinedIcon />
                    </IconButton>
                );
            },
            meta: {
                sx: {
                    width: '64px'
                }
            }
        },
        {
            header: 'Date Added',
            accessorKey: 'created_at',
            cell: ({ row }) => {
                const dateValue = dayjs.utc(row.original.created_at).tz(userTimezone);
                return dateValue.format('MMMM D, YYYY h:mm A');
            },
            meta: {
                sx: {
                    width: '210px'
                }
            }
        },
        {
            header: 'Thumbnail',
            cell: ({ row }) => {
                return (
                    <img
                        src={getProductMainImage(row.original)}
                        alt={'Main product\'s representation'}
                        loading="lazy"
                        height={'64px'}
                    />
                );
            },
            meta: {
                sx: {
                    width: '170px'
                }
            }
        },
        {
            header: 'SKU',
            accessorKey: 'sku',
        },
        {
            header: 'Title',
            accessorKey: 'title',
        },
        {
            header: '# Stores Available',
            cell: ({ row }) => {
                return row.original?.stores_count ?? 0;
            }
        },
        {
            header: 'MSRP',
            accessorKey: 'msrp',
        },
        {
            header: 'Action',
            enableSorting: false,
            cell: ({ row }) => {
                return (
                    <Button
                        variant={'contained'}
                        color={'warning'}
                        onClick={async () => {
                            setReadyStatusAction();
                            setProductUpdatedContent(null);
                            setSelectedProduct(row.original);
                        }}
                    >
                        Edit
                    </Button>
                )
            },
            meta: {
                sx: {
                    width: '0px'
                }
            }
        }
    ];
};

const storesColumns = [
    {
        header: 'Location ID',
        accessorKey: 'id',
    },
    {
        header: 'Name',
        accessorKey: 'name',
    },
    {
        header: 'Street Address',
        accessorKey: 'address',
        cell: ({ row }) => {
            const jsonObject = getJsonObject(row.original?.shipping_address);
            return [jsonObject.address_line_1, jsonObject.address_line_2].join(', ');
        }
    },

    {
        header: 'City',
        accessorKey: 'city',
        cell: ({ row }) => {
            const jsonObject = getJsonObject(row.original?.shipping_address);
            return jsonObject.city ?? '';
        }
    },
    {
        header: 'State',
        accessorKey: 'state',
        cell: ({ row }) => {
            const jsonObject = getJsonObject(row.original?.shipping_address);
            return jsonObject.state ?? '';
        }
    },
    {
        header: 'Postal code',
        accessorKey: 'postal_code',
        cell: ({ row }) => {
            const jsonObject = getJsonObject(row.original?.shipping_address);
            return jsonObject.postal_code ?? '';
        }
    },
    {
        header: 'Country',
        accessorKey: 'country',
        cell: ({ row }) => {
            const jsonObject = getJsonObject(row.original?.shipping_address);
            return jsonObject.country ?? '';
        }
    },
];

const getProducts = async (filters, setLoading) => {
    const params = prepareUrlParams(filters);
    const searchParams = new URLSearchParams();

    Object.entries(params).forEach(([key, value]) => {
        if (Array.isArray(value)) {
            value.forEach((val) => {
                searchParams.append(`${key}[]`, val);
            });
        } else {
            searchParams.append(key, value);
        }
    });

    try {
        setLoading(true);
        await getProductsAction(searchParams.toString());
    } catch (e) {
        AlertComponent.error(e.response.data.message);
    }

    setLoading(false);
};

const ProductsManagementPage = () => {
    const currentUser = useSelector(store => store.auth.authenticatedUser);
    const products = useSelector(store => store.settings.products);
    const quantities = useSelector((store) => store.settings.quantities);
    const filters = useSelector((store) => store.settings.filters);

    const [loading, setLoading] = React.useState(true);
    const [storesLoading, setStoresLoading] = React.useState(true);
    const [expanded, setExpanded] = React.useState({});
    const [selectedProduct, setSelectedProduct] = React.useState({});
    const [productUpdatedContent, setProductUpdatedContent] = React.useState(null);

    const [searchParams, setSearchParams] = useSearchParams();
    const urlParams = React.useMemo(() => Object.fromEntries(searchParams.entries()), [searchParams]);

    const columns = React.useMemo(() => getProductColumns(setSelectedProduct, setProductUpdatedContent, currentUser.timezone),
        [setSelectedProduct, setProductUpdatedContent, currentUser.timezone]
    );

    React.useEffect(() => {
        if (filters.initialState) {
            const sortingParams = parseSortingParam(urlParams?.sort);

            setFiltersAction({
                ...filters,
                pagination: {
                    ...filters.pagination,
                    page: +urlParams?.page || filters.pagination.page,
                    perPage: +urlParams?.per_page || filters.pagination.perPage,
                },

                sorting: sortingParams.length ? sortingParams : filters.sorting,
                productStatus: urlParams?.status || filters.productStatus,
                globalFilter: urlParams?.filter || filters.globalFilter,
                initialState: false
            });

            return;
        }

        setSearchParams(prepareUrlParams(filters));

    }, [filters, urlParams, setSearchParams]);

    React.useEffect(() => {
        if (!filters.initialState && !filters.pagination.totalWasUpdated) {
            getProducts(filters, setLoading);
        }
    }, [filters]);

    React.useEffect(() => {
        return () => {
            setFiltersAction({resetFilters: true})
        };
    }, []);

    const paginationChangeHandler = (param) => {
        if (param.value === filters.pagination[param.paramName]) {
            return;
        }

        if (param.paramName === 'page') {
            if (param.value <= 0 ||
                param.value > Math.floor(filters.pagination.total / filters.pagination.perPage) + 1) {
                return;
            }
        }

        setExpanded({});

        setFiltersAction({
            ...filters,
            pagination: {
                ...filters.pagination,
                [param.paramName]: param.value
            }
        });
    };

    const setGlobalFilterHandler = (value) => {
        setExpanded({});

        setFiltersAction({
            ...filters,
            globalFilter: value ? value : null,
            pagination: {
                ...filters.pagination,
                page: 1
            }
        });
    };

    const selectedProductsStatusHandler = (value) => {
        setExpanded({});

        setFiltersAction({
            ...filters,
            productStatus: value,
            pagination: {
                ...filters.pagination,
                page: 1
            }
        });
    };

    const setSortingHandler = (value) => {
        const sortingValue = value()[0];
        let newSortingParams = [];
        let itExists = false;

        setExpanded({});

        filters.sorting.forEach(item => {
            if (item.id === sortingValue.id) {
                itExists = true;

                if (item.desc) {
                    newSortingParams.push({id: item.id, desc: false});
                }
            } else {
                newSortingParams.push(item);
            }
        });

        if (!itExists) {
            newSortingParams.push({id: sortingValue.id, desc: true});
        }

        setFiltersAction({
            ...filters,
            sorting: newSortingParams
        });
    };

    const setExpandedHandler = async (value) => {
        const expandedValue = value();

        const key = Object.keys(expandedValue)[0];

        setExpanded(prev => ({
            ...prev,
            [key]: !prev[key]
        }));

        const product = products[key];

        if (!product.stores) {
            try {
                setStoresLoading(true);
                await getProductStoresAction(product.id);
            } catch (e) {
                AlertComponent.error(e.response.data.message);
            }
        }

        setStoresLoading(false);
    };

    return (
        <DefaultLayout>
            <TabsComponent
                currentTabIndex={2}
            />

            <div
                role="tabpanel"
                id={'tabpanel-2'}
                aria-labelledby={'tab-2'}
            >
                <ProductsTable {...{
                    caption: 'Products List',
                    columns,
                    subColumns: storesColumns,
                    data: products,
                    quantities: quantities,
                    filters,
                    loading,
                    storesLoading,
                    paginationChangeHandler,
                    setGlobalFilterHandler,
                    selectedProductsStatusHandler,
                    setSortingHandler,
                    expanded,
                    setExpandedHandler,
                }}
                />

                {selectedProduct?.id &&
                    <UpdateProductPriceDialog
                        product={selectedProduct}
                        handleClose={() => setSelectedProduct({})}
                        successHandler={(content) => {
                            setProductUpdatedContent(content);
                        }}
                    />
                }
            </div>

            {productUpdatedContent &&
                <CustomizedSnackbar
                    content={productUpdatedContent}
                    handleClose={() => setProductUpdatedContent(null)}
                />
            }
        </DefaultLayout>
    );
};

export default ProductsManagementPage;