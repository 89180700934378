import * as React from 'react';
import {updateCartItemAction} from "../store/actions/cart";
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import AlertComponent from "./AlertComponent";
import Tooltip from "@mui/material/Tooltip";

export default function ProductQuantity({ item }) {
    const decrementHandler = async () => {
        try {
            await updateCartItemAction(item.id, {quantity: item.quantity - 1})
        } catch (e) {
            AlertComponent.error(e.response.data.message);
        }
    };

    const incrementHandler = async () => {
        try {
            await updateCartItemAction(item.id, {quantity: item.quantity + 1})
        } catch (e) {
            AlertComponent.error(e.response.data.message);
        }
    };

    return (
        <Stack
            direction="row"
            sx={{
                justifyContent: 'center'
            }}
        >
            <Tooltip title="Decrease Quantity">
                <Button
                    key="three"
                    variant="text"
                    disabled={item.quantity <= 1}
                    onClick={decrementHandler}
                    sx={{ pr: 0.75, pl: 0.75, minWidth: '0px !important', '&:hover': { bgcolor: 'transparent' } }}
                >
                    <RemoveOutlinedIcon style={{ fontSize: 'inherit' }} />
                </Button>
            </Tooltip>
            <Typography key="two" sx={{ p: '9px 15px', border: '1px solid', borderColor: 'grey.A800' }}>
                {item.quantity}
            </Typography>
            <Tooltip title="Increase Quantity">
                <Button
                    key="one"
                    variant="text"
                    onClick={incrementHandler}
                    sx={{ pl: 0.75, pr: 0.75, minWidth: '0px !important', '&:hover': { bgcolor: 'transparent' } }}
                >
                    <AddOutlinedIcon style={{ fontSize: 'inherit' }} />
                </Button>
            </Tooltip>
        </Stack>
    );
}