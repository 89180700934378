import * as React from 'react';
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import useTheme from "@mui/material/styles/useTheme";
import MediaPreviewDialog from "./MediaPreviewDialog";
import Tooltip from "@mui/material/Tooltip";
import {findPreviewComponent, generatePreviewForUploadedFile} from "../../utils/helpers";

const UploadedFilesList = ({ files, itemsBackgroundColor, justifyContent }) => {
    const theme = useTheme();
    const [selectedMedia, setSelectedMedia] = React.useState(undefined);

    const [filePreviews, setFilePreviews] = React.useState([]);

    React.useEffect(() => {
        files.forEach(attachment => {
            generatePreviewForUploadedFile(attachment, setFilePreviews);
        });
    }, [files]);

    return (
        <>
            <Stack
                direction={'row'}
                spacing={1}
                sx={{
                    justifyContent: justifyContent ?? 'center'
                }}
            >
                {
                    files.map((file, fileIndex) => {
                        const previewComponent = findPreviewComponent(file.original_name, filePreviews);

                        return (
                            <Tooltip
                                key={fileIndex}
                                title={file.original_name}
                            >
                                <Paper
                                    variant={'outlined'}
                                    sx={{
                                        p: 1,
                                        backgroundColor: itemsBackgroundColor ?? 'none',
                                        width: '110px',
                                        height: '110px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                        borderColor: theme.palette.secondary.light,
                                        '&:hover': {
                                            borderColor: theme.palette.secondary.main,
                                        }
                                    }}
                                    onClick={() => {
                                        setSelectedMedia(
                                            {
                                                ...file,
                                                previewComponent: previewComponent
                                            });
                                    }}
                                >
                                    {previewComponent}
                                </Paper>
                            </Tooltip>
                        );
                    })
                }
            </Stack>

            {selectedMedia &&
                <MediaPreviewDialog
                    currentMedia={selectedMedia}
                    handleClose={() => setSelectedMedia(undefined)}
                />
            }
        </>
    );
};

export default UploadedFilesList