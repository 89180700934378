import * as React from 'react';
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import DeleteIconOutlined from '@mui/icons-material/DeleteOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import {generatePreview, findPreviewComponent} from "../../utils/helpers";

const FilesUploaderComponent = ({ state, option, onChangeHandler, stateFunction }) => {
    const [moreFilesThanAllowed, setMoreFilesThanAllowed] = React.useState(false);

    const selectedFiles = state[option.name] || [];
    const [filePreviews, setFilePreviews] = React.useState([]);

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);

        const filteredFiles = files.filter((file) => {
            const fileExtension = file.name.slice(file.name.lastIndexOf('.')).toLowerCase();
            const isAllowedExtension = option.allowedExt ? option.allowedExt.includes(fileExtension) : true;
            const isAllowedSize = option.maxFileSize ? 1024 * 1024 * option.maxFileSize >= file.size : true;

            if (!isAllowedExtension || !isAllowedSize) {
                alert(`File ${file.name} is not allowed.`);
                return false;
            }
            return true;
        });

        const totalFilesNumber = filteredFiles.length + selectedFiles.length;
        const maxFilesNumber = option.maxFilesNumber || 4;

        setMoreFilesThanAllowed(totalFilesNumber >= (maxFilesNumber));

        const newFilesList = [...selectedFiles];
        let availableQuantity = maxFilesNumber - selectedFiles.length;

        for (const singleFile of filteredFiles) {
            if (availableQuantity <= 0) {
                break;
            }

            generatePreview(singleFile, setFilePreviews);

            newFilesList.push(singleFile);
            availableQuantity -= 1;
        }

        onChangeHandler(option, {target: {value: newFilesList}}, stateFunction);
        event.target.value = '';
    };

    const handleDeleteFile = (fileToDelete) => {
        setMoreFilesThanAllowed(false);

        const newFilesList = selectedFiles.filter((file) => file !== fileToDelete);

        onChangeHandler(option, {target: {value: newFilesList}}, stateFunction);

        setFilePreviews((prev) => prev.filter((preview) => preview.fileName !== fileToDelete.name));
    };

    return (
        <Stack spacing={2}>
            <Button
                sx={{
                    alignSelf: 'start'
                }}
                component="label"
                variant="outlined"
                startIcon={<FileUploadOutlinedIcon />}
                disabled={moreFilesThanAllowed}
            >
                Click to Upload
                <input
                    type="file"
                    multiple
                    accept="image/*,video/*,.pdf,image/heic"
                    hidden
                    onChange={handleFileChange}
                />
            </Button>

            {moreFilesThanAllowed &&
            <Typography
                variant={'body1'}
                color={'red'}
            >
                You can't upload more files.
            </Typography>
            }

            <Stack spacing={1}>
                {selectedFiles.map((file, index) => (
                    <Card
                        key={index}
                        variant={'outlined'}
                        sx={{
                            padding: '5px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            {findPreviewComponent(file.name, filePreviews)}

                            <Typography
                                variant="h6"
                            >
                                {file.name}
                            </Typography>
                        </Box>


                        <IconButton
                            onClick={() => handleDeleteFile(file)}
                            aria-label="Delete"
                        >
                            <DeleteIconOutlined />
                        </IconButton>
                    </Card>
                ))}
            </Stack>

            <Typography
                color={'secondary'}
                sx={{
                    textAlign: 'center'
                }}
            >
                {option.bottomText}
            </Typography>
        </Stack>
    );
};

export default FilesUploaderComponent;