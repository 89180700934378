import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// material-ui
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Stack from '@mui/material/Stack';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project import

import { strengthColor, strengthIndicator } from '../../utils/password-strength';

// assets
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import Typography from "@mui/material/Typography";
import IconButton from "../../components/IconButton";
import AuthWrapper from "../../components/AuthWrapper";
import DefaultLayout from "../../components/layouts/DefaultLayout/DefaultLayout";
import {changePasswordAction} from "../../store/actions/user";
import {useSelector} from "react-redux";
import InfoMessageSnackbar from "../../components/InfoMessageSnackbar";
import AlertComponent from "../../components/AlertComponent";


export default function ChangePasswordPage() {
    const navigate = useNavigate();
    const { authenticatedUser } = useSelector(store => store.auth);
    const { error } = useSelector(store => store.user);

    const [level, setLevel] = useState();
    const [submitted, setSubmitted] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const changePassword = (value) => {
        const temp = strengthIndicator(value);
        setLevel(strengthColor(temp));
    };

    useEffect(() => {
        changePassword('');
    }, []);

    useEffect(() => {
        if (submitted && !error) {
            setTimeout(() => {
                navigate('/', { replace: true });
            }, 1500);
        }
    }, [submitted, error, navigate]);

    const passwordValidationMessage = 'Password does not meet the requirements. Please ensure it is at least 8 characters long and includes uppercase and lowercase letters, a number, and a special symbol (@, $, !, %, *, ?, &, #).';

    return (
        <DefaultLayout>
            <AuthWrapper
                isForDefaultLayout={true}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Stack sx={{ mb: { xs: -0.5, sm: 0.5 } }} spacing={1}>
                            <Typography variant="h3">Change Password</Typography>
                            <Typography color="secondary">Please enter your new password below.</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Formik
                            initialValues={{
                                oldPassword: '',
                                password: '',
                                confirmPassword: '',
                                submit: null
                            }}
                            validationSchema={Yup.object().shape({
                                oldPassword: Yup.string().max(255).required('Old password is required'),
                                password: Yup.string()
                                    .max(255)
                                    .min(8, passwordValidationMessage)
                                    .matches(/[a-z]/, passwordValidationMessage)
                                    .matches(/[A-Z]/, passwordValidationMessage)
                                    .matches(/[0-9]/, passwordValidationMessage)
                                    .matches(/[@$!%*?&#]/, passwordValidationMessage)
                                    .required('New password is required'),
                                confirmPassword: Yup.string()
                                    .required('New password\'s confirmation is required')
                                    .test('confirmPassword', 'Both passwords must be matching!', (confirmPassword, yup) => yup.parent.password === confirmPassword)
                            })}
                            onSubmit={async (values) => {
                                try {
                                    setSubmitted(false);

                                    await changePasswordAction(authenticatedUser.id, {
                                        'old_password': values.oldPassword,
                                        'new_password': values.password
                                    });

                                    setSubmitted(true);
                                } catch (e) {
                                    AlertComponent.error(e.response.data.message);
                                }
                            }}
                        >
                            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                                <form noValidate onSubmit={handleSubmit}>
                                    <Grid container spacing={3}>

                                        <Grid item xs={12}>
                                            <Stack spacing={1}>
                                                <InputLabel htmlFor="old-password">Old Password</InputLabel>
                                                <OutlinedInput
                                                    fullWidth
                                                    error={Boolean(touched.oldPassword && errors.oldPassword)}
                                                    id="old-password"
                                                    type={'password'}
                                                    value={values.oldPassword}
                                                    name="oldPassword"
                                                    onBlur={handleBlur}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                    }}
                                                    placeholder="Enter old password"
                                                />
                                            </Stack>
                                            {touched.oldPassword && errors.oldPassword && (
                                                <FormHelperText error id="helper-text-old-password">
                                                    {errors.oldPassword}
                                                </FormHelperText>
                                            )}
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Stack spacing={1}>
                                                <InputLabel htmlFor="password-reset">New Password</InputLabel>
                                                <OutlinedInput
                                                    fullWidth
                                                    error={Boolean(touched.password && errors.password)}
                                                    id="password-reset"
                                                    type={showPassword ? 'text' : 'password'}
                                                    value={values.password}
                                                    name="password"
                                                    onBlur={handleBlur}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        changePassword(e.target.value);
                                                    }}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword}
                                                                onMouseDown={handleMouseDownPassword}
                                                                edge="end"
                                                                color="secondary"
                                                            >
                                                                {showPassword ? <VisibilityOutlinedIcon/> : <VisibilityOffOutlinedIcon/>}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    placeholder="Enter new password"
                                                />
                                            </Stack>
                                            {touched.password && errors.password && (
                                                <FormHelperText error id="helper-text-password-reset">
                                                    {errors.password}
                                                </FormHelperText>
                                            )}
                                            <FormControl fullWidth sx={{ mt: 2 }}>
                                                <Grid container spacing={2} alignItems="center">
                                                    <Grid item>
                                                        <Box sx={{ bgcolor: level?.color, width: 85, height: 8, borderRadius: '7px' }} />
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="subtitle1" fontSize="0.75rem">
                                                            {level?.label}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Stack spacing={1}>
                                                <InputLabel htmlFor="confirm-password-reset">Confirm New Password</InputLabel>
                                                <OutlinedInput
                                                    fullWidth
                                                    error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                                                    id="confirm-password-reset"
                                                    type="password"
                                                    value={values.confirmPassword}
                                                    name="confirmPassword"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    placeholder="Enter new password"
                                                />
                                            </Stack>
                                            {touched.confirmPassword && errors.confirmPassword && (
                                                <FormHelperText error id="helper-text-confirm-password-reset">
                                                    {errors.confirmPassword}
                                                </FormHelperText>
                                            )}
                                        </Grid>

                                        {errors.submit && (
                                            <Grid item xs={12}>
                                                <FormHelperText error>{errors.submit}</FormHelperText>
                                            </Grid>
                                        )}
                                        <Grid item xs={12}>
                                            <Button disableElevation disabled={isSubmitting} fullWidth size="large" type="submit" variant="contained" color="primary">
                                                Save Password
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Formik>
                    </Grid>
                </Grid>
            </AuthWrapper>

            <InfoMessageSnackbar
                message={error}
                type={'error'}
                onCloseHandler={() => {
                    setSubmitted(false);
                }}
            />

            <InfoMessageSnackbar
                message={submitted && !error ? 'Password was changed.' : ''}
                type={'success'}
                onCloseHandler={() => {
                    setSubmitted(false);
                }}
            />

        </DefaultLayout>
    );
}
