import PropTypes from 'prop-types';
// material-ui
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

// assets

import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';

const SortType = {
    ASC: 'asc',
    DESC: 'desc'
};

function SortToggler({ type }) {
    const theme = useTheme();

    return (
        <Stack sx={{ color: 'secondary.light' }}>
            <ArrowDropUpOutlinedIcon
                style={{
                    fontSize: '0.8rem',
                    color: type === SortType.ASC ? theme.palette.success.dark : 'inherit'
                }}
            />
            <ArrowDropDownOutlinedIcon
                style={{
                    fontSize: '0.8rem',
                    marginTop: -2,
                    color: type === SortType.DESC ? theme.palette.primary.dark : 'inherit'
                }}
            />
        </Stack>
    );
}

// ==============================|| SORT HEADER ||============================== //

export default function HeaderSort({ column, sort }) {
    return (
        <Box {...(sort && { onClick: column.getToggleSortingHandler(), className: 'cursor-pointer prevent-select' })}>
            {{
                asc: <SortToggler type={SortType.ASC} />,
                desc: <SortToggler type={SortType.DESC} />
            }[column.getIsSorted()] ?? <SortToggler />}
        </Box>
    );
}

SortToggler.propTypes = { type: PropTypes.any };

HeaderSort.propTypes = { column: PropTypes.object, sort: PropTypes.bool };
