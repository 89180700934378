import * as React from 'react';
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import useTheme from "@mui/material/styles/useTheme";
import { uploadLogoAction } from "../../store/actions/settings";

const UploadLogoComponent = ({ error, onChange, value }) => {
    const theme = useTheme();
    const [uploadError, setUploadError] = React.useState(null);
    const fileInputRef = React.useRef(null);

    const handleFileChange = async (event) => {
        setUploadError(null);

        const file = event.target.files[0];
        const formData = new FormData();

        formData.append('logo', file);

        const result = await uploadLogoAction(formData);

        if (result?.code === 'ERR_BAD_REQUEST') {
            setUploadError(result.response?.data?.message);
            return;
        }

        const url = result?.data?.data?.logo_url;

        if (url) {
            const e = {
                target: {
                    name: 'logo',
                    value: {
                        url: url,
                        fileName: file.name,
                    }
                }
            };

            setUploadError(null);
            onChange(e);
        } else {
            setUploadError('Failed to get URL after upload.');
        }
    };

    return (
        <>
            <Stack direction="row" alignItems="center">
                <input
                    ref={fileInputRef}
                    accept="image/png"
                    id="upload-logo"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                />
                <label htmlFor="upload-logo">
                    <Button
                        variant="outlined"
                        component="span"
                        startIcon={<AddPhotoAlternateOutlinedIcon />}
                        sx={{
                            height: '40px',
                            marginRight: '8px'
                        }}
                    >
                        Select Logo
                    </Button>
                </label>

                <Box
                    display="flex"
                    alignItems="center"
                    height={'48px'}
                >
                    {value?.url ? (
                            <>
                                <Box
                                    height="100%"
                                    marginRight='8px'
                                    sx={{
                                        '& img': {
                                            height: '48px',
                                            width: '48px',
                                            border: '1px solid lightgray',
                                            borderRadius: '8px'
                                        }
                                    }}
                                >
                                    <img src={value.url} alt="logo"/>
                                </Box>
                                <Typography
                                    variant="body1"
                                    sx={{marginRight: '8px'}}
                                >
                                    {value.fileName}
                                </Typography>
                                <IconButton
                                    sx={{
                                        border: '1px solid lightgray',
                                        borderRadius: '8px',
                                        '&:hover': {
                                            border: '1px solid red',
                                            borderRadius: '8px'
                                        }
                                    }}
                                    onClick={() => {
                                        const e = {
                                            target: {
                                                name: 'logo',
                                                value: {
                                                    url: null,
                                                    fileName: null,
                                                    file: null
                                                }
                                            }
                                        };

                                        fileInputRef.current.value = '';
                                        onChange(e);
                                    }}
                                >
                                    <DeleteOutlinedIcon/>
                                </IconButton>
                            </>
                        ) :
                        (
                            <Typography
                                sx={{
                                    border: '1px solid lightgray',
                                    borderRadius: '8px',
                                    width: '48px',
                                    height: '48px',
                                    textAlign: 'center'
                                }}
                                color='secondary'
                            >
                                No Logo
                            </Typography>
                        )
                    }
                </Box>
            </Stack>

            {!uploadError &&
                <Typography
                    variant={'body1'}
                    color={'secondary'}
                >
                    Please ensure your file is a PNG and does not exceed 1MB in size.
                </Typography>
            }

            {uploadError &&
                <Typography
                    variant={'body1'}
                    color={theme.palette.error.main}
                    fontSize={'0.75rem'}
                >
                    {uploadError}
                </Typography>
            }
        </>
    );
};

export default UploadLogoComponent;