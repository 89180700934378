import * as React from 'react';
import PropTypes from 'prop-types';
// third-party
import { Page, View, Document, StyleSheet } from '@react-pdf/renderer';

// project import
import Header from './Header';
import Content from './Content';

const styles = StyleSheet.create({
  page: {
    padding: 30
  },
  container: {
    flex: 1,
    flexDirection: 'row',
    '@media max-width: 400': {
      flexDirection: 'column'
    }
  }
});

export default function ExportPDFView({ logo, order, orderIdBarcodeData, barcodeDataArray, userTimezone }) {

  return (
    <Document title={`${order?.generated_order_id}`}>
      <Page size="A4" style={styles.page} orientation="landscape">
        <Header logo={logo} order={order} orderIdBarcodeData={orderIdBarcodeData} userTimezone={userTimezone}/>
        <View style={styles.container}>
          <Content
              order={order}
              barcodeDataArray={barcodeDataArray}
          />
        </View>
      </Page>
    </Document>
  );
}

ExportPDFView.propTypes = { list: PropTypes.any };
