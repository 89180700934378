import {authApi} from './apiService'

//const getAllProducts = async () => authApi.get('products');
const getAllStoreProducts = async (storeId) => authApi.get(`products/store/${storeId}`);
const getProduct = async (id) => authApi.get(`products/${id}`);


const apiCalls = {
    //getAllProducts,
    getAllStoreProducts,
    getProduct
};

export default apiCalls;