import * as React from "react";
import router from './router';
import './App.css';
import ThemeCustomization from './themes';

import { RouterProvider } from "react-router-dom";
import AlertComponent from "./components/AlertComponent";

const App = () => (
    <ThemeCustomization>
        <RouterProvider router={router} />
        <AlertComponent/>
    </ThemeCustomization>
    );

export default App;
