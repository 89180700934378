import * as React from 'react';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import MainCard from "../../../components/MainCard";
import CardMedia from "@mui/material/CardMedia";
import { useNavigate } from "react-router-dom";
import useTheme from "@mui/material/styles/useTheme";
import {getProductMainImage} from "../../../utils/helpers";


export default function SingleProductCard({ product, setAnchorEl, setPopoverText }) {
    const theme = useTheme();
    const isStock = true;

    const navigate = useNavigate();

    const onClickHandler = () => {
        navigate(`/products/${product.id}`);
    };

    const handleMouseEnter = (event) => {
        const container = event.target;
        if (container.scrollWidth > container.clientWidth ||
            container.scrollHeight > container.clientHeight
        ) {
            setPopoverText(container.textContent);
            setAnchorEl(event.currentTarget);
        } else {
            setAnchorEl(null);
        }
    };

    const handleMouseLeave = () => {
        setAnchorEl(null);
    };

    return (
        <MainCard
            onClick={onClickHandler}
            content={false}
            boxShadow
            sx={{
                '&:hover': {
                    borderColor:  theme.palette.primary.light
                },
                width: '400px',
                height: '420px',
                cursor: 'pointer'
            }}
        >

            <CardMedia
                sx={{
                    height: 250,
                    textDecoration: 'none',
                    opacity: isStock ? 1 : 0.25,
                    backgroundSize: 'contain',
                    maxWidth: '250px',
                    width: '100%',
                    m: 'auto'
                }}
                image={getProductMainImage(product)}
            />

            <Stack
                direction="row"
                alignItems="center"
                justifyContent={'space-between'}
                padding={"0 16px"}
            >
                <Typography
                    variant="body1"
                    color="text.secondary"
                    sx={{
                        fontSize: '0.8rem',
                        fontWeight: '100'
                    }}
                >
                    {product?.sku}
                </Typography>
                <Typography variant="h5">
                    {(+product?.product_price).toLocaleString(
                        'en-US',
                        {
                            style: 'currency',
                            currency: 'USD'
                        })}
                </Typography>
            </Stack>
            <Divider />
            <CardContent sx={{ p: 2 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Stack>
                            <Typography
                                color="text.primary"
                                variant="h5"
                                sx={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    margin: "8px 0",
                                    height: '48px',
                                    display: '-webkit-box',
                                    WebkitLineClamp: '2',
                                    WebkitBoxOrient: 'vertical',
                                }}
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                            >
                                {product?.title}
                            </Typography>
                            <Typography
                                sx={{
                                    height: '48px',
                                    display: '-webkit-box',
                                    WebkitLineClamp: '2',
                                    WebkitBoxOrient: 'vertical',
                                    overflow: 'hidden'
                                }}
                                variant="h6"
                                color="text.secondary"
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                            >
                                {product?.short_description}
                            </Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </CardContent>
        </MainCard>
    );
}
