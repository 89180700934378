import * as React from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import DebouncedInput from "../../../components/DebouncedInput";

// ==============================|| CHECKOUT CART - CART DISCOUNT ||============================== //

export default function ReferenceNumber({ number, changeHandler}) {
    return (
        <Stack justifyContent="flex-end" spacing={1}>
            <Typography variant="caption" color="text.secondary" sx={{ cursor: 'pointer' }} onClick={() => {}}>
                Enter Reference Number (optional)
            </Typography>

            <DebouncedInput
                value={number || ''}
                onFilterChange={changeHandler}
                name={'referenceNumber'}
                id={'reference-number'}
                placeholder="Reference Number..."
                size={'small'}
                startAdornment={null}
            />
        </Stack>
    );
}
