import userService from "../../services/userService";
import {dispatch} from "../index";
import {setPendingStatus, setReadyStatus} from "../reducers/user";
import {setActiveStore} from "../reducers/stores";

export const changePasswordAction = async (userId, data) => {
    dispatch(setPendingStatus());

    await userService.changePassword(userId, data);

    dispatch(setReadyStatus());
};

export const setActiveStoreAction = async (storeId) => {
    dispatch(setPendingStatus());

    const response = await userService.setActiveStore(storeId);

    dispatch(setActiveStore(response.data.data.activeStore));
    dispatch(setReadyStatus());
};

export const getActiveStoreAction = async () => {
    dispatch(setPendingStatus());

    const response = await userService.getActiveStore();

    dispatch(setActiveStore(response.data.data.activeStore));
    dispatch(setReadyStatus());
};