import companyService from "../../services/companyService";
import {
    setPendingStatus,
    setReadyStatus,
    setAllStores
} from "../reducers/company";
import { dispatch } from "../index";

export const getAllCompanyStoresAction = async (companyId) => {
    dispatch(setPendingStatus());

    const response = await companyService.getAllStores(companyId);

    dispatch(setAllStores(response.data.data['allStores']));
    dispatch(setReadyStatus());
};