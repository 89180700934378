import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";

const ProductDetailsSkeleton = () => {
    const theme = useTheme();

    return (
        <Grid container spacing={8}>
            <Grid
                item md={4} xs={12}
            >
                <Skeleton variant="rectangular" width={'100%'} height={'540px'} animation="wave" />
            </Grid>

            <Grid
                item md={8} xs={12}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between"
                }}
            >
                <Box
                    marginBottom={'32px'}
                >
                    <Skeleton variant="rectangular" width={'100%'} height={'32px'} animation="wave"
                        sx={{
                            marginBottom: '32px'
                        }}
                    />
                    <Skeleton variant="rectangular" width={'100%'} height={'64px'} animation="wave"/>
                </Box>

                <Skeleton variant="rectangular" width={'50%'} height={'80px'} animation="wave"
                    sx={{
                        marginBottom: '32px'
                    }}
                />
                <Skeleton variant="rectangular" width={'50%'} height={'270px'} animation="wave"
                    sx={{
                        marginBottom: '32px'
                    }}
                />

                <Divider
                    sx={{
                        borderColor: theme.palette.secondary.light,
                        marginBottom: '16px'
                    }}
                />
                <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                >
                    <Skeleton variant="rectangular" width={'100px'} height={'32px'} animation="wave" />
                    <Skeleton variant="rectangular" width={'100px'} height={'32px'} animation="wave" />
                </Box>
            </Grid>
        </Grid>
    );
};

export default ProductDetailsSkeleton;