import * as React from 'react';
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useTheme } from '@mui/material/styles';


const CustomizedSnackbar = ({ content, handleClose }) => {
    const theme = useTheme();

    return (
        <Snackbar
            open={true}
            autoHideDuration={4000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
            <Alert
                onClose={handleClose}
                severity="success"
                variant="filled"
                sx={{
                    width: '100%',
                    bgcolor: theme.palette.success.dark
                }}
            >
                {content}
            </Alert>
        </Snackbar>
    );
};

export default CustomizedSnackbar;