import PropTypes from 'prop-types';
// material-ui
import { useTheme } from '@mui/material/styles';

// third-party
import {Text, View, StyleSheet, Image} from '@react-pdf/renderer';
import * as React from "react";
import {getJsonObject, getProductMainImage} from "../../../utils/helpers";
import {generateSelectedOptionsWithValues, isOrderItemForCustomQuote} from "../../../components/dynamicForms/utils";

const textPrimary = '#262626';
const textSecondary = '#8c8c8c';
const border = '#f0f0f0';

// custom Style
const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 16,
    '@media max-width: 400': {
      paddingTop: 10,
      paddingLeft: 0
    }
  },
  card: {
    border: '1px solid',
    borderColor: border,
    borderRadius: '2px',
    padding: '20px',
    width: '48%'
  },
  title: {
    color: textPrimary,
    fontSize: '12px',
    fontWeight: 500
  },
  caption: {
    color: textSecondary,
    fontSize: '10px'
  },
  tableTitle: {
    color: textPrimary,
    fontSize: '10px',
    fontWeight: 500
  },
  tableCell: {
    color: textPrimary,
    fontSize: '10px'
  },

  row: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 24
  },

  subRow: {
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    margin: 0,
    paddingBottom: 20
  },

  tableHeader: {
    justifyContent: 'space-between',
    borderBottom: '1px solid #f0f0f0',
    borderTop: '1px solid #f0f0f0',
    paddingTop: '10px',
    paddingBottom: '10px',
    margin: 0,
    paddingLeft: 10
  },
  tableRow: {
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: '1px solid #f0f0f0',
    paddingBottom: 10,
    paddingTop: 10,
    margin: 0,
    paddingLeft: 10
  },

  amountSection: { margin: 0, paddingRight: 25, paddingTop: 16, justifyContent: 'flex-end' },

  pb5: { paddingBottom: 5 },

  flex01: { flex: '0.1 0 0px' },
  flex02: { flex: '0.2 0 0px' },
  flex03: { flex: '0.3 1 0px' },
  flex04: { flex: '0.4 1 0px' },
  flex06: { flex: '0.6 1 0px' },
  flex07: { flex: '0.7 1 0px' },
  flex17: { flex: '1.7 1 0px' },
  flex20: { flex: '2 1 0px' },

  barcode: {
    width: 90,
    height: 30,
    margin: '0 auto'
  },

  image: {
    width: 30,
    height: 30
  },

  centered: {
    textAlign: 'center'
  },

  marginRight16: {
    marginRight: '16px'
  }
});

// ==============================|| INVOICE EXPORT - CONTENT ||============================== //

export default function Content({ order, barcodeDataArray }) {
  const theme = useTheme();

  const recipient = getJsonObject(order?.recipient) || {};
  const billingAddress = getJsonObject(order?.company?.billing_address) || {};
  const total = order?.items?.reduce((acc, item) => +item.product_price * item.quantity + acc, 0) || 0;

  const isForCustomQuote = !order?.items ? false : isOrderItemForCustomQuote(order.items?.[0]);

  return (
    <View style={styles.container}>

      <View style={[styles.row, styles.subRow]}>
        <View style={styles.card}>
          <Text style={[styles.title, { marginBottom: 8 }]}>BILLING:</Text>
          <Text style={[styles.caption, styles.pb5]}>{order?.company?.name}</Text>

          {billingAddress.address_line_1 &&
            <Text style={[styles.caption, styles.pb5]}>{billingAddress.address_line_1}</Text>
          }
          {billingAddress.address_line_2 &&
            <Text style={[styles.caption, styles.pb5]}>{billingAddress.address_line_2}</Text>
          }
          {(billingAddress.city || billingAddress.state) &&
            <Text style={[styles.caption, styles.pb5]}>{billingAddress.city}, {billingAddress.state}</Text>
          }
          {billingAddress.postal_code &&
            <Text style={[styles.caption, styles.pb5]}>{billingAddress.postal_code}</Text>
          }
          {billingAddress.country &&
            <Text style={[styles.caption, styles.pb5]}>{billingAddress.country}</Text>
          }
        </View>
        <View style={styles.card}>
          <Text style={[styles.title, { marginBottom: 8 }]}>SHIP TO: {recipient?.customer ? '"CUSTOMER"' : 'SHIPPING NICKNAME'}</Text>

          {order?.address_line_1 &&
            <Text style={[styles.caption, styles.pb5]}>{order?.address_line_1}</Text>
          }
          {order?.address_line_2 &&
            <Text style={[styles.caption, styles.pb5]}>{order?.address_line_2}</Text>
          }
          {(order?.city || order?.state) &&
            <Text style={[styles.caption, styles.pb5]}>{order?.city}, {order?.state}</Text>
          }
          {order?.postal_code &&
            <Text style={[styles.caption, styles.pb5]}>{order?.postal_code}</Text>
          }
          {order?.country &&
            <Text style={[styles.caption, styles.pb5]}>{order?.country}</Text>
          }

        </View>
      </View>


      <View>
        <View style={[styles.row, styles.tableHeader, { backgroundColor: theme.palette.grey[100] }]}>
          <Text style={[styles.tableTitle, styles.flex01]}>#</Text>
          <Text style={[styles.tableTitle, styles.flex03]}>IMAGE</Text>
          <Text style={[styles.tableTitle, styles.flex04]}>TITLE</Text>

          <Text style={[styles.tableTitle, styles.flex06, styles.centered]}>STYLE ID</Text>

          <Text style={[styles.tableTitle, styles.flex17]}>PERSONALIZED DETAILS</Text>

          <Text style={[styles.tableTitle, styles.flex03]}>QUANTITY</Text>
          {!isForCustomQuote &&
            <>
              <Text style={[styles.tableTitle, styles.flex03]}>MSPR</Text>
              <Text style={[styles.tableTitle, styles.flex03]}>AMOUNT</Text>
            </>
          }
        </View>

        {order?.items?.map((item, index) => {
          const optionsWithValues = generateSelectedOptionsWithValues(item);

          return (
            <View style={[styles.row, styles.tableRow]} key={item.id}>
              <Text style={[styles.tableCell, styles.flex01]}>{index + 1}</Text>
              <View style={[styles.tableCell, styles.flex03]}>
                <Image src={getProductMainImage(item.product)} style={styles.image}/>
              </View>
              <Text style={[styles.tableCell, styles.flex04]}>{item.product.title}</Text>

              <View style={[styles.tableCell, styles.flex06, styles.centered]}>
                <Text style={[styles.centered]}>{item.product.sku}</Text>
                <Image src={barcodeDataArray[index]} style={styles.barcode} />
              </View>

              <Text style={[styles.tableCell, styles.flex17]}>
                {optionsWithValues.map(option => `${option.title}: ${option.value}`).join('\n')}
              </Text>

              <Text style={[styles.tableCell, styles.flex03, styles.centered]}>{item.quantity}</Text>

              {!isForCustomQuote &&
                <>
                  <Text style={[styles.tableCell, styles.flex03]}>{item.product_price}</Text>
                  <Text
                      style={[styles.tableCell, styles.flex03]}>{'$' + Number(item.quantity * item.product_price).toFixed(2)}</Text>
                </>
              }
            </View>
          );
        })}
      </View>

      {!isForCustomQuote &&
        <View style={[styles.row, styles.amountSection]}>
          <Text style={[styles.tableCell, styles.marginRight16]}>Total:</Text>
          <Text style={styles.tableCell}>${total}</Text>
        </View>
      }


    </View>
  );
}

Content.propTypes = { order: PropTypes.any };
