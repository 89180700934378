import * as React from 'react';
import {blueGrey, blue, green, orange, red, purple} from "@mui/material/colors";

// material-ui
import Chip from '@mui/material/Chip';

// assets
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import NotInterestedOutlinedIcon from '@mui/icons-material/NotInterestedOutlined';

import IconButton from "../../components/IconButton";

import { useSearchParams } from "react-router-dom";
import {useSelector} from "react-redux";
import {
    setFiltersAction,
    getOrdersAction,
    getOrderProductsAction,
    updateOrderStatusAction
} from "../../store/actions/orders";
import ReactTable from "./ReactTable";
import {
    prepareUrlParams,
    ORDER_STATUSES,
    parseSortingParam,
    getJsonObject,
    ORDER_DELIVERY_STATUSES,
    ORDER_ITEM_DELIVERY_STATUSES
} from "../../utils/helpers";

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import {generateSelectedOptionsWithValues} from "../../components/dynamicForms/utils";
import OrderActions from "./OrderActions";
import OrderActionDialogs from "./OrderActionDialogs";
import AlertComponent from "../../components/AlertComponent";
import ExportCsvDialog from "./ExportCsvDialog";

dayjs.extend(utc);
dayjs.extend(timezone);

const getOrderColumns = (setSelectedOrder, setOpenConfirmationDialog, setOpenWarningDialog,
                         setOpenEditDraftDialog, setOpenConvertDraftDialog, userTimezone,
                         isForDrafts = false) => {
    const columns = [
        {
            id: 'expander',
            enableSorting: false,
            header: () => null,
            cell: ({ row }) => {
                return row.getCanExpand() ? (
                    <IconButton color={row.getIsExpanded() ? 'primary' : 'secondary'} onClick={row.getToggleExpandedHandler()} size="small">
                        {row.getIsExpanded() ? <ExpandMoreOutlinedIcon /> : <KeyboardArrowRightIcon />}
                    </IconButton>
                ) : (
                    <IconButton color="secondary" size="small" disabled>
                        <NotInterestedOutlinedIcon />
                    </IconButton>
                );
            },
            meta: {
                sx: {
                    width: '64px'
                }
            }
        },
        {
            header: 'Date',
            accessorKey: 'created_at',
            cell: (cell) => {
                const dateValue = dayjs.utc(cell.row.original.created_at).tz(userTimezone);
                return dateValue.format('MMMM D, YYYY h:mm A');
            },
            meta: {
                sx: {
                    width: '210px'
                }
            }
        },
        {
            header: 'Order ID',
            accessorKey: 'generated_order_id',
        },
        {
            header: 'Ordered By',
            accessorKey: 'ordered_by'
        },
        {
            header: 'Store',
            accessorKey: 'store_name'
        },
        {
            header: 'Reference #',
            accessorKey: 'reference_number'
        },
        {
            header: 'Quantity',
            accessorKey: 'items_quantity',
        },
        {
            header: 'Ship To',
            accessorKey: 'ship_to',
            cell: (cell) => {
                const recipient = getJsonObject(cell.row.original.recipient);
                if (recipient.type === 'company') {
                    return 'Company Office'
                }

                if (recipient.type === 'store') {
                    return 'Store'
                }

                return 'Customer'
            }
        },
        {
            header: isForDrafts ? 'excludeForDrafts' : 'Status',
            accessorKey: 'status',
            cell: (cell) => {
                const value = cell.getValue();

                if (!value) {
                    return null;
                }

                const firstLetterUpperCaseValue = value.charAt(0).toUpperCase() + value.slice(1);

                let color;

                switch (value) {
                    case ORDER_STATUSES.SUBMITTED:
                        color = blueGrey[500]; break;
                    case ORDER_STATUSES.IN_PROCESS:
                        color = blue[500]; break;
                    case ORDER_STATUSES.PARTIALLY_COMPLETED:
                        color = orange[500]; break;
                    case ORDER_STATUSES.COMPLETED:
                        color = green[500]; break;
                    case ORDER_STATUSES.CANCELED:
                        color = red[500]; break;
                    default:
                        color = orange[500];
                }

                return (<Chip sx={{ backgroundColor: color, color: 'white' }} label={firstLetterUpperCaseValue} size="small" />);
            }
        },
        {
            header: isForDrafts ? 'excludeForDrafts' : 'Delivery Status',
            accessorKey: 'delivery_status',
            cell: (cell) => {
                const value = cell.getValue();

                if (!value) {
                    return null;
                }

                const firstLetterUpperCaseValue = value.charAt(0).toUpperCase() + value.slice(1);
                let color;

                switch (value) {
                    case ORDER_DELIVERY_STATUSES.MULTI:
                        color = purple[500]; break;
                    case ORDER_DELIVERY_STATUSES.FAILURE:
                        color = red[500]; break;
                    case ORDER_DELIVERY_STATUSES.RETURNED:
                    case ORDER_DELIVERY_STATUSES.UNKNOWN:
                        color = orange[500]; break;
                    default:
                        color = green[500];
                }

                return (<Chip sx={{ backgroundColor: color, color: 'white' }} label={firstLetterUpperCaseValue} size="small"/>);
            }
        },
        {
            header: 'Actions',
            accessorKey: 'actions',
            enableSorting: false,
            cell: (cell) => {
                return (
                    <OrderActions
                        order={cell.row.original}
                        setSelectedOrder={setSelectedOrder}
                        setOpenConfirmationDialog={setOpenConfirmationDialog}
                        setOpenWarningDialog={setOpenWarningDialog}
                        setOpenEditDraftDialog={setOpenEditDraftDialog}
                        setOpenConvertDraftDialog={setOpenConvertDraftDialog}
                    />
                )
            },
            meta: {
                sx: {
                    width: '0px'
                }
            }
        }
    ];

    return columns.filter(item => item.header !== 'excludeForDrafts');
};

const getProductColumns = (isForDrafts = false) => {
    const columns = [
        {
            header: 'Item ID',
            accessorKey: 'generated_item_id',
        },
        {
            header: 'Style ID',
            accessorKey: 'product.sku',
        },
        {
            header: 'Product Name',
            accessorKey: 'product.title',
        },
        {
            header: 'Options',
            accessorKey: 'options',
            cell: (cell) => {
                const optionsWithValues = generateSelectedOptionsWithValues(cell.row.original);
                return (
                    <ul>
                        {optionsWithValues.map(option => (<li key={option.title + option.value}>{option.title}: {option.value}</li>))}
                    </ul>
                )
            }
        },
        {
            header: 'Quantity',
            accessorKey: 'quantity',
        },
        {
            header: isForDrafts ? 'excludeForDrafts' : 'Status',
            accessorKey: 'status',
            cell: (cell) => {
                const value = cell.getValue();

                if (!value) {
                    return null;
                }

                const firstLetterUpperCaseValue = value.charAt(0).toUpperCase() + value.slice(1);
                let color;

                switch (value) {
                    case ORDER_STATUSES.SUBMITTED:
                        color = blueGrey[500]; break;
                    case ORDER_STATUSES.IN_PROCESS:
                        color = blue[500]; break;
                    case ORDER_STATUSES.COMPLETED:
                        color = green[500]; break;
                    case ORDER_STATUSES.CANCELED:
                        color = red[500]; break;
                    default:
                        color = orange[500];
                }

                return (<Chip sx={{ backgroundColor: color, color: 'white' }} label={firstLetterUpperCaseValue} size="small" />);
            }
        },
        {
            header: isForDrafts ? 'excludeForDrafts' : 'Delivery Status',
            accessorKey: 'delivery_status',
            cell: (cell) => {
                const value = cell.getValue();

                if (!value) {
                    return null;
                }

                const firstLetterUpperCaseValue = value.charAt(0).toUpperCase() + value.slice(1);
                let color;

                switch (value) {
                    case ORDER_ITEM_DELIVERY_STATUSES.FAILURE:
                        color = red[500]; break;
                    case ORDER_ITEM_DELIVERY_STATUSES.RETURNED:
                    case ORDER_ITEM_DELIVERY_STATUSES.UNKNOWN:
                        color = orange[500]; break;
                    default:
                        color = green[500];
                }

                return (<Chip sx={{ backgroundColor: color, color: 'white' }} label={firstLetterUpperCaseValue} size="small"/>);
            }
        },
        {
            header: isForDrafts ? 'excludeForDrafts' : 'Tracking Number',
            accessorKey: 'latest_parcel.tracking_number',
        },
        {
            header: isForDrafts ? 'excludeForDrafts' : 'Carrier',
            accessorKey: 'latest_parcel.carrier',
        },
    ];

    return columns.filter(item => item.header !== 'excludeForDrafts');
};

// ==============================|| REACT TABLE - EXPANDING SUB TABLE ||============================== //

export default function OrdersList({ caption, selectDrafts }) {
    const currentUser = useSelector(store => store.auth.authenticatedUser);
    const [selectedOrder, setSelectedOrder] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [productsLoading, setProductsLoading] = React.useState(true);
    const [expanded, setExpanded] = React.useState({});

    ////////////////////////////////////////////////////////////////

    const orders = useSelector(store => store.orders.items);
    const quantities = useSelector(store => store.orders.quantities);

    const filters = useSelector((store) => store.orders.filters);
    const stores = useSelector(store => store.stores.items);

    const [openEditDraftDialog, setOpenEditDraftDialog] = React.useState(false);
    const [openConfirmationDialog, setOpenConfirmationDialog] = React.useState(false);
    const [openWarningDialog, setOpenWarningDialog] = React.useState(false);
    const [openConvertDraftDialog, setOpenConvertDraftDialog] = React.useState(false);
    const [openExportCsvDialog, setOpenExportCsvDialog] = React.useState(false);

    const [searchParams, setSearchParams] = useSearchParams();

    const urlParams = React.useMemo(() => Object.fromEntries(searchParams.entries()), [searchParams]);

    React.useEffect(() => {
        if (filters.initialState) {
            const sortingParams = parseSortingParam(urlParams?.sort);

            setFiltersAction({
                ...filters,
                pagination: {
                    ...filters.pagination,
                    page: +urlParams?.page || filters.pagination.page,
                    perPage: +urlParams?.per_page || filters.pagination.perPage,
                },

                sorting: sortingParams.length ? sortingParams : filters.sorting,
                orderStatus: urlParams?.status || filters.orderStatus,
                globalFilter: urlParams?.filter || filters.globalFilter,
                startDate: urlParams?.start || filters.startDate,
                endDate: urlParams?.end || filters.endDate,
                storeIds: urlParams?.store_ids?.split(',')?.map(item => {
                    const num = Number(item);
                    return isNaN(num) ? item : num;
                }) || filters.storeIds,

                initialState: false
            });

            return;
        }

        setSearchParams(prepareUrlParams(filters));

    }, [filters, urlParams, setSearchParams]);

    React.useEffect(() => {
        return () => {
            setFiltersAction({resetFilters: true})
        };
    }, []);

////////////////////////////////////////////////////

    React.useEffect(() => {
        const getOrders = async () => {
            try {
                const params = prepareUrlParams(filters);
                const searchParams = new URLSearchParams();

                Object.entries(params).forEach(([key, value]) => {
                    if (Array.isArray(value)) {
                        value.forEach((val) => {
                            searchParams.append(`${key}[]`, val);
                        });
                    } else {
                        searchParams.append(key, value);
                    }
                });

                setLoading(true);
                await getOrdersAction(`select_drafts=${selectDrafts ? 1 : 0}&` + searchParams.toString());
            } catch (e) {
                AlertComponent.error(e.response.data.message);
            }

            setLoading(false);
        };

        if (!filters.initialState && !filters.pagination.totalWasUpdated) {
            getOrders();
        }
    }, [filters, selectDrafts]);

    const handleCancelOrderClick = async () => {
        try {
            setOpenConfirmationDialog(false);

            await updateOrderStatusAction(selectedOrder?.id, ORDER_STATUSES.CANCELED);

            setFiltersAction({
                ...filters,
                pagination: {
                    ...filters.pagination,
                    totalWasUpdated: false
                }
            });
        } catch (e) {
            AlertComponent.error(e.response.data.message);
        }
    };

    const paginationChangeHandler = (param) => {
        if (param.value === filters.pagination[param.paramName]) {
            return;
        }

        if (param.paramName === 'page') {
            if (param.value <= 0 ||
                param.value > Math.floor(filters.pagination.total / filters.pagination.perPage) + 1) {
                return;
            }
        }

        setExpanded({});

        setFiltersAction({
            ...filters,
            pagination: {
                ...filters.pagination,
                [param.paramName]: param.value
            }
        });
    };

    const storeClickHandler = (id) => {
        setExpanded({});

        if (filters.storeIds.includes(id)) {
            const filteredValues = filters.storeIds.filter(item => item !== id);

            setFiltersAction({
                ...filters,
                storeIds: filteredValues,
                pagination: {
                    ...filters.pagination,
                    page: 1
                }
            });
        } else {
            setFiltersAction({
                ...filters,
                storeIds: [...filters.storeIds, id],
                pagination: {
                    ...filters.pagination,
                    page: 1
                }
            });
        }
    };

    const setGlobalFilterHandler = (value) => {
        setExpanded({});

        setFiltersAction({
            ...filters,
            globalFilter: value ? value : null,
            pagination: {
                ...filters.pagination,
                page: 1
            }
        });
    };

    const setDateHandler = (paramName, value) => {
        setExpanded({});

        if (paramName === 'clearAll') {
            setFiltersAction({
                ...filters,
                startDate: null,
                endDate: null,
                pagination: {
                    ...filters.pagination,
                    page: 1
                }
            });

            return;
        }

        const localTime = dayjs.tz(value.format('YYYY-MM-DD HH:mm'), currentUser.timezone);
        const utcTime = localTime.utc().format('YYYY-MM-DD HH:mm');

        setFiltersAction({
            ...filters,
            [paramName]: utcTime,
            pagination: {
                ...filters.pagination,
                page: 1
            }
        });
    };

    const selectedOrdersStatusHandler = (value) => {
        setExpanded({});

        setFiltersAction({
            ...filters,
            orderStatus: value,
            pagination: {
                ...filters.pagination,
                page: 1
            }
        });
    };

    const setSortingHandler = (value) => {
        const sortingValue = value()[0];
        let newSortingParams = [];
        let itExists = false;

        setExpanded({});

        filters.sorting.forEach(item => {
            if (item.id === sortingValue.id) {
                itExists = true;

                if (item.desc) {
                    newSortingParams.push({id: item.id, desc: false});
                }
            } else {
                newSortingParams.push(item);
            }
        });

        if (!itExists) {
            newSortingParams.push({id: sortingValue.id, desc: true});
        }

        setFiltersAction({
            ...filters,
            sorting: newSortingParams
        });
    };

    const setExpandedHandler = async (value) => {
        const expandedValue = value();

        const key = Object.keys(expandedValue)[0];

        setExpanded(prev => ({
            ...prev,
            [key]: !prev[key]
        }));

        const order = orders[key];

        if (!order.products) {
            try {
                setProductsLoading(true);
                await getOrderProductsAction(order.id);
            } catch (e) {
                AlertComponent.error(e.response.data.message);
            }

            setProductsLoading(false);
        }
    };

    const columns = React.useMemo(() =>
        getOrderColumns(setSelectedOrder, setOpenConfirmationDialog, setOpenWarningDialog, setOpenEditDraftDialog,
            setOpenConvertDraftDialog, currentUser.timezone, selectDrafts),
        [selectDrafts, setSelectedOrder, currentUser.timezone]
    );
    const productColumns = React.useMemo(() => getProductColumns(selectDrafts), [selectDrafts]);

    return (
        <>
            <ReactTable {...{
                showDrafts: selectDrafts,
                caption,
                columns,
                subColumns: productColumns,
                data: orders,
                quantities,
                stores,
                filters,
                loading,
                productsLoading,
                paginationChangeHandler,
                storeClickHandler,
                setGlobalFilterHandler,
                setDateHandler,
                selectedOrdersStatusHandler,
                setSortingHandler,
                expanded,
                setExpandedHandler,
                setOpenExportCsvDialog
            }}
            />

            <OrderActionDialogs
                order={selectedOrder}
                handleCancelOrderClick={handleCancelOrderClick}
                openEditDraftDialog={openEditDraftDialog}
                setOpenEditDraftDialog={setOpenEditDraftDialog}
                openWarningDialog={openWarningDialog}
                setOpenWarningDialog={setOpenWarningDialog}
                openConfirmationDialog={openConfirmationDialog}
                setOpenConfirmationDialog={setOpenConfirmationDialog}
                openConvertDraftDialog={openConvertDraftDialog}
                setOpenConvertDraftDialog={setOpenConvertDraftDialog}
            />

            {openExportCsvDialog &&
                <ExportCsvDialog
                    setOpenExportCsvDialog={setOpenExportCsvDialog}
                />
            }
        </>
    );
}