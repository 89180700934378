import PropTypes from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

// material-ui
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// assets
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import IconButton from "../../components/IconButton";
import AuthWrapper from "../../components/AuthWrapper";
import {loginAction} from "../../store/actions/auth";
import {useSelector} from "react-redux";
import { useNavigate } from "react-router-dom";
import AlertComponent from "../../components/AlertComponent";

// ============================|| JWT - LOGIN ||============================ //

export default function LoginPage() {
    const siteKey = process.env.REACT_APP_GOOGLE_CAPTCHA_SITE_KEY;

    const navigate = useNavigate();
    const [captchaToken, setCaptchaToken] = React.useState(null);
    const [showPassword, setShowPassword] = React.useState(false);
    const { authenticatedUser } = useSelector(store => store.auth);

    if (authenticatedUser) {
        navigate('/');
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    window.onCaptchaSuccess = (token) => {
        setCaptchaToken(token);
    };

    React.useEffect(() => {
        if (siteKey) {
            const script = document.createElement("script");
            script.src = "https://www.google.com/recaptcha/enterprise.js";
            script.async = true;
            script.defer = true;
            document.body.appendChild(script);
        }
    }, [siteKey]);


    const handleSubmit = async (values) => {
        if (siteKey) {
            if (!captchaToken) {
                alert("Please complete the reCAPTCHA!");
                return;
            }

            const response = await fetch('/api/verify-captcha', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    token: captchaToken,
                }),
            });

            const data = await response.json();

            if (!data.success) {
                alert("CAPTCHA verification failed!");
                return;
            }
        }

        try {
            await loginAction(values);
        } catch (e) {
            AlertComponent.error(e.response.data.message);
        }
    };

    return (
        <AuthWrapper>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Stack direction="row" justifyContent="space-between" alignItems="baseline" sx={{ mb: { xs: -0.5, sm: 0.5 } }}>
                        <Typography variant="h3">Login</Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Formik
                        initialValues={{
                            email: '',
                            password: '',
                            submit: null
                        }}
                        validationSchema={Yup.object().shape({
                            email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                            password: Yup.string().max(255).required('Password is required')
                        })}
                        onSubmit={handleSubmit}
                    >
                        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => {
                            return (
                                <form noValidate onSubmit={handleSubmit}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Stack spacing={1}>
                                                <InputLabel htmlFor="email-login">Email Address</InputLabel>
                                                <OutlinedInput
                                                    id="email-login"
                                                    type="email"
                                                    value={values.email}
                                                    name="email"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    placeholder="Enter email address"
                                                    fullWidth
                                                    error={Boolean(touched.email && errors.email)}
                                                />
                                            </Stack>
                                            {touched.email && errors.email && (
                                                <FormHelperText error id="standard-weight-helper-text-email-login">
                                                    {errors.email}
                                                </FormHelperText>
                                            )}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Stack spacing={1}>
                                                <InputLabel htmlFor="password-login">Password</InputLabel>
                                                <OutlinedInput
                                                    fullWidth
                                                    error={Boolean(touched.password && errors.password)}
                                                    id="-password-login"
                                                    type={showPassword ? 'text' : 'password'}
                                                    value={values.password}
                                                    name="password"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword}
                                                                onMouseDown={handleMouseDownPassword}
                                                                edge="end"
                                                                color="secondary"
                                                            >
                                                                {showPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    placeholder="Enter password"
                                                />
                                            </Stack>
                                            {touched.password && errors.password && (
                                                <FormHelperText error id="standard-weight-helper-text-password-login">
                                                    {errors.password}
                                                </FormHelperText>
                                            )}
                                        </Grid>

                                        <Grid item xs={12} sx={{ mt: -1 }}>
                                            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                                                {siteKey ?
                                                <div
                                                    className="g-recaptcha"
                                                    data-sitekey={siteKey}
                                                    data-action="LOGIN"
                                                    data-callback="onCaptchaSuccess"
                                                /> : <div/>
                                                }

                                                <Link variant="h6" component={RouterLink} to={'/forgot-password'} color="text.primary">
                                                    Forgot Password?
                                                </Link>
                                            </Stack>
                                        </Grid>
                                        {errors.submit && (
                                            <Grid item xs={12}>
                                                <FormHelperText error>{errors.submit}</FormHelperText>
                                            </Grid>
                                        )}

                                        <Grid item xs={12}>
                                            <Button
                                                disableElevation
                                                disabled={isSubmitting || (siteKey && !captchaToken) || (Object.keys(errors).length > 0) || (Object.keys(touched).length === 0)}
                                                fullWidth
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                            >
                                                Login
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            )
                        }}
                    </Formik>
                </Grid>
            </Grid>
        </AuthWrapper>
    );
}

LoginPage.propTypes = { isDemo: PropTypes.bool };
