import * as React from 'react';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';


const TimePickerComponent = ({ state, option, onChangeHandler, stateFunction }) => {
    dayjs.extend(customParseFormat);

    const value = dayjs(state[option.name] || '12:00 AM', 'hh:mm A');

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
                sx={{
                    width: '100%'
                }}
                slotProps={{ textField: { size: 'small' } }}
                label={option.label || "Select Time"}
                value={value}
                onChange={
                    (newValue) => onChangeHandler(option, {target: {value: newValue.format('hh:mm A')}}, stateFunction)
                }
            />
        </LocalizationProvider>
    );
};

export default TimePickerComponent;