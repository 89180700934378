import * as React from 'react';
import './print-component-styles.css';
import dayjs from 'dayjs';

const PrintComponent = ({header, orderNumber, children}) => {
    const currentDate = dayjs();

    return (
        <>
            <div className={'print-header'}>
                {header}
            </div>

            <div className={'print-footer'}>
                <div>Order #{orderNumber}</div>
                <div>Printed: {currentDate.format('MMMM D, YYYY HH:mm')}</div>
                <div className={'page-number'}/>
            </div>

            <table className={'full-width'}>
                <thead className={'thead-for-print'}>
                <tr>
                    <td>
                        <div className={'header-placeholder'}/>
                    </td>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>
                        {children}
                    </td>
                </tr>
                </tbody>
                <tfoot className={'tfoot-for-print'}>
                <tr>
                    <td>
                        <div className={'footer-placeholder'}/>
                    </td>
                </tr>
                </tfoot>
            </table>
        </>
    );
};

export default PrintComponent