import * as React from "react";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {useTheme} from "@mui/material";

const WarningSubmitCancelDialog = ({ title, content, actionButtonText, closeButtonText = 'Cancel', actionHandler, closeHandler }) => {
    const theme = useTheme();

    return (
        <Dialog
            open={true}
            onClose={closeHandler}
        >
            <Box
                padding={'32px'}
                textAlign={'center'}
                maxWidth={'470px'}
            >
                <Box
                    sx={{
                        margin: '30px auto 10px auto',
                        '& img': {
                            width: '80px'
                        }
                    }}
                >
                    <img src={'/warning.png'} alt={'warning'}/>
                </Box>
                <Typography
                    variant={'h4'}
                    sx={{
                        marginBottom: '16px',
                        fontSize: '1.5rem'
                    }}
                >
                    {title}
                </Typography>
                <Typography
                    variant={'body1'}
                    sx={{
                        margin: '0 auto 32px auto',
                        maxWidth: '320px',
                        textAlign: 'center',
                        color: theme.palette.text.secondary,
                        fontSize: '1rem'
                    }}
                >
                    {content}
                </Typography>

                <Button
                    sx={{
                        width: '180px',
                        margin: '0 8px'
                    }}
                    variant="outlined"
                    color={'secondary'}
                    onClick={closeHandler}
                >
                    {closeButtonText}
                </Button>
                <Button
                    sx={{
                        width: '180px',
                        margin: '0 8px'
                    }}
                    variant="contained"
                    color={'primary'}
                    onClick={actionHandler}
                >
                    {actionButtonText}
                </Button>
            </Box>
        </Dialog>
    );
};

export default WarningSubmitCancelDialog;