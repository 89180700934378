import checkoutService from "../../services/checkoutService";
import {
    setCheckoutSettings,
    setPendingStatus,
    setReadyStatus
} from "../reducers/checkout";
import { dispatch } from "../index";

export const getCheckoutSettingsAction = async (activeStoreId, userId) => {
    dispatch(setPendingStatus());

    const response = await checkoutService.getCheckoutSettings(activeStoreId, userId);

    dispatch(setCheckoutSettings(response.data.data['settings']));
    dispatch(setReadyStatus());
};

export const setCheckoutSettingsAction = async (activeStoreId, userId, data) => {
    dispatch(setPendingStatus());

    const response = await checkoutService.setCheckoutSettings(activeStoreId, userId, data);

    dispatch(setCheckoutSettings(response.data.data['settings']));
    dispatch(setReadyStatus());
};