import * as React from 'react';
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";

const SelectComponent = ({ state, option, onChangeHandler, stateFunction }) => {
    const theme = useTheme();

    let maxImagesWidth = 0;
    const defaultImageWidth = 48;

    option.variants.filter(variant => variant.image).forEach(item => {
        const width = +item.image.width || defaultImageWidth;
        maxImagesWidth = maxImagesWidth >= width ? maxImagesWidth : width;
    });

    return (
        <FormControl
            sx={{
                width: "100%"
            }}
        >
            <Select
                size={"small"}
                name={option.name}
                value={state[option.name] || ''}
                onChange={event => onChangeHandler(option, event, stateFunction)}
                renderValue={(selectedValue) => option.variants.find(variant => variant.value === selectedValue).caption}
            >
                {option.variants.map(variant => (
                    <MenuItem
                        key={variant.value}
                        value={variant.value}
                        sx={{
                            paddingLeft: '8px'
                        }}
                    >
                        <Stack
                            direction="row"
                            sx={{
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            {variant.image &&
                            <img
                                src={variant.image.url}
                                alt={'product option'}
                                loading="lazy"
                                width={`${variant.image.width ? variant.image.width : defaultImageWidth}px`}
                            />
                            }

                            <Box
                                marginLeft={`${maxImagesWidth - (variant.image ? variant.image.width || defaultImageWidth : 0) + 8}px`}
                            >
                                <Typography variant={'body1'}>{variant.caption}</Typography>
                                {variant.helperText &&
                                <Box
                                    sx={{
                                        fontSize: '0.9em',
                                        color: theme.palette.text.secondary,
                                        whiteSpace: 'normal'
                                    }}
                                >
                                    {variant.helperText}
                                </Box>
                                }
                            </Box>
                        </Stack>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default SelectComponent;