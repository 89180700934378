import storesService from "../../services/storesService";
import {
    setStores,
    setPendingStatus,
    setReadyStatus,
    updateActiveStoreCompany
} from "../reducers/stores";
import { dispatch } from "../index";


export const getAllUserStoresAction = async (userId) => {
    dispatch(setPendingStatus());

    const response = await storesService.getAllUserStores(userId);

    dispatch(setStores(response.data.data.stores));
    dispatch(setReadyStatus());
};

export const updateCompanyShippingAddressAction = async (companyId, data) => {
    dispatch(setPendingStatus());

    const response = await storesService.updateCompanyShippingAddress(companyId, data);

    dispatch(updateActiveStoreCompany(response.data.data['company']));
    dispatch(setReadyStatus());
};