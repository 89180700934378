import * as React from 'react';
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from '@mui/material/styles';
import LinearProgress from "@mui/material/LinearProgress";
import {useNavigate} from "react-router-dom";

const OrderCreatedDialog = ({redirectPath, mainText, bottomText, closeDialogHandler, showProgressBar = false}) => {
    const theme = useTheme();
    const navigate = useNavigate();

    React.useEffect(() => {
        setTimeout(() => {
            if (typeof(closeDialogHandler) === 'function') {
                closeDialogHandler();
            } else {
                navigate(redirectPath);
            }
        }, 2000);
    }, []);

    return (
        <Dialog
            open={true}
        >
            <Box
                padding={'32px'}
                textAlign={'center'}
                width={'400px'}
            >
                <Box
                    sx={{
                        margin: '16px auto 10px auto',
                        '& img': {
                            width: '80px'
                        }
                    }}
                >
                    <img src={'/success.png'} alt={'success'}/>
                </Box>

                <Typography
                    variant={'h4'}
                    sx={{
                        marginBottom: '16px'
                    }}
                >
                    {mainText}
                </Typography>

                {showProgressBar &&
                    <LinearProgress
                        variant="determinate"
                        value={100}
                        color={'success'}
                    />
                }

                <Typography
                    variant={'body1'}
                    sx={{
                        marginTop: '16px',
                        color: theme.palette.text.secondary
                    }}
                >
                    {bottomText}
                </Typography>
            </Box>
        </Dialog>
    );
};

export default OrderCreatedDialog;