import * as React from 'react';
import Paper from "@mui/material/Paper";
import { yellow } from '@mui/material/colors';
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import UploadedFilesList from "./UploadedFilesList";

dayjs.extend(utc);
dayjs.extend(timezone);

const VersionFeedbacks = ({ currentUser, feedbacks }) => {
    return (
        <React.Fragment>
            {
                feedbacks.map((feedback, index) => {
                    const feedbackDate = dayjs.utc(feedback.created_at).tz(currentUser.timezone).format('MM/DD/YYYY');
                    return (
                        <Paper
                            key={index}
                            variant={'outlined'}
                            sx={{
                                p: 2,
                                backgroundColor: yellow[50]
                            }}
                        >
                            <Typography
                                variant={'h6'}
                                sx={{
                                    fontSize: '16px',
                                    mb: 1
                                }}
                            >
                                Submitted Feedback by {feedback.user?.first_name} {feedback.user?.last_name} on {feedbackDate}
                            </Typography>

                            <Typography
                                variant={'body1'}
                            >
                                {feedback.comment}
                            </Typography>

                            {feedback.files?.length > 0 &&
                                <Stack
                                    spacing={1}
                                    sx={{
                                        justifyContent: 'center',
                                        mt: 4
                                    }}
                                >
                                    <Typography
                                        variant={'body1'}
                                        sx={{
                                            textAlign: 'center'
                                        }}
                                    >
                                        Uploaded attachments:
                                    </Typography>

                                    <UploadedFilesList
                                        files={feedback.files}
                                        itemsBackgroundColor={yellow[50]}
                                    />
                                </Stack>
                            }
                        </Paper>
                    );
                })
            }
        </React.Fragment>
    )
};

export default VersionFeedbacks;