import * as React from 'react';

import {getJsonObject} from "../../utils/helpers";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import CloseIcon from '@mui/icons-material/Close';

import {
    generateOptionElement,
    getInitialStateByDefaultValues,
    getRequiredFieldsCounts
} from "../../components/dynamicForms/utils";
import Popover from "@mui/material/Popover";
import Box from "@mui/material/Box";
import DynamicFormContent from "../../components/dynamicForms/DynamicFormContent";
import {useSelector} from "react-redux";


const AddStoneDialog = ({ stoneOptions, saveAndNewHandler, saveHandler, closeHandler }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [tooltip, setTooltip] = React.useState({type: 'text', value: ''});
    const [enteredOptions, setEnteredOptions] = React.useState(stoneOptions);

    const formsConfig = useSelector(store => store.customQuotes.formsConfig);

    const config = React.useMemo(() => getJsonObject(formsConfig)?.addStoneForm, [formsConfig]);
    //const config = data.addStoneForm;

    const requiredFieldsCounts = getRequiredFieldsCounts(enteredOptions, config);
    const disableSaveButtons = requiredFieldsCounts.filled < requiredFieldsCounts.required;

    React.useEffect(() => {
        if (Object.keys(stoneOptions).length === 0) {
            setEnteredOptions(getInitialStateByDefaultValues(config));
        } else {
            setEnteredOptions(stoneOptions);
        }
    }, [config]);

    return (
        <Dialog
            aria-labelledby="customized-dialog-title"
            open={true}
            fullWidth={true}
        >
            <DialogTitle
                id="customized-dialog-title"
                sx={{
                    fontWeight: 600
                }}
            >
                {config?.title}
            </DialogTitle>

            <IconButton
                aria-label="close"
                onClick={closeHandler}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>

            <DialogContent dividers>
                <DynamicFormContent
                    form={config}
                    generateElement={(option) => generateOptionElement(option, enteredOptions, setEnteredOptions, setAnchorEl, setTooltip, true)}
                />
            </DialogContent>

            <DialogActions
                sx={{
                    padding: '16px'
                }}
            >
                <Button
                    color={'error'}
                    onClick={() => {
                        setEnteredOptions({});
                        closeHandler();
                    }}
                >
                    Close
                </Button>
                <Button
                    variant={"contained"}
                    onClick={() => {
                        saveAndNewHandler(enteredOptions);
                        setEnteredOptions({});
                    }}
                    disabled={disableSaveButtons}
                >
                    Save & Create New
                </Button>
                <Button
                    variant={"contained"}
                    onClick={() => {
                        saveHandler(enteredOptions);
                        setEnteredOptions({});
                    }}
                    disabled={disableSaveButtons}
                >
                    Save & Close
                </Button>
            </DialogActions>

            {!!anchorEl &&
            <Popover
                open={true}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                {tooltip.type === 'img' ?
                    <Box component={'img'}
                         src={tooltip.value}
                         maxWidth={'300px'}
                         border={'1px solid black'}
                    />
                    :
                    <Typography sx={{ p: 2 }}>{tooltip.value}</Typography>
                }
            </Popover>
            }

        </Dialog>
    );
};

export default AddStoneDialog;