import * as React from 'react';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import MediaPreviewDialog from "./MediaPreviewDialog";
import useTheme from "@mui/material/styles/useTheme";

const VersionMedia = ({ media }) => {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [currentMedia, setCurrentMedia] = React.useState(null);

    const handleOpen = (selectedMedia) => {
        setCurrentMedia(selectedMedia);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setCurrentMedia(null);
    };

    const VideoPlayer = ({ src }) => {
        const videoRef = React.useRef(null);

        React.useEffect(() => {
            if (videoRef.current) {
                videoRef.current.load();
            }
        }, [src]);

        return (
            <video
                ref={videoRef}
                autoPlay
                loop
                muted
                style={{ width: '100%' }}
            >
                <source src={src} type="video/mp4" />
                Your browser does not support playing video files.
            </video>
        );
    };

    return (
        <>
            <Grid
                container
                spacing={3}
            >
                {media.files?.map((file, index) => (
                    <Grid
                        item
                        xs={6}
                        key={index}
                        onClick={() => handleOpen(file)}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '100%',
                                height: '100%',
                                cursor: 'pointer',
                                '&:hover': {
                                    border: `1px solid ${theme.palette.primary.main}`
                                }
                            }}
                        >
                            {file.type === 'image' ? (
                                <img
                                    src={file.url}
                                    alt={`media-${index}`}
                                    style={{ width: '100%'}}
                                />
                            ) : (
                                <VideoPlayer
                                    src={file.url}
                                />
                            )}
                        </Box>
                    </Grid>
                ))}
            </Grid>

            {open &&
                <MediaPreviewDialog
                    currentMedia={currentMedia}
                    handleClose={handleClose}
                />
            }
        </>
    )
};

export default VersionMedia;