import * as React from "react";
import Menu from "@mui/material/Menu";

const MenuWrapper = ({ anchorElement, closeHandler, children, minWidth, horizontalAlign = 'right' }) => (
        <Menu
            anchorEl={anchorElement}
            open={!!anchorElement}
            onClose={closeHandler}
            onClick={closeHandler}
            PaperProps={{
                elevation: 0,
                sx: {
                    minWidth: `${minWidth ? minWidth : 'auto'}`,
                    overflow: 'auto',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                    },
                    '&::before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        [horizontalAlign]: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                    },
                },
            }}
            anchorOrigin={{ vertical: 'bottom', horizontal: horizontalAlign }}
            transformOrigin={{ vertical: 'top', horizontal: horizontalAlign }}
        >
            {children}
        </Menu>
);

export default MenuWrapper;