import {authApi} from './apiService'

const getUsersList = async (params) => authApi.get(`users?${params}`);
const getStoresList = async (params) => authApi.get(`stores?${params}`);
const getProductsList = async (params) => authApi.get(`products/list?${params}`);

const getRoles = async () => authApi.get(`/roles`);

const getUserStores = async (userId) => authApi.get(`/users/${userId}/stores`);
const getStoreUsers = async (storeId) => authApi.get(`/stores/${storeId}/users`);
const getProductStores = async (productId) => authApi.get(`/products/${productId}/stores`);

const createUser = async (data) => authApi.post(`/users`, data);
const createStore = async (data) => authApi.post(`/stores`, data);

const updateUser = async (userId, data) => authApi.patch(`/users/${userId}`, data);
const updateStore = async (storeId, data) => authApi.patch(`/stores/${storeId}`, data);
const updateProductPrice = async (productId, data) => authApi.patch(`/products/${productId}/price`, data);

const getAvailableStores = async () => authApi.get(`/stores/available`);

const uploadImageAndGetURL = async (formData) => authApi.post(`/stores/upload-logo`, formData);

const apiCalls = {
    getUsersList,
    getStoresList,
    getProductsList,
    getProductStores,
    getRoles,
    getUserStores,
    getStoreUsers,
    createUser,
    createStore,
    updateUser,
    updateStore,
    updateProductPrice,
    getAvailableStores,
    uploadImageAndGetURL
};

export default apiCalls;