import React, {useState} from 'react';
import { useLocation, useNavigate} from "react-router-dom";
import AuthWrapper from "../../components/AuthWrapper";
import RestorePasswordForm from "./RestorePasswordForm";
import OutdatedTokenForm from "./OutdatedTokenForm";
import {checkPasswordTokenAction} from "../../store/actions/auth";

const RestorePassword = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [token, setToken] = useState('');
    const [isTokenValid, setIsTokenValid] = useState(true);

    React.useEffect(() => {
        const checkTokenFunction = async (token) => {
            setIsTokenValid(await checkPasswordTokenAction(token));
        };

        if (!token) {
            return;
        }

        checkTokenFunction(token);
    }, [token]);

    React.useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const tokenParam = queryParams.get('token');

        if (tokenParam) {
            setToken(tokenParam);
        } else {
            navigate('/');
        }
    }, [location, navigate]);

    React.useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const tokenParam = queryParams.get('token');

        if (tokenParam) {
            setToken(tokenParam);
        } else {
            navigate('/');
        }
    }, [location, navigate]);

    return (
        <AuthWrapper>
            {isTokenValid &&
                <RestorePasswordForm
                    token={token}
                />
            }

            {!isTokenValid &&
                <OutdatedTokenForm/>
            }
        </AuthWrapper>
    );
};

export default RestorePassword;