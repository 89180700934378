import { createSlice } from '@reduxjs/toolkit'
import {STATE_STATUSES} from "../../utils/helpers";

const initialState = {
    allStores: [],
    status: STATE_STATUSES.INIT,
};

export const companySlice = createSlice({
    name: 'company',
    initialState,
    reducers: {
        setPendingStatus: (state) => {
            state.status = STATE_STATUSES.PENDING;
        },

        setAllStores: (state, action) => {
            state.allStores = action.payload;
        },

        setReadyStatus: (state) => {
            state.status = STATE_STATUSES.READY;
        },
    },
});

export const {
    setPendingStatus,
    setAllStores,
    setReadyStatus
} = companySlice.actions;

export default companySlice.reducer;