import * as React from 'react';
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import {Typography} from "@mui/material";
import Divider from "@mui/material/Divider";
import useTheme from "@mui/material/styles/useTheme";

const VersionDetails = ({ details }) => {
    const theme = useTheme();

    return (
        <Paper
            variant={'outlined'}
        >
            <Stack>
                {details.header &&
                    <>
                        <Typography
                            variant={'body1'}
                            sx={{
                                p: 2,
                                fontSize: '15px',
                                fontWeight: '600'
                            }}
                        >
                            {details.header}
                        </Typography>

                        <Divider/>
                    </>
                }

                {
                    details.lines?.map((line, index) => {
                        return (
                            <React.Fragment
                                key={index}
                            >
                                <Stack
                                    direction={'row'}
                                    spacing={4}
                                    p={2}
                                >
                                    {line.parameters?.map((param, paramIndex) => {
                                        return (
                                            <Stack
                                                key={paramIndex}
                                            >
                                                <Typography
                                                    variant={'body1'}
                                                    color={theme.palette.secondary.main}
                                                >
                                                    {param.caption}
                                                </Typography>
                                                <Typography
                                                    variant={'body1'}
                                                >
                                                    {param.value}
                                                </Typography>
                                            </Stack>
                                        );
                                    })}
                                </Stack>
                                <Divider/>
                            </React.Fragment>
                        );
                    })
                }

                {details.footer &&
                    <Typography
                        variant={'body1'}
                        sx={{
                            p: 2,
                            fontWeight: '600',
                            fontSize: '13px'
                        }}
                    >
                        {details.footer}
                    </Typography>
                }
            </Stack>
        </Paper>
    )
};

export default VersionDetails;