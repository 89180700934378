import * as React from 'react';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';

import Paper from "@mui/material/Paper";
import {getJsonObject} from "../../utils/helpers";
import Button from "@mui/material/Button";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Box from "@mui/material/Box";
import SingleStoneParamItem from "../../components/dynamicForms/SingleStoneParamItem";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import UploadedFilesList from "./UploadedFilesList";

const OptionsTable = ({questions}) => {
    return (
        <TableContainer
            component={(props) => <Paper {...props} variant="outlined" />}
            sx={{
                overflowY: 'none',
            }}
        >
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell>Parameter</TableCell>
                        <TableCell>Value</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {questions?.map((question, index) => (
                        <TableRow
                            key={question.title + index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell>{question.title}</TableCell>
                            <TableCell>{question.value}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const SuppliedStonesList = ({suppliedStones}) => {
    return (
        <Paper
            variant={'outlined'}
            sx={{
                marginTop: 3,
                padding: 2
            }}
        >
            <Stack>
                <Typography
                    variant={'h2'}
                    sx={{
                        fontSize: '1rem',
                        marginBottom: 1
                    }}
                >
                    Stone List
                </Typography>

                <Divider/>

                {suppliedStones?.map((stone, index) => {
                    return (
                        <React.Fragment
                            key={index}
                        >
                            <Box
                                sx={{
                                    marginTop: 1,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    flexWrap: 'wrap',
                                    '& > :not(:first-child):not(:last-child)': {
                                        marginLeft: 1,
                                        marginRight: 1
                                    },
                                    '& > :first-child': {
                                        marginRight: 1
                                    },
                                    '& > :last-child': {
                                        marginLeft: 1
                                    }
                                }}
                            >
                                {
                                    stone.map((param, paramIndex) => {
                                        return (
                                            <SingleStoneParamItem
                                                key={param.title + paramIndex}
                                                title={param.title}
                                                value={param.value ? param.value : '-'}
                                            />
                                        )
                                    })
                                }
                            </Box>
                            {(suppliedStones.length - 1 > index) &&
                            <Divider/>
                            }
                        </React.Fragment>
                    )
                })}
            </Stack>
        </Paper>
    );
};

const AttachmentsList = ({attachments}) => {
    return (
        <Paper
            variant={'outlined'}
            sx={{
                marginTop: 3,
                padding: 2
            }}
        >
            <Stack>
                <Typography
                    variant={'h2'}
                    sx={{
                        fontSize: '1rem',
                        marginBottom: 1
                    }}
                >
                    Attachments
                </Typography>

                <Divider
                    sx={{
                        mb: 2
                    }}
                />

                <UploadedFilesList
                    files={attachments}
                    justifyContent={'left'}
                />
            </Stack>
        </Paper>
    );
};

const OriginalSubmissionDialog = ({customQuote, closeHandler}) => {
    const originalSubmission = getJsonObject(customQuote?.original_submission);

    return (
        <Dialog
            aria-labelledby="customized-dialog-title"
            open={true}
            fullWidth={true}
            maxWidth={'md'}
        >
            <DialogTitle
                id="customized-dialog-title"
                sx={{
                    fontWeight: 600
                }}
            >
                Original Submission
            </DialogTitle>

            <IconButton
                aria-label="close"
                onClick={closeHandler}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>

            <DialogContent
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box
                    sx={{
                        overflow: 'auto'
                    }}
                >
                    {originalSubmission?.questions &&
                        <OptionsTable
                            questions={originalSubmission.questions}
                        />
                    }

                    {originalSubmission?.suppliedStones &&
                        <SuppliedStonesList
                            suppliedStones={originalSubmission.suppliedStones}
                        />
                    }

                    {customQuote?.files?.length > 0 &&
                        <AttachmentsList
                            attachments={customQuote.files}
                        />
                    }
                </Box>
                <Button
                    variant="contained"
                    onClick={closeHandler}
                    sx={{
                        mt: 2,
                        width: '100px',
                        alignSelf: 'center',
                    }}
                >
                    Close
                </Button>
            </DialogContent>
        </Dialog>);
};

export default OriginalSubmissionDialog;