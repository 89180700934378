import {authApi} from './apiService'

const getCustomQuotes = async (params) => authApi.get(`custom-quotes?${params}`);
const getCustomQuote = async (id) => authApi.get(`custom-quotes/${id}`);
const storeCustomQuote = async (data) => authApi.post(`custom-quotes/`, data);
const cancelCustomQuote = async (id) => authApi.post(`custom-quotes/${id}/cancel`);
const convertCustomQuoteToOrder = async (quoteId, data) => authApi.post(`custom-quotes/${quoteId}/convert`, data);
const getCustomQuoteFormsConfig = async () => authApi.get('custom-quotes/form-parameters');
const getCustomQuoteVersionFeedbacks = async (quoteId, versionId) => authApi.get(`custom-quotes/${quoteId}/versions/${versionId}/feedbacks`);
const storeCustomQuoteVersionFeedback = async (quoteId, versionId, data) => authApi.post(`custom-quotes/${quoteId}/versions/${versionId}/feedbacks`, data);
const getPresentationLink = async (quoteId, versionId, forceRefresh = false) => authApi.get(`custom-quotes/${quoteId}/versions/${versionId}/presentation-link${forceRefresh ? '?force_refresh=1' : ''}`);

const apiCalls = {
    getCustomQuotes,
    getCustomQuote,
    storeCustomQuote,
    cancelCustomQuote,
    convertCustomQuoteToOrder,
    getCustomQuoteFormsConfig,
    getCustomQuoteVersionFeedbacks,
    storeCustomQuoteVersionFeedback,
    getPresentationLink
};

export default apiCalls;