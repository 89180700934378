import axios from "axios";
//import jwtDecode from 'jwt-decode';
//import {refreshTokenAction} from "../store/actions/auth";

const baseURL = `${process.env['REACT_APP_API_URL']}/api/`;

const publicApi = axios.create({ baseURL });

const authApi = axios.create({ baseURL });

authApi.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('auth_token');

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// const apiOnFulfilled = (response) => response.data;
// const apiOnRejected = (error) => {
//   const { response: {data, status} } = error;
//
//   // eslint-disable-next-line prefer-promise-reject-errors
//   return Promise.reject({...data, status});
// };
//
// const checkToken = () => {
//   const accessToken = localStorage.getItem('auth_token');
//   if (!accessToken) return ({
//     token: null,
//     isValid: false
//   });
//
//   const decoded = jwtDecode(accessToken);
//   const currentTime = Date.now() / 1000;
//
//   return {
//     token: accessToken,
//     isValid: decoded.exp > currentTime
//   }
// };
//
// const handleAuthToken = async (config) => {
//   const setToken = (token) => config.headers.Authorization = `Bearer ${token}`;
//
//   const {token, isValid} = checkToken();
//   if (!token) return config;
//
//   setToken(token);
//   if (config.url !== 'auth/refresh') {
//     if (!isValid) {
//       const newToken = await refreshTokenAction();
//       if (newToken) {
//         setToken(newToken);
//         localStorage.setItem('auth_token', newToken);
//       } else {
//         localStorage.removeItem('auth_token')
//       }
//     }
//   }
//   return config;
// };
//
//
// authApi.interceptors.request.use(handleAuthToken, (error) => Promise.reject(error));
//
// publicApi.interceptors.response.use(apiOnFulfilled, apiOnRejected);
// authApi.interceptors.response.use(apiOnFulfilled, apiOnRejected);

export {publicApi, authApi};
