import * as React from 'react';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AuthWrapper from "../../components/AuthWrapper";

// ================================|| CHECK MAIL ||================================ //

export default function CheckEmailPage() {
    return (
        <AuthWrapper>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Box sx={{ mb: { xs: -0.5, sm: 0.5 } }}>
                        <Typography variant="h3">Check Your Email</Typography>
                        <Typography color="secondary" sx={{ mb: 0.5, mt: 1.25 }}>
                            We've sent a password recovery link to your email address.
                            <br/>
                            If you don't see the email, please check your junk or spam folder.
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                        <Button
                            component={Link}
                            to={'/login'}
                            disableElevation
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            color="primary"
                        >
                            Back to login
                        </Button>
                </Grid>
            </Grid>
        </AuthWrapper>
    );
}