import * as React from 'react';

import {getCustomQuoteFormsConfigAction} from '../../store/actions/customQuotes'
import {getJsonObject} from "../../utils/helpers";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import CloseIcon from '@mui/icons-material/Close';

import {
    generateOptionElement,
    getInitialStateByDefaultValues, getOriginalTitleAndOptionValue,
    getRequiredFieldsCounts
} from "../../components/dynamicForms/utils";
import Popover from "@mui/material/Popover";
import Box from "@mui/material/Box";
import DynamicFormContent from "../../components/dynamicForms/DynamicFormContent";
import {useSelector} from "react-redux";
import CancellationDialog from "../checkout/components/CancellationDialog";
import OrderCanceledDialog from "../checkout/components/OrderCanceledDialog";
import AlertComponent from "../../components/AlertComponent";

const prepareFormData = (formsConfig, enteredOptions) => {
    const formsConfigObj = getJsonObject(formsConfig);

    const mainOptions = [];

    formsConfigObj.mainForm?.sections?.forEach(section => {
        section.options.forEach(option => {
            mainOptions.push(option);
        });
    });

    const stoneFormOptions = [];

    formsConfigObj.addStoneForm?.sections?.forEach(section => {
        section.options.forEach(option => {
            stoneFormOptions.push(option);
        });
    });

    const filesParams = [];
    const stonesParams = [];

    const allFiles = [];
    const allStones = [];

    const preparedEnteredOptions = {};
    const originalTitlesAndValues = {
        questions: [],
        attachments: [],
        suppliedStones: []
    };

    Object.keys(enteredOptions).forEach((key) => {
        if (!Array.isArray(enteredOptions[key])) {
            originalTitlesAndValues.questions.push(getOriginalTitleAndOptionValue(key, enteredOptions[key], mainOptions));
            preparedEnteredOptions[key] = enteredOptions[key];
        } else {
            const optionType = mainOptions.find(option => option.name === key)?.type;

            if (optionType === 'files') {
                enteredOptions[key].forEach(file => {
                    allFiles.push(file);
                    filesParams.push({name: file.name, type: file.type});
                });
            }

            if (optionType === 'stonesList') {
                enteredOptions[key].forEach(stoneParams => {
                    allStones.push(stoneParams);

                    const originalStoneParams = [];

                    stoneFormOptions.forEach(option => {
                        originalStoneParams.push(getOriginalTitleAndOptionValue(option.name, stoneParams[option.name] ?? '', stoneFormOptions));
                    });

                    stonesParams.push(originalStoneParams);
                });
            }
        }
    });

    originalTitlesAndValues.attachments = filesParams;
    originalTitlesAndValues.suppliedStones = stonesParams;

    preparedEnteredOptions['stones'] = allStones;

    const formData = new FormData();

    allFiles.forEach((file, index) => {
        formData.append(`files[${index}]`, file);
    });

    formData.append('original_submission', JSON.stringify(originalTitlesAndValues));
    formData.append('options', JSON.stringify(preparedEnteredOptions));

    if (enteredOptions['reference_number']) {
        formData.append('reference_number', enteredOptions['reference_number']);
    }

    return formData;
};

const AddCustomQuoteDialog = ({ createCustomQuoteHandler, handleClose }) => {
    const formsConfig = useSelector(store => store.customQuotes.formsConfig);
    const [enteredOptions, setEnteredOptions] = React.useState({});
    const [tooltip, setTooltip] = React.useState({ type: 'text', value: '' });
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openCancellationDialog, setOpenCancellationDialog] = React.useState(false);
    const [openOrderCanceledDialog, setOpenOrderCanceledDialog] = React.useState(false);

    const createHandlerWasCalled = React.useRef(false);

    const config = React.useMemo(() => getJsonObject(formsConfig)?.mainForm, [formsConfig]);

    const requiredFieldsCounts = React.useMemo(
        () => getRequiredFieldsCounts(enteredOptions, config),
        [enteredOptions, config]
    );
    const disableSaveButtons = requiredFieldsCounts.filled < requiredFieldsCounts.required;

    React.useEffect(() => {
        const initializeForm = async () => {
            if (!config) {
                try {
                    await getCustomQuoteFormsConfigAction();
                } catch (e) {
                    AlertComponent.error(e.response.data.message);
                }
            } else {
                setEnteredOptions(getInitialStateByDefaultValues(config));
            }
        };
        initializeForm();
    }, [config]);

    const handleCreateOrder = React.useCallback(async () => {
        if (createHandlerWasCalled.current) {
            return;
        }

        createHandlerWasCalled.current = true;

        const preparedData = prepareFormData(formsConfig, enteredOptions);
        await createCustomQuoteHandler(preparedData);
        setOpenCancellationDialog(false);
    }, [createCustomQuoteHandler, formsConfig, enteredOptions]);

    return (
        <>
            <Dialog open={true} fullWidth maxWidth="md">
                <DialogTitle sx={{ fontWeight: 600 }}>
                    {config?.title}
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: theme => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <DynamicFormContent
                        form={config}
                        generateElement={(option) =>
                            generateOptionElement(option, enteredOptions, setEnteredOptions, setAnchorEl, setTooltip, true)
                        }
                    />
                </DialogContent>
                <DialogActions sx={{ padding: '16px' }}>
                    <Button color="error" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={() => setOpenCancellationDialog(true)} disabled={disableSaveButtons}>
                        Submit For Quotation
                    </Button>
                </DialogActions>
            </Dialog>

            {!!anchorEl && (
                <Popover
                    open={true}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                >
                    {tooltip.type === 'img' ? (
                        <Box component="img" src={tooltip.value} maxWidth="300px" border="1px solid black" />
                    ) : (
                        <Typography sx={{ p: 2 }}>{tooltip.value}</Typography>
                    )}
                </Popover>
            )}

            {openCancellationDialog && (
                <CancellationDialog
                    mainText="Quote submission in progress..."
                    bottomText="Do not refresh or close this page until your quote is submitted."
                    cancelButtonText="Cancel Quote"
                    createOrderHandler={handleCreateOrder}
                    closeDialogHandler={() => {
                        setOpenCancellationDialog(false);
                        setOpenOrderCanceledDialog(true);
                    }}
                />
            )}

            {openOrderCanceledDialog && (
                <OrderCanceledDialog mainText="Quote submission canceled." closeDialogHandler={() => setOpenOrderCanceledDialog(false)} />
            )}
        </>
    );
};

export default AddCustomQuoteDialog;