import * as React from 'react';
import { useReactToPrint } from 'react-to-print';
import {Link as RouterLink, useParams} from 'react-router-dom';
import DefaultLayout from "../../components/layouts/DefaultLayout/DefaultLayout";
import MainCard from "../../components/MainCard";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import {getOrderAction } from "../../store/actions/orders";
import {useSelector} from "react-redux";

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import Dialog from "@mui/material/Dialog";
import {generateSelectedOptionsWithValues, isOrderItemForCustomQuote} from "../../components/dynamicForms/utils";
import {PDFDownloadLink} from "@react-pdf/renderer";
import ExportPDFView from "./export-pdf";
import { useTheme } from '@mui/material/styles';
import Barcode from 'react-barcode';
import {getJsonObject, getProductMainImage} from "../../utils/helpers";
import BarcodeComponent from "./BarcodeComponent";
import { createRoot } from 'react-dom/client';
import PrintComponent from "./PrintComponent";
import AlertComponent from "../../components/AlertComponent";
import Link from "@mui/material/Link";

dayjs.extend(utc);
dayjs.extend(timezone);

const OrderDetails = () => {
    const { id } = useParams();
    const theme = useTheme();

    const currentUser = useSelector(store => store.auth.authenticatedUser);
    const order = useSelector(store => store.orders.selectedItem);
    const activeStore = useSelector(store => store.stores.activeStore);

    const [isLoading, setIsLoading] = React.useState(false);
    const [openPrintDialog, setOpenPrintDialog] = React.useState(false);
    const [hideForCustomerCopy, setHideForCustomerCopy] = React.useState(false);

    const [orderIdBarcodeData, setOrderIdBarcodeData] = React.useState('');
    const [barcodeDataArray, setBarcodeDataArray] = React.useState([]);

    const componentRef = React.useRef(null);

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        onAfterPrint: () => {
            setHideForCustomerCopy(false);
        }
    });

    React.useEffect(() => {
        const getOrder = async () => {
            try {
                setIsLoading(true);
                await getOrderAction(id);
            } catch (e) {
                AlertComponent.error(e.response.data.message);
            }
            setIsLoading(false);
        };

        getOrder();
    }, [id]);

    React.useEffect(() => {
        if (hideForCustomerCopy) {
            handlePrint();
        }
    }, [hideForCustomerCopy, handlePrint]);

    React.useEffect(() => {
        const generateBarcodes = async () => {
            const barcodes = [];

            for (let i = 0; i < order?.items?.length; i++) {
                const barcodeData = await new Promise((resolve) => {
                    const barcodeComponent = (
                        <BarcodeComponent
                            value={order.items[i].generated_item_id}
                            setBarcodeData={resolve}
                        />
                    );
                    const container = document.createElement('div');

                    document.body.appendChild(container);
                    createRoot(container).render(barcodeComponent);
                });

                barcodes.push(barcodeData);
            }

            setBarcodeDataArray(barcodes);
        };

        generateBarcodes();
    }, [order]);

    const total = order?.items?.reduce((acc, item) => +item.product_price * item.quantity + acc, 0) || 0;

    const orderDate = order ? dayjs.utc(order.created_at).tz(currentUser.timezone).format('MMMM D, YYYY') : null;

    const handlePrintCustomerCopyClick = () => {
        setHideForCustomerCopy(true);
        setOpenPrintDialog(false);
    };

    const handlePrintInternalCopyClick = () => {
        setOpenPrintDialog(false);
        handlePrint();
    };

    const recipient = getJsonObject(order?.recipient) || {};
    const isForCustomQuote = !order?.items ? false : isOrderItemForCustomQuote(order.items?.[0]);

    const printingContent = (
        <Grid container spacing={2.5}>
            <Grid item xs={12}>
                <Stack direction='row' justifyContent="space-between" flexWrap='wrap-reverse'>
                    <Box>
                        <Stack direction="row" spacing={2}>
                            <img
                                src={order?.store?.logo}
                                alt={'Logo'}
                                loading="lazy"
                                height={'180px'}
                            />
                        </Stack>
                    </Box>


                    <Box
                        sx={{
                            '& tr td:first-of-type': {
                                textAlign: 'right',
                                paddingRight: 1,
                                fontWeight: 600
                            },
                            '& tr td:last-of-type': {
                                color: theme.palette.text.secondary
                            }
                        }}
                    >
                        <table>
                            <tbody>
                            <tr>
                                <td>Order ID</td>
                                <td>
                                    <Barcode
                                        value={order?.generated_order_id || '0'}
                                        format={'CODE128'}
                                        height={25}
                                        width={1}
                                        fontSize={14}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Reference #</td>
                                <td>{order?.reference_number}</td>
                            </tr>
                            <tr>
                                <td>Order Date</td>
                                <td>{orderDate}</td>
                            </tr>
                            <tr>
                                <td>Ordered By</td>
                                <td>{order?.user_name}</td>
                            </tr>
                            <tr>
                                <td>Store Location</td>
                                <td>{order?.store?.name}</td>
                            </tr>

                            {order?.custom_quote_id &&
                                <tr>
                                    <td>Custom Quote ID</td>
                                    <td>
                                        <Link variant="h6" component={RouterLink} to={`/custom-quote-details/${order.custom_quote_id}`}>
                                            {order.custom_quote_generated_id}
                                        </Link>
                                    </td>
                                </tr>
                            }
                            </tbody>
                        </table>
                    </Box>
                </Stack>
            </Grid>

            {(!hideForCustomerCopy || (hideForCustomerCopy && recipient?.type === 'customer')) &&
            <Grid item xs={12} sm={6}>
                <MainCard>
                    <Stack spacing={1}>
                        <Typography variant="h5">SHIP TO: {recipient?.type?.toUpperCase()}</Typography>
                        {isLoading ? (
                            <Stack spacing={0.5}>
                                <Skeleton/>
                                <Skeleton width={60}/>
                                <Skeleton/>
                            </Stack>
                        ) : (
                            <FormControl sx={{width: '100%'}}>
                                <Typography color="secondary">
                                    {recipient?.type !== 'customer' ?
                                        recipient?.value :
                                        recipient?.value?.first_name + ' ' + recipient?.value?.last_name
                                    }
                                </Typography>

                                {order?.address_line_1 &&
                                <Typography color="secondary">{order?.address_line_1}</Typography>
                                }
                                {order?.address_line_2 &&
                                <Typography color="secondary">{order?.address_line_2}</Typography>
                                }
                                {(order?.city || order?.state) &&
                                <Typography color="secondary">{order?.city}, {order?.state}</Typography>
                                }
                                {order?.postal_code &&
                                <Typography color="secondary">{order?.postal_code}</Typography>
                                }
                                {order?.country &&
                                <Typography color="secondary">{order?.country}</Typography>
                                }
                            </FormControl>
                        )}
                    </Stack>
                </MainCard>
            </Grid>
            }
            <Grid item xs={12}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>Thumbnail</TableCell>
                                <TableCell>Title</TableCell>
                                <TableCell>Style ID</TableCell>
                                <TableCell>Personalized details</TableCell>
                                <TableCell>Quantity</TableCell>

                                {!isForCustomQuote &&
                                    <>
                                        <TableCell>MSRP</TableCell>
                                        <TableCell>Amount</TableCell>
                                    </>
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {isLoading &&
                            [1, 2, 3].map((row) => (
                                <TableRow key={row} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell>
                                        <Skeleton />
                                    </TableCell>
                                    <TableCell>
                                        <Skeleton />
                                    </TableCell>
                                    <TableCell>
                                        <Skeleton />
                                    </TableCell>
                                    <TableCell>
                                        <Skeleton />
                                    </TableCell>

                                    {!isForCustomQuote &&
                                        <>
                                            <TableCell>
                                                <Skeleton />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton />
                                            </TableCell>
                                        </>
                                    }
                                </TableRow>
                            ))}

                            {!isLoading &&
                            order?.items?.map((item, index) => {
                                const optionsWithValues = generateSelectedOptionsWithValues(item);
                                return (
                                    <TableRow key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>
                                            <img
                                                src={item.thumbnail || getProductMainImage(item.product)}
                                                alt={'Logo'}
                                                loading="lazy"
                                                height={'100px'}
                                            />
                                        </TableCell>
                                        <TableCell>{item.product.title}</TableCell>
                                        <TableCell
                                            sx={{
                                                textAlign: 'center'
                                            }}
                                        >
                                            <Typography variant={'body1'}>
                                                {item.product.sku}
                                            </Typography>
                                            {item?.generated_item_id &&
                                            <Typography
                                                variant={'body1'}
                                                sx={{
                                                    marginTop: '16px',
                                                    color: theme.palette.text.secondary
                                                }}
                                            >
                                                <Barcode
                                                    value={item?.generated_item_id || '0'}
                                                    format={'CODE128'}
                                                    height={25}
                                                    width={1}
                                                    fontSize={14}
                                                />
                                            </Typography>
                                            }
                                        </TableCell>
                                        <TableCell>
                                            <ul>
                                                {optionsWithValues.map(option => (<li key={option.title + option.value}>{option.title}: {option.value}</li>))}
                                            </ul>
                                        </TableCell>
                                        <TableCell>{item.quantity}</TableCell>

                                        {!isForCustomQuote &&
                                            <>
                                                <TableCell>${item.product_price}</TableCell>
                                                <TableCell>
                                                    {'$' + Number(item.quantity * item.product_price).toFixed(2)}
                                                </TableCell>
                                            </>
                                        }
                                    </TableRow>
                                )})
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>

            <Grid item xs={12}>
                <Divider sx={{ borderWidth: 1 }} />
            </Grid>

            {!isForCustomQuote &&
                <Grid item xs={12}>
                    <Stack direction="row" justifyContent="right">
                        <Typography variant="subtitle1">Total:</Typography>
                        <Typography
                            variant="subtitle1"
                            sx={{
                                marginLeft: 2
                            }}
                        >
                            {isLoading &&
                            <Skeleton width={100}/>
                            }
                            {!isLoading &&
                            <>
                                ${total?.toFixed(2)}
                            </>
                            }
                        </Typography>
                    </Stack>
                </Grid>
            }
        </Grid>
    );

    return (
        <DefaultLayout>
            <BarcodeComponent value={order?.generated_order_id} setBarcodeData={setOrderIdBarcodeData}/>

            <MainCard content={false}>
                <Stack spacing={2.5}>
                    <Box sx={{ p: 2.5 }} id="print" ref={componentRef}>
                        <PrintComponent
                            header={`${hideForCustomerCopy ? 'Customer' : 'Internal'} Copy`}
                            orderNumber={order?.generated_order_id}
                        >
                            {printingContent}
                        </PrintComponent>
                    </Box>
                    <Stack direction="row" justifyContent="flex-end" spacing={2} sx={{ p: 2.5, a: { textDecoration: 'none', color: 'inherit' } }}>
                        <Button
                            variant="contained"
                            color="warning"
                            sx={{
                                width: '100px'
                            }}
                            onClick={() => setOpenPrintDialog(true)}
                        >
                            Print
                        </Button>

                        <PDFDownloadLink
                            document={
                                <ExportPDFView
                                    logo={activeStore.logo}
                                    order={order}
                                    orderIdBarcodeData={orderIdBarcodeData}
                                    barcodeDataArray={barcodeDataArray}
                                    userTimezone={currentUser.timezone}
                                />
                            }
                            fileName={`Order_${order?.generated_order_id}.pdf`}
                        >
                            <Button
                                color="primary"
                                variant="contained"
                                sx={{
                                    color: 'secondary.lighter',
                                    width: '100px'
                                }}
                            >
                                Download
                            </Button>
                        </PDFDownloadLink>
                    </Stack>
                </Stack>
            </MainCard>

            {openPrintDialog &&
            <Dialog
                open={true}
                onClose={() => setOpenPrintDialog(false)}
            >
                <Box
                    padding={'32px'}
                    textAlign={'center'}
                >
                    <Typography
                        variant={'h2'}
                        sx={{
                            marginBottom: '32px'
                        }}
                    >
                        Printing options
                    </Typography>

                    <Box
                        display={'flex'}
                        justifyContent={'space-between'}
                    >
                        <Button
                            sx={{
                                width: '120px',
                                margin: '0 16px'
                            }}
                            variant="contained"
                            size='small'
                            color={'primary'}
                            onClick={handlePrintCustomerCopyClick}
                        >
                            Customer Copy
                        </Button>

                        <Button
                            sx={{
                                width: '120px',
                                margin: '0 16px'
                            }}
                            variant="contained"
                            size='small'
                            color={'secondary'}
                            onClick={handlePrintInternalCopyClick}
                        >
                            Internal Copy
                        </Button>
                    </Box>
                </Box>
            </Dialog>
            }

        </DefaultLayout>
    )
};

export default OrderDetails;