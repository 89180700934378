import {authApi} from './apiService'

const getAllUserStores = async (userId) => authApi.get(`users/${userId}/stores`);
const updateCompanyShippingAddress = async (companyId, data) => authApi.patch(`/companies/${companyId}/shipping-address`, data);

const apiCalls = {
    getAllUserStores,
    updateCompanyShippingAddress
};

export default apiCalls;