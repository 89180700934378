import * as React from 'react';
// material-ui
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// project import
import AuthWrapper from "../../components/AuthWrapper";
import Button from "@mui/material/Button";
import OutlinedInput from "@mui/material/OutlinedInput";

// ================================|| CODE VERIFICATION ||================================ //

export default function CodeVerificationPage() {
    const [otp, setOtp] = React.useState('');

    return (
        <AuthWrapper>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Stack spacing={1}>
                        <Typography variant="h3">Enter Verification Code</Typography>
                        <Typography color="secondary">We sent it on your e-mail.</Typography>
                    </Stack>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Stack spacing={1}>
                                <OutlinedInput
                                    fullWidth
                                    value={otp}
                                    onChange={(e) => setOtp(e.target.value)}
                                    placeholder="Verification code..."
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Button disableElevation fullWidth size="large" type="submit" variant="contained">
                                Continue
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>
                                {'Did not receive the email? Check your spam filter, or '}
                                <Typography variant='string' sx={{cursor: 'pointer' }} color="primary">
                                    Resend code
                                </Typography>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </AuthWrapper>
    );
}
