import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '../../IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import Drawer from '@mui/material/Drawer';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import {AccountCircle} from "@mui/icons-material";
import DropdownMenu from "./DropdownMenu/DropdownMenu";
import Badge from "@mui/material/Badge";

import {useSelector} from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import Box from "@mui/material/Box";
import StoresSelector from "./StoresSelector";
import GridViewIcon from '@mui/icons-material/GridView';
import EventAvailableOutlined from '@mui/icons-material/EventAvailableOutlined';
import NoteAltOutlined from '@mui/icons-material/NoteAltOutlined';
import SettingsOutlined from '@mui/icons-material/SettingsOutlined';
import RequestQuoteIconOutlined from '@mui/icons-material/RequestQuoteOutlined';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {ROLE_NAMES} from "../../../utils/helpers";

const pages = [
    {
        name: 'Catalog',
        path: '/',
        Icon: GridViewIcon
    },
    {
        name: 'Order History',
        path: '/orders-history',
        Icon: EventAvailableOutlined
    },
    {
        name: 'Draft Orders',
        path: '/draft-orders',
        Icon: NoteAltOutlined
    },
    {
        name: 'Custom Quote Submissions',
        path: '/custom-quotes',
        Icon: RequestQuoteIconOutlined
    },
    {
        name: 'Settings',
        path: '/settings/users',
        Icon: SettingsOutlined
    }
];

const CartIcon = ({ itemsNumber, setIsCartWidgetOpened }) => {
    return (
        <Tooltip title="View Cart">
            <IconButton
                size="large"
                aria-label="Proceed to order creation"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => {
                    setIsCartWidgetOpened(true)
                }}
                color="inherit"
            >
                {itemsNumber > 0 &&
                    <Badge badgeContent={itemsNumber} color="primary">
                        <ShoppingCartOutlinedIcon/>
                    </Badge>
                }

                {itemsNumber === 0 &&
                    <ShoppingCartOutlinedIcon/>
                }
            </IconButton>
        </Tooltip>
    )
};

const Header = ( {setIsCartWidgetOpened} ) => {
    const cartItems = useSelector(store => store.cart.items);
    const totalQuantity = React.useMemo(() => {return cartItems.reduce((total, item) => {return total + item.quantity}, 0)}, [cartItems]);
    const currentUser = useSelector(store => store.auth.authenticatedUser);
    const activeStore = useSelector(store => store.stores.activeStore);
    const navigate = useNavigate();
    const location = useLocation();
    const [anchorElement, setAnchorElement] = React.useState(null);
    const [open, setOpen] = React.useState(false);

    const theme = useTheme();
    const isXsBreakpoint = useMediaQuery(theme.breakpoints.down('sm'));

    const handleOpenUserMenu = (event) => {
        setAnchorElement(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElement(null);
    };

    const filteredPages = pages.filter(page => {
        if (page.path === '/settings/users' && ![ROLE_NAMES.GLOBAL_ADMIN, ROLE_NAMES.COMPANY_ADMIN, ROLE_NAMES.FINANCIAL_MANAGER].includes(currentUser?.role?.name)) {
            return false;
        }

        if (page.path === '/custom-quotes' && (ROLE_NAMES.SALESPERSON === currentUser?.role?.name || !activeStore.enable_custom_quotes)) {
            return false;
        }

        return true;
    });

    return (
        <AppBar
            position="fixed"
            sx={{
                boxShadow: 0,
                bgcolor: 'transparent',
                backgroundImage: 'none',
                color: 'inherit'
            }}
        >
            <Container
                maxWidth="100%"
                disableGutters
            >
                <Toolbar
                    variant="regular"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        flexShrink: 0,
                        bgcolor: 'white',
                        minHeight: 30,
                        py: 0
                    }}
                >
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            px: 0,
                        }}
                    >
                        <Box
                            display={'flex'}
                        >
                            {activeStore?.logo &&
                                <Box
                                    sx={{
                                        cursor: 'pointer'
                                    }}
                                    component={'img'}
                                    src={activeStore.logo}
                                    width={'48px'}
                                    marginRight={'8px'}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        navigate('/');
                                    }}
                                />
                            }

                            {!isXsBreakpoint &&
                                <StoresSelector
                                    currentUserId={currentUser.id}
                                />
                            }
                        </Box>
                    </Box>

                    <Box flexGrow={0}>
                        {!isXsBreakpoint &&
                            <CartIcon
                                itemsNumber={totalQuantity}
                                setIsCartWidgetOpened={setIsCartWidgetOpened}
                            />
                        }

                        <Tooltip title="View Profile">
                            <Button
                                size="small"
                                aria-label="current user settings"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenUserMenu}
                                color='inherit'

                                sx={{
                                    maxWidth: '200px',
                                    textOverflow: 'ellipsis'
                                }}
                            >
                                <AccountCircle
                                    sx={{
                                        mx: 0.5
                                    }}
                                />
                                {`${currentUser.first_name} ${currentUser.last_name}`}
                            </Button>
                        </Tooltip>

                        <DropdownMenu
                            anchorElement={anchorElement}
                            closeHandler={handleCloseUserMenu}
                        />
                    </Box>

                </Toolbar>
                <Toolbar
                    variant="regular"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexShrink: 0,
                        bgcolor: 'white',
                        minHeight: 30,
                        py: 0,
                        border: '1px solid',
                        borderColor: 'divider',
                    }}
                >
                    <Box sx={{
                        display: { sm: 'none'}
                    }}>
                        <Button
                            variant="text"
                            aria-label="menu"
                            onClick={() => setOpen(true)}
                            sx={{ minWidth: '30px', p: '4px' }}
                            color='inherit'
                        >
                            <MenuIcon />
                        </Button>
                        <Drawer anchor="left" open={open} onClose={() => setOpen(false)}>
                            <Box
                                sx={{
                                    minWidth: '60dvw',
                                    p: 2,
                                    backgroundColor: 'background.paper',
                                    flexGrow: 1,
                                }}
                            >
                                {filteredPages.map((page) => {


                                    return (
                                        <MenuItem
                                            key={page.name}
                                            onClick={() => navigate(page.path)}
                                        >
                                            <IconButton
                                                size="small"
                                                aria-controls="menu-appbar"
                                                aria-haspopup="true"
                                                sx={{
                                                    padding: 0,
                                                    marginRight: "5px"
                                                }}
                                                color='inherit'
                                            >
                                                <page.Icon />
                                            </IconButton>
                                            {page.name}
                                        </MenuItem>
                                    )
                                })}
                            </Box>
                        </Drawer>
                    </Box>

                    <Box sx={{ display: {xs: 'none', sm:'flex'}}}>
                        {filteredPages.map((page) => {
                            return (
                                <MenuItem
                                    key={page.name}
                                    onClick={() => navigate(page.path)}
                                    sx={{
                                        py: '6px',
                                        px: '12px',
                                    }}
                                    selected={page.path.split('/')[1] === location.pathname.split('/')[1]}
                                >
                                    <IconButton
                                        size="small"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        sx={{
                                            padding: 0,
                                            marginRight: "5px"
                                        }}
                                        color='inherit'
                                    >
                                        <page.Icon />
                                    </IconButton>

                                    {page.name}
                                </MenuItem>
                            )
                        })}
                    </Box>

                    {isXsBreakpoint &&
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                flexGrow: 1
                            }}
                        >
                            <StoresSelector
                                currentUserId={currentUser.id}
                            />
                            <CartIcon
                                itemsNumber={totalQuantity}
                                setIsCartWidgetOpened={setIsCartWidgetOpened}
                            />
                        </Box>
                    }

                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default Header;