import settingsService from "../../services/settingsService";
import {
    setUsers,
    setStores,
    setProducts,
    setProductStores,
    setUserStores,
    setStoreUsers,
    setPendingStatus,
    setReadyStatus,
    setRoles,
    setFilters,
    setAvailableStores,
    updateProductPrice,
    resetAvailableStores
} from "../reducers/settings";
import { dispatch } from "../index";

export const getUsersAction = async (data) => {
    dispatch(setPendingStatus());

    const response = await settingsService.getUsersList(data);

    dispatch(setUsers(response.data.data));
    dispatch(setReadyStatus());
};

export const getStoresAction = async (params) => {
    dispatch(setPendingStatus());

    const response = await settingsService.getStoresList(params);

    dispatch(setStores(response.data.data));
    dispatch(setReadyStatus());
};

export const getProductsAction = async (params) => {
    dispatch(setPendingStatus());

    const response = await settingsService.getProductsList(params);

    dispatch(setProducts(response.data.data));
    dispatch(setReadyStatus());
};

export const getRolesAction = async () => {
    dispatch(setPendingStatus());

    const response = await settingsService.getRoles();

    dispatch(setRoles(response.data.data['roles']));
    dispatch(setReadyStatus());
};

export const getUserStoresAction = async (userId) => {
    dispatch(setPendingStatus());

    const response = await settingsService.getUserStores(userId);

    dispatch(setUserStores({
        userId,
        stores: response.data.data['stores']
    }));
    dispatch(setReadyStatus());
};

export const getStoreUsersAction = async (storeId) => {
    dispatch(setPendingStatus());

    const response = await settingsService.getStoreUsers(storeId);

    dispatch(setStoreUsers({
        storeId,
        users: response.data.data['users']
    }));
    dispatch(setReadyStatus());
};

export const getProductStoresAction = async (productId) => {
    dispatch(setPendingStatus());

    const response = await settingsService.getProductStores(productId);

    dispatch(setProductStores({
        productId,
        stores: response.data.data['stores']
    }));
    dispatch(setReadyStatus());
};

export const getAvailableStoresAction = async () => {
    dispatch(setPendingStatus());

    const response = await settingsService.getAvailableStores();

    dispatch(setAvailableStores({
        availableStores: response.data.data['availableStores']
    }));
    dispatch(setReadyStatus());
};

export const uploadLogoAction = async (data) => {
    dispatch(setPendingStatus());

    let result = null;

    try {
        result = await settingsService.uploadImageAndGetURL(data);
        dispatch(setReadyStatus());
    } catch (error) {
        result = error;
    }

    return result;
};

export const createUserAction = async (data) => {
    dispatch(setPendingStatus());

    await settingsService.createUser(data);

    dispatch(setReadyStatus());
};

export const updateUserAction = async (userId, data) => {
    dispatch(setPendingStatus());

    await settingsService.updateUser(userId, data);

    dispatch(setReadyStatus());
};

export const createStoreAction = async (data) => {
    dispatch(setPendingStatus());

    await settingsService.createStore(data);

    dispatch(resetAvailableStores());
    dispatch(setReadyStatus());
};

export const updateStoreAction = async (storeId, data) => {
    dispatch(setPendingStatus());

    await settingsService.updateStore(storeId, data);

    dispatch(resetAvailableStores());
    dispatch(setReadyStatus());
};

export const updateProductPriceAction = async (productId, price) => {
    dispatch(setPendingStatus());

    await settingsService.updateProductPrice(productId, {product_price: price});

    dispatch(updateProductPrice({productId, price}));
    dispatch(setReadyStatus());
};

export const setFiltersAction = (data) => {
    dispatch(setFilters(data));
    dispatch(setReadyStatus());
};

export const setReadyStatusAction = () => {
    dispatch(setReadyStatus());
};