import React from "react";
import SingleProductCard from "./SingleProductCard";
import Typography from "@mui/material/Typography";
import SingleProductCardSkeleton from "./SingleProductCardSkeleton";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import NoItemsComponent from "../../../components/NoItemsComponent";

const CategoryGroup = ({ name, products, isProductsLoaded, setAnchorEl, setPopoverText }) => {
    return (
        <Box
            position={'relative'}
            sx={{
                display: "flex",
                flexWrap: "wrap",
                rowGap: "16px",
                columnGap: "32px",
                padding: "10px"
            }}
            className={'category-group'}
        >
            <Divider
                textAlign="left"
                sx={{
                    display: "flat",
                    alignItems: "center",
                    width: "100%",
                    height: "40px",
                    position: "sticky",
                    top: "0px",
                    backgroundColor: "white",
                    zIndex: "100",
                    '&:before': {
                        width: '3%'
                    }
                }}
            >
                <Typography
                    variant="h4"
                    color={'primary'}
                >
                    {name}
                </Typography>
            </Divider>

            {!isProductsLoaded && [1,2,3,4].map(key => (
                <SingleProductCardSkeleton
                    key={key}
                />
            ))}

            {isProductsLoaded && products.map(product => (
                <SingleProductCard
                    key={product.id}
                    product={product}
                    setAnchorEl={setAnchorEl}
                    setPopoverText={setPopoverText}
                />
            ))}

            {isProductsLoaded && products.length === 0 &&
                <NoItemsComponent
                    caption={'No Products Found.'}
                />
            }
        </Box>
    );
};

export default CategoryGroup;