import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import {Logout, Password, HomeWorkOutlined, AccessTimeOutlined} from "@mui/icons-material";
import { logoutAction} from "../../../../store/actions/auth";
import { useNavigate } from "react-router-dom";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import MenuWrapper from "../../../MenuWrapper";
import {useSelector} from "react-redux";
import UpdateCompanyShippingAddressDialog from "./UpdateCompanyShippingAddressDialog";
import CustomizedSnackbar from "../../../CustomizedSnackbar";
import {ROLE_NAMES} from "../../../../utils/helpers";
import ChooseTimezoneDialog from "./ChooseTimezoneDialog";
import AlertComponent from "../../../AlertComponent";

const DropdownMenu = ({ anchorElement, closeHandler }) => {
    const currentUser = useSelector(store => store.auth.authenticatedUser);
    const navigate = useNavigate();
    const canUseDialog = [ROLE_NAMES.GLOBAL_ADMIN, ROLE_NAMES.COMPANY_ADMIN].includes(currentUser.role?.name);
    const [openShippingAddressDialog, setOpenShippingAddressDialog] = React.useState(false);
    const [openTimezoneDialog, setOpenTimezoneDialog] = React.useState(false);
    const [companyUpdatedContent, setCompanyUpdatedContent] = React.useState(null);

    let settings = [
        {
            name: 'Choose your timezone',
            handler: () => {
                setOpenTimezoneDialog(true);
            },
            Icon: AccessTimeOutlined
        },
        {
            Component: Divider,
            name: 'divider1'
        },
        {
            name: 'Change password',
            handler: () => {
                navigate('/change-password');
            },
            Icon: Password
        },
        {
            Component: Divider,
            name: 'divider2'
        },
        {
            name: 'Logout',
            handler: async () => {
                try {
                    await logoutAction();
                } catch (e) {
                    AlertComponent.error(e.response.data.message);
                }
            },
            Icon: Logout
        }
    ];

    if (canUseDialog) {
        settings = [
            {
                name: 'Set company shipping address',
                handler: () => {
                    setOpenShippingAddressDialog(true);
                },
                Icon: HomeWorkOutlined
            },
            {
                Component: Divider,
                name: 'divider0'
            },
            ...settings
        ]
    }

    return (
        <>
            <MenuWrapper
                anchorElement={anchorElement}
                closeHandler={closeHandler}
            >
                {settings.map(setting => {
                    if (setting.Component) {
                        return (<setting.Component key={setting.name}/>)
                    }

                    return (
                        <MenuItem key={setting.name} onClick={setting.handler}>
                            <ListItemIcon>
                                {<setting.Icon fontSize="small"/>}
                            </ListItemIcon>

                            {setting.name}
                        </MenuItem>
                    )
                })}
            </MenuWrapper>

            {canUseDialog &&
                <UpdateCompanyShippingAddressDialog
                    openDialog={openShippingAddressDialog}
                    handleClose={() => {setOpenShippingAddressDialog(false)}}
                    successHandler={(content) => {
                        setCompanyUpdatedContent(content);
                    }}
                />
            }

            {companyUpdatedContent &&
                <CustomizedSnackbar
                    content={companyUpdatedContent}
                    handleClose={() => {
                        setCompanyUpdatedContent(null)
                    }}
                />
            }

            {openTimezoneDialog &&
                <ChooseTimezoneDialog
                    openDialog={openTimezoneDialog}
                    handleClose={() => {
                        setOpenTimezoneDialog(false)
                    }}
                />
            }
        </>
    );
};

export default DropdownMenu;