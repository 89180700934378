import * as React from 'react';
import Alert from '@mui/material/Alert';

let alertControl = [];

const AlertComponent = () => {
    const [alert, setAlert] = React.useState(null);

    alertControl = [alert, setAlert];

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            setAlert(null);
        }, 4000);

        return () => clearTimeout(timeout);
    }, [alert, setAlert]);

    return (
        <>
            {alert &&
                <Alert
                    variant="filled"
                    severity={alert.type}
                    onClose={() => {setAlert(null)}}
                    sx={{
                        position: 'fixed',
                        zIndex: 10000,
                        top: 0,
                        left: 0,
                        right: 0,
                        height: '48px',
                        alignItems: 'center'
                    }}
                >
                    {alert.type.charAt(0).toUpperCase() + alert.type.slice(1)}: {alert.message}
                </Alert>
            }
        </>
    );
};

const show = (type, message) => {
    const [_, setAlert] = alertControl;

    setAlert({
        type,
        message
    });

    if (type === 'error') {
        console.error(`ERROR: ${message}`);
    }
};

AlertComponent.show = show;
AlertComponent.error = show.bind(AlertComponent, 'error');
AlertComponent.success = show.bind(AlertComponent, 'success');

export default AlertComponent;