import * as React from 'react';
import Dialog from "@mui/material/Dialog";
import {useSelector} from "react-redux";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider, MobileDatePicker} from "@mui/x-date-pickers";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import AlertComponent from "../../components/AlertComponent";
import {getAllCompanyStoresAction} from "../../store/actions/company";
import useTheme from "@mui/material/styles/useTheme";
import * as Yup from "yup";
import {Formik} from "formik";
import Grid from "@mui/material/Grid";
import FormHelperText from "@mui/material/FormHelperText";
import {exportOrdersAction} from "../../store/actions/orders";

const ExportCsvDialog = ({ setOpenExportCsvDialog }) => {
    const theme = useTheme();

    const allStores = useSelector(store => store.company.allStores);
    const currentUser = useSelector(store => store.auth.authenticatedUser);

    const orderStatuses = [
        {value: 'all', title: 'All'},
        {value: 'open', title: 'Open'},
        {value: 'closed', title: 'Closed'},
    ];

    React.useEffect(() => {
        const getAllStores = async () =>{
            if (!allStores?.length) {
                try {
                    await getAllCompanyStoresAction(currentUser.companyId)
                } catch (e) {
                    AlertComponent.error(e.response.data.message);
                }
            }
        };

        getAllStores();
    }, [allStores, currentUser]);

    const downloadCsv = (data) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', `orders_export_${dayjs().format('MM-DD-YYYY_HH-mm-ss')}.csv`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    };

    return (
        <Dialog
            open={true}
            onClose={() => setOpenExportCsvDialog(false)}
        >
            <Box
                padding={'32px'}
                maxWidth={'400px'}
            >
                <Typography
                    variant={'h4'}
                    sx={{
                        marginBottom: '32px',
                        textAlign: 'center'
                    }}
                >
                    Export Orders
                </Typography>

                <Formik
                    initialValues={{
                        startDate: null,
                        endDate: null,
                        status: 'all',
                        storeId: 0
                    }}

                    validationSchema={Yup.object().shape({
                        startDate: Yup.date().required('Start date is required'),
                        endDate: Yup.date().required('End date is required'),
                    })}

                    onSubmit={async (values) => {
                        try {
                            const localStartTime = dayjs.tz(values.startDate.format('YYYY-MM-DD HH:mm'), currentUser.timezone);
                            const localEndTime = dayjs.tz(values.endDate.format('YYYY-MM-DD HH:mm'), currentUser.timezone);
                            const utcStartTime = localStartTime.utc().format('YYYY-MM-DD HH:mm');
                            const utcEndTime = localEndTime.utc().format('YYYY-MM-DD HH:mm');

                            const response = await exportOrdersAction(
                              `start=${utcStartTime}&end=${utcEndTime}&store_id=${values.storeId === 0 ? '' : values.storeId}&status=${values.status}`
                            );

                            downloadCsv(response.data);
                            setOpenExportCsvDialog(false);
                        } catch (e) {
                            AlertComponent.error(e.response.data.message);
                        }
                    }}
                >
                    {({ errors, setFieldValue, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => {
                        return (
                            <form noValidate onSubmit={handleSubmit}>
                                <Grid
                                    container
                                    spacing={2}
                                    sx={{
                                        marginBottom: '32px'
                                    }}
                                >
                                    <Grid item xs={12}>
                                        <Stack
                                            spasing={2}
                                            direction={'row'}
                                            sx={{
                                                justifyContent: 'space-between'
                                            }}
                                        >
                                            <Box>
                                                <InputLabel htmlFor="start-date">Start Date</InputLabel>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <MobileDatePicker
                                                        sx={{
                                                            width: "130px",
                                                        }}
                                                        name={'startDate'}
                                                        id={'start-date'}
                                                        format="MM/DD/YYYY"
                                                        value={values.startDate}
                                                        onBlur={handleBlur}
                                                        onChange={value => {
                                                            setFieldValue('startDate', value);
                                                        }}
                                                        maxDate={values.endDate ? values.endDate : dayjs()}
                                                    />
                                                </LocalizationProvider>

                                                {touched.startDate && errors.startDate && (
                                                    <FormHelperText error id="helper-text-start-date">
                                                        {errors.startDate}
                                                    </FormHelperText>
                                                )}
                                            </Box>
                                            <Box>
                                                <InputLabel htmlFor="end-date">End Date</InputLabel>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <MobileDatePicker
                                                        sx={{
                                                            width: "130px",
                                                        }}
                                                        name={'endDate'}
                                                        id={'end-date'}
                                                        format="MM/DD/YYYY"
                                                        value={values.endDate}
                                                        onBlur={handleBlur}
                                                        onChange={value => {
                                                            setFieldValue('endDate', value);
                                                        }}
                                                        minDate={values.startDate ? values.startDate : null}
                                                        maxDate={dayjs().add(1, 'day')}
                                                    />
                                                </LocalizationProvider>

                                                {touched.endDate && errors.endDate && (
                                                    <FormHelperText error id="helper-text-end-date">
                                                        {errors.endDate}
                                                    </FormHelperText>
                                                )}
                                            </Box>
                                        </Stack>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <InputLabel htmlFor="store">
                                            Store
                                        </InputLabel>
                                        <Select
                                            id="store"
                                            name={'storeId'}
                                            value={values.storeId}
                                            onChange={(event) => {
                                                setFieldValue('storeId', event.target.value);
                                            }}
                                            onBlur={handleBlur}
                                            sx={{
                                                width: '100%'
                                            }}
                                        >
                                            {[{id: '0', name: 'All', status: 'active'}, ...allStores].map(singleStore => {
                                                    return (
                                                        <MenuItem
                                                            key={singleStore.id}
                                                            value={singleStore.id}
                                                            sx={{
                                                                color: singleStore.status !== 'active' ? theme.palette.text.secondary : theme.palette.text.primary,
                                                            }}
                                                        >
                                                            {singleStore.name}
                                                        </MenuItem>
                                                    )
                                                }
                                            )}
                                        </Select>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <InputLabel htmlFor="status">
                                            Order status
                                        </InputLabel>
                                        <Select
                                            id="status"
                                            name={'status'}
                                            value={values.status}
                                            onChange={(event) => {
                                                setFieldValue('status', event.target.value);
                                            }}
                                            onBlur={handleBlur}
                                            sx={{
                                                width: '100%'
                                            }}
                                        >
                                            {orderStatuses.map(singleStatus => {
                                                    return (
                                                        <MenuItem
                                                            key={singleStatus.value}
                                                            value={singleStatus.value}
                                                        >
                                                            {singleStatus.title}
                                                        </MenuItem>
                                                    )
                                                }
                                            )}
                                        </Select>
                                    </Grid>
                                </Grid>

                                <Stack spacing={2} direction={'row'}>
                                    <Button
                                        sx={{
                                            width: '50%',
                                        }}
                                        variant="outlined"
                                        color={'secondary'}
                                        onClick={() => setOpenExportCsvDialog(false)}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        type='submit'
                                        sx={{
                                            width: '50%'
                                        }}
                                        variant="contained"
                                        color={'primary'}
                                        disableElevation
                                        disabled={isSubmitting}
                                    >
                                        Export
                                    </Button>
                                </Stack>
                            </form>
                        )
                    }}
                </Formik>
            </Box>
        </Dialog>
    );
};

export default ExportCsvDialog;