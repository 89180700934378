import * as React from 'react';

import CategoryGroup from "./CategoryGroup";
import Paper from "@mui/material/Paper";
import NoItemsComponent from "../../../components/NoItemsComponent";

const GroupedProductList = ({ categoriesWithProducts, isProductsLoaded, setAnchorEl, setPopoverText }) => {
    const categories = Object.keys(categoriesWithProducts).sort();

    return (
        <>
            <Paper
                sx={{
                    '& > .category-group:last-child' : {
                        paddingBottom: "35px"
                    }
                }}
            >
                {!isProductsLoaded &&
                    <CategoryGroup
                        name={'Loading...'}
                        isProductsLoaded={false}
                    />
                }

                {isProductsLoaded && categories.map(category => (
                    <CategoryGroup
                            key={category}
                            name={category}
                            products={categoriesWithProducts[category]}
                            isProductsLoaded={true}
                            setAnchorEl={setAnchorEl}
                            setPopoverText={setPopoverText}
                    />))
                }
            </Paper>

            {isProductsLoaded && categories.length === 0 &&
                <NoItemsComponent
                    caption={'No Products Found.'}
                    verticalMargin={32}
                />
            }
        </>
    )
};

export default GroupedProductList;