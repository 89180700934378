import * as React from 'react';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import MainCard from "../../../components/MainCard";
import Skeleton from "@mui/material/Skeleton";



export default function SingleProductCardSkeleton() {
    return (
        <MainCard
            content={false}
            boxShadow
            sx={{
                '&:hover': {
                    transform: 'scale3d(1.02, 1.02, 1)',
                    transition: 'all .4s ease-in-out'
                },
                width: '400px',
                cursor: 'pointer'
            }}
        >
            <Box sx={{ width: 250, m: '0 auto', p: 2 }}>
                <Skeleton
                    variant="rounded"
                    animation="wave"
                    width={"100%"}
                    height={"200px"}
                />
            </Box>

            <Stack
                direction="row"
                alignItems="center"
                justifyContent={'space-between'}
                padding={"10px 16px"}
            >
                <Typography variant="h5" color="text.secondary">
                    <Skeleton
                        variant="rounded"
                        animation="wave"
                        width={"150px"}
                    />
                </Typography>
                <Typography variant="h5">
                    <Skeleton
                        variant="rounded"
                        animation="wave"
                        width={"100px"}
                    />
                </Typography>
            </Stack>

            <Divider />

            <CardContent sx={{ p: 2 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Stack spacing={1}>
                            <Typography
                                variant="h5"
                                sx={{ display: 'block'}}
                            >
                                <Skeleton
                                    variant="rounded"
                                    animation="wave"
                                    width={"250px"}
                                />
                            </Typography>
                            <Typography variant="h6">
                                <Skeleton
                                    height={'40px'}
                                    variant="rounded"
                                    animation="wave"
                                />
                            </Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </CardContent>
        </MainCard>
    );
}
