import * as React from 'react';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

const InformationDialog = ({ isOpen, handleClose }) => (
    <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle
            id="alert-dialog-title"
            sx={{
                textAlign: "center",
                fontWeight: "600"
            }}
        >
            Information
        </DialogTitle>
        <DialogContent>
            <DialogContentText
                id="alert-dialog-description"
                textAlign="center"
            >
                This product with the same options is already in the Cart.
                <br/>
                Please re-adjust its selected quantity according to your needs.
            </DialogContentText>
        </DialogContent>
        <DialogActions
            sx={{
                justifyContent: "center"
            }}
        >
            <Button
                onClick={handleClose}
                autoFocus
                variant='contained'
            >
                OK
            </Button>
        </DialogActions>
    </Dialog>
);

export default InformationDialog;