import * as React from 'react';
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import Autocomplete from "@mui/material/Autocomplete";

const ShippingAddressFields = ({ values, touched, errors, handleChange, handleBlur}) => {
    return (
        <>
            <Grid item xs={12}>
                <TextField
                    sx={{
                        width: '100%'
                    }}
                    required
                    error={touched.address_line_1 && Boolean(errors.address_line_1)}
                    label="Street Address 1"
                    name="address_line_1"
                    value={values.address_line_1}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Enter Street Address 1 ..."
                />
                {touched.address_line_1 && errors.address_line_1 && (
                    <FormHelperText error id="helper-text-address-line-1">
                        {errors.address_line_1}
                    </FormHelperText>
                )}
            </Grid>

            <Grid item xs={12}>
                <TextField
                    sx={{
                        width: '100%'
                    }}
                    error={touched.address_line_2 && Boolean(errors.address_line_2)}
                    label="Street Address 2"
                    name="address_line_2"
                    value={values.address_line_2}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Enter Street Address 2 ..."
                />
                {touched.address_line_2 && errors.address_line_2 && (
                    <FormHelperText error id="helper-text-address-line-2">
                        {errors.address_line_2}
                    </FormHelperText>
                )}
            </Grid>

            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                        <TextField
                            sx={{
                                width: '100%'
                            }}
                            required
                            error={touched.city && Boolean(errors.city)}
                            label="City"
                            name="city"
                            value={values.city}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Enter city..."
                        />
                        {touched.city && errors.city && (
                            <FormHelperText error id="helper-text-city">
                                {errors.city}
                            </FormHelperText>
                        )}
                    </Grid>

                    <Grid item md={6} xs={12}>
                        <TextField
                            sx={{
                                width: '100%'
                            }}
                            required
                            error={touched.state && Boolean(errors.state)}
                            label="State / Province"
                            name="state"
                            value={values.state}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Enter State / Province..."
                        />
                        {touched.state && errors.state && (
                            <FormHelperText error id="helper-text-state">
                                {errors.state}
                            </FormHelperText>
                        )}
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                        <TextField
                            sx={{
                                width: '100%'
                            }}
                            required
                            autoComplete='on'
                            error={touched.postal_code && Boolean(errors.postal_code)}
                            label="Postal Code / Zip Code"
                            name="postal_code"
                            id="postal-code"
                            value={values.postal_code}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Enter postal code..."
                        />
                        {touched.postal_code && errors.postal_code && (
                            <FormHelperText error id="helper-text-postal-code">
                                {errors.postal_code}
                            </FormHelperText>
                        )}
                    </Grid>

                    <Grid item md={6} xs={12}>
                        <Autocomplete
                            disablePortal
                            error={touched.country && Boolean(errors.country)}
                            options={[
                                'USA',
                                //'Canada',
                                //'Germany'
                            ]}
                            sx={{ width: '100%' }}
                            value={values.country}
                            onChange={(event, newValue) => {
                                const e = {
                                    target:
                                        {
                                            name: 'country',
                                            value: newValue
                                        }
                                };

                                handleChange(e);
                            }}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    label="Country"
                                    required
                                    name="country"
                                />
                            }
                        />
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
};

export default ShippingAddressFields