import * as React from 'react';
import TextField from "@mui/material/TextField";
import {Autocomplete, GoogleMap, MarkerF, useJsApiLoader} from '@react-google-maps/api';

import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";

const libraries = ['places'];

const AddressOptionComponent = ({ state, option, onChangeHandler, stateFunction }) => {
    const inputStyle = {
        boxSizing: 'border-box',
        border: '1px solid transparent',
        width: '100%',
        height: '38px',
        padding: '0 12px',
        borderRadius: '3px',
        boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
        fontSize: '14px',
        outline: 'none',
        textOverflow: 'ellipsis',
    };

    const getLatLngFromString = (addressString) => {
        const regex = /lat: (.*), lng: (.*)/;
        const match = addressString?.match(regex);

        return ({
            lat: match ? match[1] : option['defaultLat'] || 0,
            lng: match ? match[2] : option['defaultLng'] || 0,
        });
    };

    const [coordinates, setCoordinates] = React.useState(() => {
        return getLatLngFromString(state[option.name]);
    });

    const [openDialog, setOpenDialog] = React.useState(false);
    const [autocompleteWasUsed, setAutocompleteWasUsed] = React.useState(false);
    const [placeName, setPlaceName] = React.useState('');

    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
        libraries,
    });

    const mapRef = React.useRef();
    const autocompleteRef = React.useRef();

    const onLoadMap = (map) => {
        mapRef.current = map
    };

    const onLoadAutocomplete = (autocomplete) => {
        autocompleteRef.current = autocomplete
    };

    const getPlaceByCoords = (lat, lng) => {
        const geocoder = new window.google.maps.Geocoder();
        const latlng = new window.google.maps.LatLng(lat, lng);

        geocoder.geocode({ location: latlng }, (results, status) => {
            if (status === 'OK' && results[0]) {
                const place = results[0];

                setPlaceName(place.formatted_address);

                const geometry = place.geometry;

                setCoordinates({
                    lat: geometry.location.lat(),
                    lng: geometry.location.lng(),
                });
                setAutocompleteWasUsed(true);
            } else {
                setPlaceName('');
                setAutocompleteWasUsed(false);
            }
        });
    };

    React.useEffect(() => {
        if (isLoaded && !placeName) {
            getPlaceByCoords(coordinates.lat, coordinates.lng);
        }
    }, [isLoaded, coordinates, placeName]);

    const handlePlaceChanged = () => {
        const place = autocompleteRef.current.getPlace();

        if (!place || !place?.geometry) {
            return;
        }

        const geometry = place.geometry;

        // const bounds = new window.google.maps.LatLngBounds();
        //
        // if (geometry.viewport) {
        //     bounds.union(geometry.viewport);
        // } else {
        //     bounds.extend(geometry.location);
        // }

        // if (coordinates.lat !== geometry.location.lat() || coordinates.lng !== geometry.location.lng()) {
        //     mapRef.current.fitBounds(bounds);
        // }

        setCoordinates({
            lat: geometry.location.lat(),
            lng: geometry.location.lng(),
        });

        setPlaceName(place.formatted_address);
        setAutocompleteWasUsed(true);
    };

    const onMarkerDragEnd = (event) => {
        getPlaceByCoords(event.latLng.lat(), event.latLng.lng());
    };

    const onMouseClick = (event) => {
        if (event.domEvent.ctrlKey) {
            onMarkerDragEnd(event);
        }
    };

    const generateResult = () => {
        return `${placeName} (lat: ${coordinates.lat}, lng: ${coordinates.lng})`;
    };

    if (loadError) {
        console.error(loadError);
        return null;
    }

    return (
        <>
            <TextField
                sx={{
                    width: "100%",
                    '& input': {
                        cursor: "pointer"
                    }
                }}
                size={"small"}
                required={option?.required}
                readOnly={true}
                value={state[option.name] || ""}
                onClick={(e) => {
                    e.target.blur();
                    setOpenDialog(true);
                }}
                placeholder={'Click here to choose...'}
            />

            <Dialog
                open={openDialog}
                fullWidth={true}
            >
                <Box
                    display={'flex'}
                    flexWrap={'wrap-reverse'}
                    alignItems={'start'}
                >
                    <Box
                        display={'flex'}
                        flexWrap={'wrap'}
                        justifyContent={'center'}
                        width={'300px'}
                        flexGrow={1}
                        padding={2}
                    >
                        <Typography
                            variant={'h4'}
                            sx={{
                                textAlign: 'center',
                                marginBottom: '32px'
                            }}
                        >
                            Enter the Address
                        </Typography>

                        {isLoaded && (
                            <Box
                                width={'100%'}
                                display={'flex'}
                                alignItems={'stretch'}
                                flexDirection={'column'}
                                marginBottom={'16px'}
                            >
                                <Autocomplete
                                    onLoad={onLoadAutocomplete}
                                    onPlaceChanged={handlePlaceChanged}
                                    value={coordinates}
                                >
                                    <input
                                        type='text'
                                        placeholder='Search Location'
                                        style={inputStyle}
                                        value={placeName}
                                        onChange={(e) => setPlaceName(e.target.value)}
                                    />
                                </Autocomplete>
                            </Box>
                        )}

                        {!isLoaded &&
                            <Skeleton
                                animation="wave"
                                width={"100%"}
                            />
                        }

                        <Divider
                            sx={{
                                width: '100%',
                                margin: '16px 0'
                            }}
                        />

                        <Stack direction={'column'} spacing={2} sx={{textAlign: 'center'}}>
                            <Typography variant={'body1'} color={'secondary'}>
                                Coordinates (lat, lng)
                            </Typography>
                            <Typography variant={'h5'}  color={'secondary'}>
                                {coordinates.lat},  {coordinates.lng}
                            </Typography>
                        </Stack>

                        <Divider
                            sx={{
                                width: '100%',
                                margin: '16px 0'
                            }}
                        />

                        <Button
                            variant={'outlined'}
                            sx={{
                                margin: '16px',
                                width: '80px'
                            }}
                            onClick={() => setOpenDialog(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant={'contained'}
                            sx={{
                                margin: '16px',
                                width: '80px'
                            }}
                            disabled={!autocompleteWasUsed}
                            onClick={() => {
                                setOpenDialog(false);
                                onChangeHandler(option, {target: {value: generateResult()}}, stateFunction);
                            }}
                        >
                            OK
                        </Button>
                    </Box>

                    {isLoaded && (autocompleteWasUsed || option['defaultLat'] ) &&
                    <GoogleMap
                        mapContainerStyle={{
                            minWidth: "300px",
                            height: "400px",
                            flexGrow: 10000,
                            flexShrink: 10000,
                            borderLeft: '1px solid lightgrey'
                        }}
                        center={coordinates}
                        onLoad={onLoadMap}
                        onClick={onMouseClick}
                        zoom={option.zoom || 10}
                        options={{
                            disableDefaultUI: true,
                            zoomControl: false,
                            disableDoubleClickZoom: true,
                            gestureHandling: 'none',
                            mapId: option.mapId
                        }}
                    >
                        <MarkerF
                            position={coordinates}
                            draggable={true}
                            onDragEnd={onMarkerDragEnd}
                        />
                    </GoogleMap>
                    }

                    {(!isLoaded || (!autocompleteWasUsed && !option['defaultLat'])) &&
                        <img height={'400px'} src={'/map_placeholder.jpg'} alt={'map placeholder'}/>
                    }
                </Box>
            </Dialog>
        </>
    );
};

export default AddressOptionComponent;