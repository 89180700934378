import * as React from 'react';
import Typography from "@mui/material/Typography";
import {ErrorOutlineOutlined} from "@mui/icons-material";
import Box from "@mui/material/Box";

const NoItemsComponent = ({ caption, verticalMargin = 0}) => {
    return (
        <Box
            width={'100%'}
            textAlign={'center'}
            margin={`${verticalMargin}px 0`}
        >
            <ErrorOutlineOutlined
                color={'secondary'}
            />
            <Typography
                variant={'body1'}
                sx={{
                    fontSize: '16px'
                }}
            >
                {caption}
            </Typography>
        </Box>
    );
};

export default NoItemsComponent;