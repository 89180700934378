import * as  React from 'react';
import Barcode from "react-barcode";

const BarcodeComponent = ({ value = '0', setBarcodeData }) => {
    const barcodeRef = React.useRef();

    React.useEffect(() => {
        const svg = barcodeRef.current.querySelector('svg');
        if (svg) {
            const svgData = new XMLSerializer().serializeToString(svg);
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            const img = new Image();
            img.src = 'data:image/svg+xml;base64,' + btoa(svgData);

            img.onload = () => {
                canvas.width = img.width;
                canvas.height = img.height;
                ctx.drawImage(img, 0, 0);
                const imgData = canvas.toDataURL('image/png');
                setBarcodeData(imgData);
            };
        }
    }, [value, setBarcodeData]);

    return (
        <div ref={barcodeRef} style={{ position: 'absolute', visibility: 'hidden', top: 0, left: 0 }}>
            <Barcode
                value={value}
                format={'CODE128'}
                height={25}
                width={1}
                fontSize={14}
            />
        </div>
    );
};

export default BarcodeComponent;
