import * as React from 'react';
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useNavigate } from 'react-router-dom';
import {useSelector} from "react-redux";
import {setFiltersAction} from "../../store/actions/settings";
import {ROLE_NAMES} from "../../utils/helpers";

const TabsComponent = ({currentTabIndex}) => {
    const currentUser = useSelector(store => store.auth.authenticatedUser);
    const navigate = useNavigate();

    const handleChange = (event, newValue) => {
        let newPage = '/settings/products';

        switch (newValue) {
            case 0:
                newPage = '/settings/users';
                break;
            case 1:
                newPage = '/settings/stores';
                break;
            default: break;
        }

        setFiltersAction({resetFilters: true});
        navigate(newPage);
    };

return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={currentTabIndex} onChange={handleChange} aria-label="settings">
            <Tab index={0} label="User management" id='tab-0' aria-controls='tabpanel-0' />
            <Tab index={1} label="Store management" id='tab-1' aria-controls='tabpanel-1' />
            {[ROLE_NAMES.GLOBAL_ADMIN, ROLE_NAMES.COMPANY_ADMIN].includes(currentUser.role?.name) &&
                <Tab index={2} label="Product management" id='tab-2' aria-controls='tabpanel-2' />
            }
        </Tabs>
    </Box>
)
};

export default TabsComponent;