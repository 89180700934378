import * as React from "react";
import {createBrowserRouter} from "react-router-dom";
import PrivateRoute from "./privateRoute";

import LoginPage from "../pages/auth/LoginPage";
import ProductsPage from "../pages/products/ProductsPage";
import ForgotPasswordPage from "../pages/auth/ForgotPasswordPage";
import ChangePasswordPage from "../pages/user/ChangePasswordPage";
import ProductDetailsPage from "../pages/productDetails/ProductDetailsPage";
import OrdersHistoryPage from "../pages/orders/OrdersHistoryPage";
import CustomQuotesPage from "../pages/customQuotes/CustomQuotesPage";
import CustomQuoteDetailsPage from "../pages/customQuotes/CustomQuoteDetailsPage";
import DraftOrdersPage from "../pages/orders/DraftOrdersPage";
import CheckoutPage from "../pages/checkout/CheckoutPage";
import OrderDetails from "../pages/orderDetails/OrderDetails";
import ConvertDraftOrderPage from "../pages/convertDraftOrder/ConvertDraftOrderPage";
import CheckEmailPage from "../pages/auth/CheckEmailPage";
import CodeVerificationPage from "../pages/auth/CodeVerificationPage";
import UsersManagementPage from "../pages/settings/UsersManagementPage";
import StoresManagementPage from "../pages/settings/StoresManagementPage";
import AllowedForRolesRoute from "./AllowedForRolesRoute";
import RestorePassword from "../pages/Password/RestorePassword";
import ProductsManagementPage from "../pages/settings/ProductsManagementPage";
import {ROLE_NAMES} from "../utils/helpers";


const router = createBrowserRouter([
    {
        path: "/login",
        element: <LoginPage/>,
    },
    {
        path: "/forgot-password",
        element: <ForgotPasswordPage/>,
    },
    {
        path: "/restore-password",
        element: <RestorePassword/>,
    },
    {
        path: "/code-verification",
        element: <CodeVerificationPage/>,
    },
    {
        path: "/check-mail",
        element: <CheckEmailPage/>,
    },
    {
        path: "/",
        element: <PrivateRoute component={ProductsPage}/>,
    },
    {
        path: "/change-password",
        element:  <PrivateRoute component={ChangePasswordPage}/>,
    },
    {
        path: "/products/:id",
        element:  <PrivateRoute component={ProductDetailsPage}/>,
    },
    {
        path: "/settings/users",
        element:
            <AllowedForRolesRoute allowedRoles={[ROLE_NAMES.GLOBAL_ADMIN, ROLE_NAMES.COMPANY_ADMIN, ROLE_NAMES.FINANCIAL_MANAGER]}>
                <PrivateRoute component={UsersManagementPage}/>
            </AllowedForRolesRoute>,
    },
    {
        path: "/settings/stores",
        element:
            <AllowedForRolesRoute allowedRoles={[ROLE_NAMES.GLOBAL_ADMIN, ROLE_NAMES.COMPANY_ADMIN, ROLE_NAMES.FINANCIAL_MANAGER]}>
                <PrivateRoute component={StoresManagementPage}/>
            </AllowedForRolesRoute>,
    },
    {
        path: "/settings/products",
        element:
            <AllowedForRolesRoute allowedRoles={[ROLE_NAMES.GLOBAL_ADMIN, ROLE_NAMES.COMPANY_ADMIN]}>
                <PrivateRoute component={ProductsManagementPage}/>
            </AllowedForRolesRoute>,
    },
    {
        path: "/orders-history",
        element:  <PrivateRoute component={OrdersHistoryPage}/>,
    },
    {
        path: "/draft-orders",
        element:  <PrivateRoute component={DraftOrdersPage}/>,
    },
    {
        path: "/checkout",
        element:  <PrivateRoute component={CheckoutPage}/>,
    },
    {
        path: "/order-details/:id",
        element:  <PrivateRoute component={OrderDetails}/>,
    },
    {
        path: "/convert-draft-order/:id",
        element:  <PrivateRoute component={ConvertDraftOrderPage}/>,
    },
    {
        path: "/custom-quotes",
        element:
            <AllowedForRolesRoute
                allowedRoles={[
                    ROLE_NAMES.GLOBAL_ADMIN,
                    ROLE_NAMES.COMPANY_ADMIN,
                    ROLE_NAMES.FINANCIAL_MANAGER,
                    ROLE_NAMES.SALES_MANAGER,
                    ROLE_NAMES.CUSTOM_SPECIALIST,
                ]}
            >
                <PrivateRoute component={CustomQuotesPage}/>
            </AllowedForRolesRoute>,
    },
    {
        path: "/custom-quote-details/:id",
        element:
            <AllowedForRolesRoute
                allowedRoles={[
                    ROLE_NAMES.GLOBAL_ADMIN,
                    ROLE_NAMES.COMPANY_ADMIN,
                    ROLE_NAMES.FINANCIAL_MANAGER,
                    ROLE_NAMES.SALES_MANAGER,
                    ROLE_NAMES.CUSTOM_SPECIALIST,
                ]}
            >
                <PrivateRoute component={CustomQuoteDetailsPage}/>
            </AllowedForRolesRoute>,
    },
]);

export default router;