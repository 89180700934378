import {authApi} from './apiService'

const getCartItems = async (activeStoreId, userId) => authApi.get(`/cart-items/store/${activeStoreId}/user/${userId}`);
const createCartItem = async (data) => authApi.post(`cart-items`, data);
const updateCartItem = async (cartItemId, data) => authApi.patch(`cart-items/${cartItemId}`, data);
const deleteCartItem = async (cartItemId) => authApi.delete(`cart-items/${cartItemId}`);

const apiCalls = {
    getCartItems,
    createCartItem,
    updateCartItem,
    deleteCartItem
};

export default apiCalls;