import * as React from "react";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import LinearProgress from "@mui/material/LinearProgress";
import {useTheme} from "@mui/material";

const CancellationDialog = ({ mainText, bottomText, cancelButtonText, createOrderHandler, closeDialogHandler }) => {
    const theme = useTheme();
    const [secondsLeft, setSecondsLeft] = React.useState(5);
    const timerRef = React.useRef(null);

    const stopTimer = React.useCallback(() => {
        if (timerRef.current) {
            clearInterval(timerRef.current);
        }
    }, []);

    React.useEffect(() => {
        timerRef.current = setInterval(() => {
            setSecondsLeft((prev) => {
                if (prev < 1) {
                    stopTimer();
                    createOrderHandler();
                    return 0;
                }
                return prev - 1;
            });
        }, 1000);

        return stopTimer;
    }, [createOrderHandler, stopTimer]);

    const progressValue = (100 / 5) * (5 - secondsLeft);

    return (
        <Dialog
            open={true}
        >
            <Box
                padding={'32px'}
                textAlign={'center'}
                width={'400px'}
            >
                <Box
                    sx={{
                        margin: '16px auto 10px auto',
                        '& img': {
                            width: '80px'
                        }
                    }}
                >
                    <img src={'/warning.png'} alt={'warning'}/>
                </Box>

                <Typography
                    variant={'h4'}
                    sx={{
                        marginBottom: '16px'
                    }}
                >
                    {mainText}
                </Typography>

                <LinearProgress variant="determinate" value={progressValue} />

                <Typography
                    variant={'body1'}
                    sx={{
                        marginTop: '16px',
                        color: theme.palette.text.secondary
                    }}
                >
                    {bottomText}
                </Typography>

                <Button
                    variant="outlined"
                    sx={{
                        margin: '16px auto 0 auto',
                        width: '180px'
                    }}
                    onClick={closeDialogHandler}
                >
                    {cancelButtonText}
                </Button>
            </Box>
        </Dialog>
    );
};

export default CancellationDialog;