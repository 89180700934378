import * as React from 'react';
import * as Yup from "yup";
import {setRestoredPasswordAction} from "../../store/actions/auth";
import {Typography} from "@mui/material";
import {Formik} from "formik";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormHelperText from "@mui/material/FormHelperText";
import Button from "@mui/material/Button";
import {useState} from "react";
import {useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import AlertComponent from "../../components/AlertComponent";

const RestorePasswordForm = ({ token }) => {
    const navigate = useNavigate();

    const initialValues = {
        email: '',
        password: '',
        password_confirmation: ''
    };

    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        if (!successMessage) {
            return;
        }

        const timeout = setTimeout(() => {
            navigate('/');
        }, 3000);

        return () => clearTimeout(timeout);
    }, [successMessage, navigate]);

    const passwordValidationMessage = 'Password does not meet the requirements. Please ensure it is at least 8 characters long and includes uppercase and lowercase letters, a number, and a special symbol (@, $, !, %, *, ?, &, #).';

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
                email: Yup.string().email('Invalid email address').required('Email is required'),
                password: Yup.string()
                    .max(255)
                    .min(8, passwordValidationMessage)
                    .matches(/[a-z]/, passwordValidationMessage)
                    .matches(/[A-Z]/, passwordValidationMessage)
                    .matches(/[0-9]/, passwordValidationMessage)
                    .matches(/[@$!%*?&#]/, passwordValidationMessage)
                    .required('New password is required'),
                password_confirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('Password confirmation is required'),
            })}

            onSubmit={async (values) => {
                try {
                    setSuccessMessage('');

                    await setRestoredPasswordAction({
                        ...values,
                        token: token
                    });

                    setSuccessMessage('Password was set successfully. Redirect in 3 seconds...')
                } catch (e) {
                    AlertComponent.error(e.response.data.message);
                }
            }}
        >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => {
                return (
                    <form noValidate onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Stack direction="row" justifyContent="space-between" alignItems="baseline" sx={{ mb: { xs: -0.5, sm: 0.5 } }}>
                                    <Typography variant="h3">Enter Your New Password</Typography>
                                    <Typography
                                        component={Link}
                                        to={'/login'}
                                        variant="body1"
                                        sx={{ textDecoration: 'none' }}
                                        color="primary"
                                    >
                                        Sign in
                                    </Typography>
                                </Stack>
                            </Grid>

                            <Grid item xs={12}>
                                <Stack spacing={1}>
                                    <InputLabel htmlFor="email">Email</InputLabel>
                                    <OutlinedInput
                                        fullWidth
                                        error={touched.email && Boolean(errors.email)}
                                        id="email"
                                        type={'text'}
                                        value={values.email}
                                        name="email"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        placeholder="Enter Your Email..."
                                    />
                                </Stack>
                                {touched.email && errors.email && (
                                    <FormHelperText error id="helper-text-email">
                                        {errors.email}
                                    </FormHelperText>
                                )}
                            </Grid>

                            <Grid item xs={12}>
                                <Stack spacing={1}>
                                    <InputLabel htmlFor="password">Password</InputLabel>
                                    <OutlinedInput
                                        fullWidth
                                        error={touched.password && Boolean(errors.password)}
                                        id="password"
                                        type={'password'}
                                        value={values.password}
                                        name="password"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        placeholder="Enter New Password..."
                                    />
                                </Stack>
                                {touched.password && errors.password && (
                                    <FormHelperText error id="helper-text-password">
                                        {errors.password}
                                    </FormHelperText>
                                )}
                            </Grid>

                            <Grid item xs={12}>
                                <Stack spacing={1}>
                                    <InputLabel htmlFor="password-confirmation">Password Confirmation</InputLabel>
                                    <OutlinedInput
                                        fullWidth
                                        error={Boolean(touched.password_confirmation && errors.password_confirmation)}
                                        id="password-confirmation"
                                        type={'password'}
                                        value={values.password_confirmation}
                                        name="password_confirmation"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        placeholder="Enter Password Confirmation..."
                                    />
                                </Stack>
                                {touched.password_confirmation && errors.password_confirmation && (
                                    <FormHelperText error id="helper-text-password-confirmation">
                                        {errors.password_confirmation}
                                    </FormHelperText>
                                )}
                            </Grid>

                            {successMessage &&
                            <Grid item xs={12}>
                                <Typography color={'success.dark'} variant={'body1'}>
                                    {successMessage}
                                </Typography>
                            </Grid>
                            }

                            <Grid item xs={12}>
                                <Button disableElevation disabled={isSubmitting} fullWidth size="large" type="submit" variant="contained" color="primary">
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )
            }}
        </Formik>
    )
};

export default RestorePasswordForm;