import * as React from 'react';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from "@mui/material/DialogContent";

const MediaPreviewDialog = ({ currentMedia, handleClose }) => {
    let dialogContent;

    if (currentMedia.previewComponent && currentMedia.type !== 'video') {
        dialogContent = currentMedia.previewComponent;
    } else {
        dialogContent = currentMedia?.type === 'image' ? (
            <img
                src={currentMedia?.url}
                alt='Large preview'
                style={{width: '100%', maxHeight: 'calc(100vh - 150px)'}}
            />
        ) : (
            <video
                src={currentMedia?.url}
                controls
                autoPlay
                style={{width: '100%', maxHeight: 'calc(100vh - 150px)'}}
            />
        )
    }

    return (
        <Dialog
            open={true}
            onClose={handleClose}
            maxWidth={'lg'}
        >
            <DialogTitle
                id="customized-dialog-title"
                sx={{
                    fontWeight: 600
                }}
            >
                Preview {currentMedia.original_name ? `- ${currentMedia.original_name}` : ''}
            </DialogTitle>

            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>

            <DialogContent
                sx={{
                    pt: 0
                }}
            >
                {dialogContent}
            </DialogContent>
        </Dialog>
    );
};

export default MediaPreviewDialog;