import {publicApi, authApi} from './apiService'

const login = async (data) => publicApi.post('auth/login', data);
const logout = async () => authApi.post('auth/logout');
const getAuthenticatedUser = async () => authApi.get('users/me');
const checkPasswordToken = async (token) => authApi.get(`password/check/${token}`);
const restorePasswordRequest = async (data) => publicApi.post('auth/forgot-password', data);
const setRestoredPassword = async (data) => publicApi.post('password/reset', data);

const apiCalls = {
    login,
    logout,
    getAuthenticatedUser,
    checkPasswordToken,
    restorePasswordRequest,
    setRestoredPassword
};

export default apiCalls;
