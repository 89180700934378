import * as React from 'react';

import CategoryGroup from "./CategoryGroup";
import Paper from "@mui/material/Paper";

const StandardProductsList = ({ products, isProductsLoaded, setAnchorEl, setPopoverText }) => {
    return (
        <Paper
            sx={{
                '& > .category-group:last-child' : {
                    paddingBottom: "35px"
                }
            }}
        >
            <CategoryGroup
                name={'Products list'}
                products={products}
                isProductsLoaded={isProductsLoaded}
                setAnchorEl={setAnchorEl}
                setPopoverText={setPopoverText}
            />
        </Paper>
    )
};

export default StandardProductsList;