import * as React from 'react';
import {Formik} from "formik";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import {useSelector} from "react-redux";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import timezones from 'timezones-list';

import useTheme from "@mui/material/styles/useTheme";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {updateUserAction} from "../../../../store/actions/settings";
import {getAuthenticatedUserAction} from "../../../../store/actions/auth";
import AlertComponent from "../../../AlertComponent";

const ChooseTimezoneDialog = ({ openDialog, handleClose }) => {
    const currentUser = useSelector(store => store.auth.authenticatedUser);
    const theme = useTheme();

    const initialValues = {
        timezone: currentUser.timezone,
    };

    return (
        <Dialog
            open={openDialog}
            onClose={handleClose}
        >
            <DialogContent
                sx={{
                    textAlign: 'center',
                    padding: '48px',
                    width: '470px'
                }}
            >
                <Typography
                    variant={'h4'}
                    sx={{
                        marginBottom: '16px'
                    }}
                >
                    Choose Your Timezone
                </Typography>
                <Typography
                    variant={'body1'}
                    sx={{
                        margin: '0 auto 32px auto',
                        maxWidth: '320px',
                        textAlign: 'center',
                        color: theme.palette.text.secondary
                    }}
                >
                    Please select your timezone from the dropdown below. This will ensure that all dates and times are shown correctly for your location.
                </Typography>

                <Formik
                    initialValues={initialValues}
                    onSubmit={async (values) => {
                        try {
                            await updateUserAction(currentUser.id,
                                {
                                    ...currentUser,
                                    timezone: values.timezone
                                });

                            await getAuthenticatedUserAction();

                            handleClose();
                        } catch (e) {
                            AlertComponent.error(e.response.data.message);
                        }
                    }}
                >
                    {({ errors, setFieldValue, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <FormControl
                                            sx={{
                                                width: '100%'
                                            }}
                                        >
                                            <InputLabel htmlFor="timezone">Timezone</InputLabel>
                                            <Select
                                                sx={{
                                                    textAlign: 'left'
                                                }}
                                                value={values.timezone}
                                                name="timezone"
                                                id="timezone"
                                                onChange={(event) => {
                                                    setFieldValue('timezone', event.target.value);
                                                }}
                                            >
                                                {timezones?.map((timezone) => (
                                                    <MenuItem key={timezone.label} value={timezone.tzCode}>
                                                        {timezone.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Button
                                            type={'submit'}
                                            variant={"contained"}
                                            sx={{
                                                width: '100%'
                                            }}
                                        >
                                            Save Timezone
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        );
                    }}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

export default ChooseTimezoneDialog;