import { createSlice } from '@reduxjs/toolkit'
import {STATE_STATUSES} from "../../utils/helpers";

const filtersInitialState = {
    pagination: {
        page: 1,
        perPage: 25,
        total: 0,
        totalWasUpdated: false
    },

    sorting: [{id: 'created_at', desc: true}],
    userStatus: null,
    storeStatus: null,
    productStatus: null,
    globalFilter: null,
    initialState: true
};

const initialState = {
    users: [],
    quantities: {
        active: 0,
        disabled: 0,
        archived: 0,
        discontinued: 0,
    },
    availableStores: [],
    stores: [],
    products: [],
    roles: [],
    filters: {...filtersInitialState},
    status: STATE_STATUSES.INIT,
};

export const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        setPendingStatus: (state) => {
            state.status = STATE_STATUSES.PENDING;
        },

        setReadyStatus: (state) => {
            state.status = STATE_STATUSES.READY;
        },

        setUsers: (state, action) => {
            state.users = action.payload['users'].data;

            state.quantities = action.payload['users_quantities'].reduce((acc, curr) => {
                acc[curr.status] = curr.quantity;
                return acc;
            }, {active: 0, disabled: 0, archived: 0});

            state.filters.pagination.totalWasUpdated =
                state.filters.pagination.total !== action.payload['users'].total;
            state.filters.pagination.total = action.payload['users'].total;
        },

        setStores: (state, action) => {
            state.stores = action.payload['stores'].data;

            state.quantities = action.payload['stores_quantities'].reduce((acc, curr) => {
                acc[curr.status] = curr.quantity;
                return acc;
            }, {active: 0, disabled: 0, archived: 0});

            state.filters.pagination.totalWasUpdated =
                state.filters.pagination.total !== action.payload['stores'].total;
            state.filters.pagination.total = action.payload['stores'].total;
        },

        setProducts: (state, action) => {
            state.products = action.payload['products'].data;

            state.quantities = action.payload['products_quantities'].reduce((acc, curr) => {
                acc[curr.status] = curr.quantity;
                return acc;
            }, {active: 0, disabled: 0, archived: 0, discontinued: 0});

            state.filters.pagination.totalWasUpdated =
                state.filters.pagination.total !== action.payload['products'].total;
            state.filters.pagination.total = action.payload['products'].total;
        },

        setRoles: (state, action) => {
            state.roles = action.payload;
        },

        setUserStores: (state, action) => {
            const users = state.users.map(item => {
                if (item.id === action.payload.userId) {
                    return {
                        ...item,
                        stores: action.payload.stores
                    }
                }
                return item;
            });

            state.users = [...users];
        },

        setStoreUsers: (state, action) => {
            const stores = state.stores.map(item => {
                if (item.id === action.payload.storeId) {
                    return {
                        ...item,
                        users: action.payload.users
                    }
                }
                return item;
            });

            state.stores = [...stores];
        },

        setProductStores: (state, action) => {
            const products = state.products.map(item => {
                if (item.id === action.payload.productId) {
                    return {
                        ...item,
                        stores: action.payload.stores
                    }
                }
                return item;
            });

            state.products = [...products];
        },

        setFilters: (state, action) => {
            if (action.payload.resetFilters) {
                state.filters = {...filtersInitialState};
            } else {
                state.filters = {
                    ...action.payload,
                    pagination: {
                        ...action.payload.pagination,
                        totalWasUpdated: false
                    }
                }
            }
        },

        setAvailableStores: (state, action) => {
            state.availableStores = action.payload.availableStores;
        },

        resetAvailableStores: (state) => {
            state.availableStores = [];
        },

        updateProductPrice: (state, action) => {
            state.products = state.products.map(item =>
                item.id !== action.payload.productId ? item : ({...item, msrp: action.payload.price})
            );
        },
    },
});

export const {
    setPendingStatus,
    setReadyStatus,
    setUsers,
    setStores,
    setProducts,
    setProductStores,
    setRoles,
    setUserStores,
    setStoreUsers,
    setFilters,
    setAvailableStores,
    resetAvailableStores,
    updateProductPrice
} = settingsSlice.actions;

export default settingsSlice.reducer;