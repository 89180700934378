// material-ui
import { styled } from '@mui/material/styles';

const ScrollX = styled('div')({
    width: '100%',
    overflowX: 'auto',
    display: 'block'
});

export default ScrollX;
