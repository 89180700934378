import * as React from 'react';
import {Link, useNavigate} from 'react-router-dom';

// material-ui
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import OutlinedInput from '@mui/material/OutlinedInput';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';
import {restorePasswordAction} from "../../store/actions/auth";
import AuthWrapper from "../../components/AuthWrapper";

import AlertComponent from "../../components/AlertComponent";

export default function ForgotPasswordPage() {
    const navigate = useNavigate();
    const [submitted, setSubmitted] = React.useState(false);

    React.useEffect(() => {
        if (submitted) {
            navigate('/check-mail', {replace: true});
        }
    }, [submitted, navigate]);

    return (
        <AuthWrapper>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Stack direction="row" justifyContent="space-between" alignItems="baseline" sx={{ mb: { xs: -0.5, sm: 0.5 } }}>
                        <Typography variant="h3">Forgot Your Password?</Typography>
                        <Typography
                            component={Link}
                            to={'/login'}
                            variant="body1"
                            sx={{ textDecoration: 'none' }}
                            color="primary"
                        >
                            Sign in
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Formik
                        initialValues={{
                            email: '',
                            submit: null
                        }}
                        validationSchema={Yup.object().shape({
                            email: Yup.string().email('Must be a valid email').max(255).required('Email is required')
                        })}

                        onSubmit={async (values) => {
                            try {
                                await restorePasswordAction({
                                    email: values.email
                                });

                                setSubmitted(true);
                            } catch (e) {
                                AlertComponent.error(e.response.data.message);
                            }
                        }}
                    >
                        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                            <form noValidate onSubmit={handleSubmit}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Stack spacing={2}>
                                            <Typography
                                                color={'secondary'}
                                                variable={'body1'}
                                            >
                                                Enter your email address below, and we'll send you a link to reset your password.
                                            </Typography>
                                            <OutlinedInput
                                                fullWidth
                                                error={Boolean(touched.email && errors.email)}
                                                id="email-forgot"
                                                type="email"
                                                value={values.email}
                                                name="email"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                placeholder="Enter your email"
                                                inputProps={{}}
                                            />
                                        </Stack>
                                        {touched.email && errors.email && (
                                            <FormHelperText error id="helper-text-email-forgot">
                                                {errors.email}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                    {errors.submit && (
                                        <Grid item xs={12}>
                                            <FormHelperText error>{errors.submit}</FormHelperText>
                                        </Grid>
                                    )}

                                    <Grid item xs={12}>
                                        <Button disableElevation disabled={isSubmitting} fullWidth size="large" type="submit" variant="contained" color="primary">
                                            Submit
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Formik>
                </Grid>
            </Grid>
        </AuthWrapper>
    );
}
