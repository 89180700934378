import * as React from 'react';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

const Section = ({ title, description, children }) => {
    return (
        <Box
            sx={{
                marginBottom: '20px'
            }}
        >
            <Typography
                variant={'h6'}
                sx={{
                    fontWeight: 600
                }}
            >
                {title}
            </Typography>

            <Box
                sx={{
                    marginBottom: '10px'
                }}
            >
                <Typography
                    variant={'body1'}
                >
                    {description}
                </Typography>
            </Box>

            <Paper
                variant={'outlined'}
                sx={{
                    padding: '16px',
                }}
            >
                {children}
            </Paper>
        </Box>
    );
};

export default Section;