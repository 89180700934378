import * as React from 'react';
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import {ORDER_STATUSES} from "../../utils/helpers";
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import {useSelector} from "react-redux";

const OrderActions = ({order, setSelectedOrder, setOpenConfirmationDialog, setOpenWarningDialog, setOpenEditDraftDialog,
                          setOpenConvertDraftDialog}) => {
    const theme = useTheme();
    const { activeStore } = useSelector(store => store.stores);
    const cartItems = useSelector(store => store.cart.items);
    const navigate = useNavigate();

    const handleEditDraftClick = async () => {
        if (activeStore.id !== order.store_id) {
            setOpenWarningDialog(true);
            return;
        }

        if ((cartItems || []).length > 0) {
            setOpenEditDraftDialog(true);
        }
        else {
            setOpenConvertDraftDialog(true);
        }
    };

    const buttonStyles = {
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.secondary.dark,
        '&:hover': {
            color: 'white'
        }
    };

    return (
        <Stack spacing={2} direction="row">
            {!order?.is_draft && order?.status !== ORDER_STATUSES.CANCELED &&
            <Button
                variant="contained"
                size={'small'}
                sx={buttonStyles}
                onClick={() => {
                    navigate(`/order-details/${order?.id}`);
                }}
            >
                View
            </Button>
            }

            {!!order?.is_draft && order?.status !== ORDER_STATUSES.CANCELED &&
            <Button
                variant="contained"
                size='small'
                sx={buttonStyles}
                onClick={async () => {
                    setSelectedOrder(order);
                    await handleEditDraftClick();
                }}
            >
                Edit
            </Button>
            }

            {order?.status === ORDER_STATUSES.SUBMITTED &&
            <Button
                variant="contained"
                size='small'
                sx={buttonStyles}
                onClick={() => {
                    setSelectedOrder(order);
                    setOpenConfirmationDialog(true);
                }}
            >
                Cancel
            </Button>
            }

        </Stack>
    )
};

export default OrderActions;