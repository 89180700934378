import * as React from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const TitleWithProductId = ({ product }) => (
    <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"baseline"}
    >
        <Typography
            component="div"
            variant="h3"
            sx={{
                flexShrink: '2'
            }}

        >
            {product.title}
        </Typography>

        <Box
            sx={{
                opacity: "0.5",
                flexShrink: '1',
                '& span': {
                    fontWeight: "600",
                    fontSize: "0.9em",
                    marginRight: "5px"
                }
            }}
        >
            <span>Product ID:</span> {product.sku}
        </Box>
    </Box>
);

export default TitleWithProductId;