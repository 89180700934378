import * as React from 'react';
import PropTypes from 'prop-types';

// material-ui
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import AuthCard from "./AuthCard";

// project import


// ==============================|| AUTHENTICATION - WRAPPER ||============================== //

export default function AuthWrapper({ isForDefaultLayout = false, children }) {
    return (
        <Box sx={{ minHeight: !isForDefaultLayout ? '100vh' : 'calc(100vh - 110px)'}}>
            <Grid
                container
                direction="row"
                sx={{
                    minHeight: !isForDefaultLayout ? '100vh' : 'calc(100vh - 110px)',
                    alignItems: 'center'
                }}
            >
                <Grid item xs={12}>
                    <Grid
                        item
                        xs={12}
                        container
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item>
                            <AuthCard>{children}</AuthCard>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}

AuthWrapper.propTypes = { children: PropTypes.node };
