import * as React from 'react';
// material-ui
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            minWidth: '200px',
            width: 'auto',
        },
    },
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
    },
};

const SelectStoresDropdown = ({stores, selectedStoreIds, storeClickHandler, size = 'medium' }) => {
    const [selectedStores, setSelectedStores] = React.useState([]);

    React.useEffect(() => {
        setSelectedStores(stores.filter(store => selectedStoreIds.includes(store.id)).map(item => item.name));
    }, [stores, selectedStoreIds]);

    return (
        <FormControl sx={{ width: 200 }}>
            <Select
                sx={{
                    '& > div': {
                        paddingTop: '6px',
                        paddingBottom: '6px',
                    }
                }}
                multiple
                displayEmpty
                value={selectedStores}
                name={'selectStores'}
                id={'select-stores'}
                renderValue={(selected) => {
                    if (selectedStoreIds?.length > 0) {
                        return (
                            <Typography variant="subtitle2">
                                {selected.join(', ')}
                            </Typography>
                        );
                    }
                    return <Typography variant="subtitle2">Filter by stores</Typography>;
                }}
                MenuProps={MenuProps}
                size={size}
            >
                {stores.map(store =>
                    (
                        <MenuItem
                            key={store.id}
                            value={store.name}
                            onClick={() => storeClickHandler(store.id)}
                        >
                            <Checkbox
                                checked={!!selectedStoreIds.find(id => store.id === id)}
                                id={`check-box-${store.id}`}
                                color="success"
                            />
                            <ListItemText primary={store.name} />
                        </MenuItem>
                    )
                )}
            </Select>
        </FormControl>
    );
};

export default SelectStoresDropdown;