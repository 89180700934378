import * as React from 'react';
import {getJsonObject} from "../../../../utils/helpers";
import {Formik} from "formik";
import * as Yup from "yup";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {useSelector} from "react-redux";
import CloseIcon from '@mui/icons-material/Close';
import ShippingAddressFields from "../../../ShippingAddressFields";
import Grid from "@mui/material/Grid";
import {updateCompanyShippingAddressAction} from "../../../../store/actions/stores";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import AlertComponent from "../../../AlertComponent";

const UpdateCompanyShippingAddressDialog = ({ openDialog, handleClose, successHandler }) => {
    const activeStore = useSelector(store => store.stores.activeStore);
    const formRef = React.useRef(null);
    const initialValues = {
        shipping_name: activeStore?.company?.shipping_name,
        ...getJsonObject(activeStore?.company?.shipping_address)
    };

    return (
        <Dialog
            aria-labelledby="customized-dialog-title"
            open={openDialog}
        >
            <DialogTitle sx={{ m: 0, p: 2, fontWeight: 700 }} id="customized-dialog-title">
                Edit Company Shipping Address
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                <Formik
                    initialValues={initialValues}
                    validationSchema={Yup.object().shape({
                        shipping_name: Yup.string().max(255).required('Shipping company name is required'),
                        address_line_1: Yup.string().max(255).required('Street Address 1 is required'),
                        city: Yup.string().max(255).required('City is required'),
                        state: Yup.string().max(255).required('State is required'),
                        postal_code: Yup.string()
                            .matches(/^\d{5}$/, 'Postal code must be exactly 5 digits')
                            .required('Postal code is required'),
                    })}

                    onSubmit={async (values) => {
                        try {
                            const data = {
                                shipping_name: values.shipping_name,
                                shipping_address: JSON.stringify({
                                    address_line_1: values.address_line_1,
                                    address_line_2: values.address_line_2,
                                    city: values.city,
                                    state: values.state,
                                    postal_code: values.postal_code,
                                    country: values.country
                                }),
                            };

                            await updateCompanyShippingAddressAction(activeStore?.company_id, data);

                            successHandler('Company Shipping Address Successfully Updated.');
                            handleClose();
                        } catch (e) {
                            AlertComponent.error(e.response.data.message);
                        }
                    }}
                >
                    {({ errors, setFieldValue, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => {
                        return (
                            <form noValidate onSubmit={handleSubmit} ref={formRef}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            sx={{
                                                width: '100%'
                                            }}
                                            required
                                            error={touched.shipping_name && Boolean(errors.shipping_name)}
                                            label="Company Name (Shipping purposes)"
                                            name="shipping_name"
                                            value={values.shipping_name}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder="Enter company shipping name ..."
                                        />
                                        {touched.shipping_name && errors.shipping_name && (
                                            <FormHelperText error id="helper-text-shipping-name">
                                                {errors.shipping_name}
                                            </FormHelperText>
                                        )}
                                    </Grid>

                                    <ShippingAddressFields
                                        values={values}
                                        errors={errors}
                                        touched={touched}
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                    />
                                </Grid>
                            </form>
                        );
                    }}
                </Formik>
            </DialogContent>

            <DialogActions
                sx={{
                    padding: '16px'
                }}
            >
                <Button
                    color={'error'}
                    onClick={handleClose}
                >
                    Cancel
                </Button>
                <Button
                    autoFocus
                    type="submit"
                    form="formik-form"
                    variant={"contained"}
                    onClick={
                        () => formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
                    }
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UpdateCompanyShippingAddressDialog;