import {authApi} from './apiService'

const getCheckoutSettings = async (activeStoreId, userId) => authApi.get(`/checkout-settings/store/${activeStoreId}/user/${userId}`);
const setCheckoutSettings = async (activeStoreId, userId, data) => authApi.post(`checkout-settings/store/${activeStoreId}/user/${userId}`, data);

const apiCalls = {
    getCheckoutSettings,
    setCheckoutSettings
};

export default apiCalls;