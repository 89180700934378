import * as React from 'react';
import Snackbar from "@mui/material/Snackbar";
import Slide from "@mui/material/Slide";
import Alert from "@mui/material/Alert";

const SlideTransition = React.forwardRef((props, ref) =>
    (
        <Slide {...props} direction="down" ref={ref}/>
    )
);


const InfoMessageSnackbar = ({ message, type = 'error', onCloseHandler }) => {
    return (
        <Snackbar
            open={Boolean(message)}
            anchorOrigin={{ vertical: 'top', horizontal: 'center'}}
            TransitionComponent={SlideTransition}
            autoHideDuration={3000}
            onClose={onCloseHandler}
        >
            <Alert
                onClose={onCloseHandler}
                severity={type}
                variant="filled"
                sx={{
                    width: '100%',
                    fontSize: '16px'
                }}
            >
                {message}
            </Alert>
        </Snackbar>
    );
};

export default InfoMessageSnackbar;