import authService from "../../services/authService";
import {setActiveStore} from "../reducers/stores";
import {
    setPendingStatus,
    setAuthenticatedUser,
    forgetAuthenticatedUser,
    setReadyStatus,
    setErrorStatus
} from "../reducers/auth";

import { dispatch } from "../index";

export const loginAction = async ({ email, password, remember = false }) => {
    dispatch(setPendingStatus());

    const response = await authService.login({email, password, remember});
    localStorage.setItem('auth_token', response.data.data.token);

    dispatch(setAuthenticatedUser(response.data.data.user));
    dispatch(setActiveStore(response.data.data.activeStore));
    dispatch(setReadyStatus());
};

export const restorePasswordAction = async ({email}) => {
    dispatch(setPendingStatus());

    await authService.restorePasswordRequest({email});

    dispatch(setReadyStatus());
};

export const setRestoredPasswordAction = async (data) => {
    dispatch(setPendingStatus());

    await authService.setRestoredPassword(data);

    dispatch(setReadyStatus());
};

export const getAuthenticatedUserAction = async () => {
    dispatch(setPendingStatus());

    try {
        const response = await authService.getAuthenticatedUser();

        dispatch(setAuthenticatedUser(response.data.data.user));
        dispatch(setActiveStore(response.data.data.activeStore));
        dispatch(setReadyStatus());
    } catch (e) {
        dispatch(setErrorStatus());
        throw (e);
    }
};

export const logoutAction = async () => {
    dispatch(setPendingStatus());

    await authService.logout();
    localStorage.removeItem('auth_token');

    dispatch(forgetAuthenticatedUser());
    dispatch(setReadyStatus());
};

export const checkPasswordTokenAction = async (token) => {
    let result = true;

    try {
        result = (await authService.checkPasswordToken(token)).data.valid;
    } catch (error) {
        result = error.response.data.valid;
        console.error(error);
    }

    return result;
};