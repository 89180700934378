import * as React from 'react';

import {Link as RouterLink, useSearchParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {
    setFiltersAction,
    getCustomQuotesAction, storeCustomQuoteAction,
} from "../../store/actions/customQuotes";

import CustomQuotesTable from "./CustomQuotesTable";

import {
    prepareUrlParams,
    parseSortingParam, getCustomQuoteBackgroundColor,
} from "../../utils/helpers";

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import AlertComponent from "../../components/AlertComponent";
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import Button from "@mui/material/Button";
import Link from '@mui/material/Link';
import AddCustomQuoteDialog from "./AddCustomQuoteDialog";
import OrderCreatedDialog from "../checkout/components/OrderCreatedDialog";
import Box from "@mui/material/Box";

dayjs.extend(utc);
dayjs.extend(timezone);

const getCustomQuoteColumns = (navigate, buttonStyles, userTimezone) => {
    return [
        {
            header: 'Date',
            accessorKey: 'created_at',
            cell: (cell) => {
                const dateValue = dayjs.utc(cell.row.original.created_at).tz(userTimezone);
                return dateValue.format('MMMM D, YYYY h:mm A');
            },
            meta: {
                sx: {
                    width: '220px'
                }
            }
        },
        {
            header: 'Submission ID',
            accessorKey: 'generated_id',
        },
        {
            header: 'Submitted By',
            accessorKey: 'submitted_by'
        },
        {
            header: 'Store',
            accessorKey: 'store_name'
        },
        {
            header: 'Reference #',
            accessorKey: 'reference_number'
        },
        {
            header: 'Submission status',
            accessorKey: 'status',
            cell: (cell) => {
                return cell.row.original.status ?
                    <Box
                        sx={{
                            display: 'inline-block',
                            padding: '1px 4px',
                            backgroundColor: getCustomQuoteBackgroundColor(cell.row.original.status),
                            color: '#fff',
                            borderRadius: '4px',
                            textAlign: 'center',

                        }}
                    >
                        {cell.row.original.status?.split(' ')
                            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                            .join(' ')
                        }
                    </Box> : null;
            },
        },
        {
            header: 'Placed Order ID',
            accessorKey: 'order_id',
            enableSorting: false,
            cell: (cell) => {
                return cell.row.original.order_id ?
                            <Link variant="h6" component={RouterLink}
                                  to={`/order-details/${cell.row.original.order_id}`}>
                                {cell.row.original.order.generated_order_id}
                            </Link> : null;
            },
        },
        {
            header: 'Actions',
            accessorKey: 'actions',
            enableSorting: false,
            cell: (cell) => {
                return (
                    <Button
                        variant="contained"
                        size={'small'}
                        sx={buttonStyles}
                        onClick={() => {
                            navigate(`/custom-quote-details/${cell.row.original.id}`);
                        }}
                    >
                        View
                    </Button>
                )
            },
            meta: {
                sx: {
                    width: '0px'
                }
            }
        }
    ];
};

export default function CustomQuoteList() {
    const navigate = useNavigate();
    const theme = useTheme();

    const currentUser = useSelector(store => store.auth.authenticatedUser);
    const [loading, setLoading] = React.useState(true);
    const [openAddCustomQuoteDialog, setOpenAddCustomQuoteDialog] = React.useState(false);
    const [openOrderCreatedDialog, setOpenOrderCreatedDialog] = React.useState(false);

    const customQuotes = useSelector(store => store.customQuotes.items);
    const quantities = useSelector(store => store.customQuotes.quantities);
    const filters = useSelector((store) => store.customQuotes.filters);
    const stores = useSelector(store => store.stores.items);

    const [searchParams, setSearchParams] = useSearchParams();

    const urlParams = React.useMemo(() => Object.fromEntries(searchParams.entries()), [searchParams]);

    const buttonStyles = React.useMemo(() => ({
            backgroundColor: theme.palette.secondary.light,
            color: theme.palette.secondary.dark,
            '&:hover': {
                color: 'white'
            }
        })
    , [theme]);

    React.useEffect(() => {
        if (filters.initialState) {
            const sortingParams = parseSortingParam(urlParams?.sort);

            setFiltersAction({
                ...filters,
                pagination: {
                    ...filters.pagination,
                    page: +urlParams?.page || filters.pagination.page,
                    perPage: +urlParams?.per_page || filters.pagination.perPage,
                },

                sorting: sortingParams.length ? sortingParams : filters.sorting,
                customQuoteStatus: urlParams?.status || filters.customQuoteStatus,
                globalFilter: urlParams?.filter || filters.globalFilter,
                startDate: urlParams?.start || filters.startDate,
                endDate: urlParams?.end || filters.endDate,
                storeIds: urlParams?.store_ids?.split(',')?.map(item => {
                    const num = Number(item);
                    return isNaN(num) ? item : num;
                }) || filters.storeIds,

                initialState: false
            });

            return;
        }

        setSearchParams(prepareUrlParams(filters));

    }, [filters, urlParams, setSearchParams]);

    React.useEffect(() => {
        return () => {
            setFiltersAction({resetFilters: true})
        };
    }, []);

////////////////////////////////////////////////////

    React.useEffect(() => {
        const getCustomQuotes = async () => {
            try {
                const params = prepareUrlParams(filters);
                const searchParams = new URLSearchParams();

                Object.entries(params).forEach(([key, value]) => {
                    if (Array.isArray(value)) {
                        value.forEach((val) => {
                            searchParams.append(`${key}[]`, val);
                        });
                    } else {
                        searchParams.append(key, value);
                    }
                });

                setLoading(true);
                await getCustomQuotesAction(searchParams.toString());
            } catch (e) {
                AlertComponent.error(e.response.data.message);
            }

            setLoading(false);
        };

        if (!filters.initialState && !filters.pagination.totalWasUpdated) {
            getCustomQuotes();
        }
    }, [filters]);

    const paginationChangeHandler = (param) => {
        if (param.value === filters.pagination[param.paramName]) {
            return;
        }

        if (param.paramName === 'page') {
            if (param.value <= 0 ||
                param.value > Math.floor(filters.pagination.total / filters.pagination.perPage) + 1) {
                return;
            }
        }

        setFiltersAction({
            ...filters,
            pagination: {
                ...filters.pagination,
                [param.paramName]: param.value
            }
        });
    };

    const storeClickHandler = (id) => {
        if (filters.storeIds.includes(id)) {
            const filteredValues = filters.storeIds.filter(item => item !== id);

            setFiltersAction({
                ...filters,
                storeIds: filteredValues,
                pagination: {
                    ...filters.pagination,
                    page: 1
                }
            });
        } else {
            setFiltersAction({
                ...filters,
                storeIds: [...filters.storeIds, id],
                pagination: {
                    ...filters.pagination,
                    page: 1
                }
            });
        }
    };

    const setGlobalFilterHandler = (value) => {
        setFiltersAction({
            ...filters,
            globalFilter: value ? value : null,
            pagination: {
                ...filters.pagination,
                page: 1
            }
        });
    };

    const setDateHandler = (paramName, value) => {
        if (paramName === 'clearAll') {
            setFiltersAction({
                ...filters,
                startDate: null,
                endDate: null,
                pagination: {
                    ...filters.pagination,
                    page: 1
                }
            });

            return;
        }

        const localTime = dayjs.tz(value.format('YYYY-MM-DD HH:mm'), currentUser.timezone);
        const utcTime = localTime.utc().format('YYYY-MM-DD HH:mm');

        setFiltersAction({
            ...filters,
            [paramName]: utcTime,
            pagination: {
                ...filters.pagination,
                page: 1
            }
        });
    };

    const selectedCustomQuotesStatusHandler = (value) => {
        setFiltersAction({
            ...filters,
            customQuoteStatus: value,
            pagination: {
                ...filters.pagination,
                page: 1
            }
        });
    };

    const setSortingHandler = (value) => {
        const sortingValue = value()[0];
        let newSortingParams = [];
        let itExists = false;

        filters.sorting.forEach(item => {
            if (item.id === sortingValue.id) {
                itExists = true;

                if (item.desc) {
                    newSortingParams.push({id: item.id, desc: false});
                }
            } else {
                newSortingParams.push(item);
            }
        });

        if (!itExists) {
            newSortingParams.push({id: sortingValue.id, desc: true});
        }

        setFiltersAction({
            ...filters,
            sorting: newSortingParams
        });
    };

    const columns = React.useMemo(() =>
            getCustomQuoteColumns(navigate, buttonStyles, currentUser.timezone),
        [navigate, buttonStyles, currentUser.timezone]
    );

    const createCustomQuoteHandler = async (data) => {
        try {
            await storeCustomQuoteAction(data);

            setOpenAddCustomQuoteDialog(false);
            setOpenOrderCreatedDialog(true);

            setLoading(true);
            await getCustomQuotesAction(searchParams.toString());
        } catch (e) {
            AlertComponent.error(e.response.data.message);
        }

        setLoading(false);
    };

    return (
        <>
            <CustomQuotesTable {
                ...{
                    caption: 'Custom Quote Submissions List',
                    columns,
                    data: customQuotes,
                    quantities,
                    stores,
                    filters,
                    loading,
                    paginationChangeHandler,
                    storeClickHandler,
                    setGlobalFilterHandler,
                    setDateHandler,
                    selectedCustomQuotesStatusHandler,
                    setSortingHandler,
                    setOpenAddCustomQuoteDialog
                }
            }
            />

            {openAddCustomQuoteDialog &&
                <AddCustomQuoteDialog
                    createCustomQuoteHandler={createCustomQuoteHandler}
                    handleClose={() => setOpenAddCustomQuoteDialog(false)}
                />
            }

            {openOrderCreatedDialog &&
            <OrderCreatedDialog
                closeDialogHandler={() => {setOpenOrderCreatedDialog(false)}}
                mainText={'Your quote has been created!'}
                showProgressBar={true}
            />
            }
        </>
    );
}