import { createSlice } from '@reduxjs/toolkit'
import {STATE_STATUSES} from "../../utils/helpers";

export const initialState = {
    authenticatedUser: undefined,
    status: STATE_STATUSES.INIT,
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setPendingStatus: (state) => {
            state.status = STATE_STATUSES.PENDING;
        },

        setReadyStatus: (state) => {
            state.status = STATE_STATUSES.READY;
        },

        setErrorStatus: (state) => {
            state.status = STATE_STATUSES.ERROR;
        },

        setAuthenticatedUser: (state, action) => {
            state.authenticatedUser = action.payload;
        },

        forgetAuthenticatedUser: (state) => {
            state.authenticatedUser = undefined;
        }
    },
});

export const {
    setPendingStatus,
    setReadyStatus,
    setErrorStatus,
    setAuthenticatedUser,
    forgetAuthenticatedUser,
} = authSlice.actions;

export default authSlice.reducer;