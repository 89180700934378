import { createSlice } from '@reduxjs/toolkit'
import {STATE_STATUSES} from "../../utils/helpers";

const initialState = {
    settings: {
        referenceNumber: '',
        customerData: '',
        shippingAddressObject: ''
    },
    status: STATE_STATUSES.INIT,
};

export const checkoutSlice = createSlice({
    name: 'checkout',
    initialState,
    reducers: {
        setPendingStatus: (state) => {
            state.status = STATE_STATUSES.PENDING;
        },

        setReadyStatus: (state) => {
            state.status = STATE_STATUSES.READY;
        },

        setCheckoutSettings: (state, action) => {
            state.settings = {
                referenceNumber: action.payload['reference_number'],
                customerData: action.payload['customer_data'],
                shippingAddressObject: action.payload['shipping_address_object']
            };
        },
    },
});

export const {
    setPendingStatus,
    setReadyStatus,
    setCheckoutSettings,
} = checkoutSlice.actions;

export default checkoutSlice.reducer;