import * as React from 'react';
import Section from "./Section";
import Grid from "@mui/material/Grid";

const DynamicFormContent = ({ form, generateElement }) => {
    return (
        <>
            {form?.sections?.map((section, index) =>
                (<Section title={section?.title} description={section?.description} key={`${section?.title}_${index}`}>
                    {section.options &&
                    <Grid container spacing={2}>
                        {section.options.map((option) => (
                            <Grid
                                item
                                xs={+(option.xs || 12)}
                                sm={+(option.sm || option.xs || 12)}
                                md={+(option.md || option.sm || option.xs || 12)}
                                lg={+(option.lg || option.md || option.sm || option.xs || 12)}

                                key={option.name}
                            >
                                {generateElement(option)}
                            </Grid>
                        ))
                        }
                    </Grid>
                    }
                </Section>)
            )}
        </>
    );
};

export default DynamicFormContent;