import * as React from 'react';
import heic2any from 'heic2any';
import * as pdfjsLib from 'pdfjs-dist';
import "pdfjs-dist/build/pdf.worker";
import {blue, blueGrey, green, orange, red} from "@mui/material/colors";

export const getJsonObject = (str) => {
    try {
        return JSON.parse(str);
    } catch (e) {
        return {};
    }
};

export const STATE_STATUSES = {
    READY: 'ready',
    PENDING: 'pending',
    ERROR: 'error',
    INIT:'init',
    DRAFT:'draft',
    CONNECTED:'connected'
};

export const ORDER_STATUSES = {
    SUBMITTED: 'submitted',
    IN_PROCESS: 'in process',
    PARTIALLY_COMPLETED: 'partially completed',
    COMPLETED: 'completed',
    CANCELED: 'canceled'
};

export const ORDER_DELIVERY_STATUSES = {
    FULFILLED: 'fulfilled',
    SHIPPED: 'shipped',
    DELIVERED: 'delivered',
    MULTI: '(Multi)',
    PRE_TRANSIT: 'pre_transit',
    TRANSIT: 'transit',
    RETURNED: 'returned',
    FAILURE: 'failure',
    UNKNOWN: 'unknown'
};

export const ORDER_ITEM_DELIVERY_STATUSES = {
    SHIPPED: 'shipped',
    PRE_TRANSIT: 'pre_transit',
    TRANSIT: 'transit',
    DELIVERED: 'delivered',
    RETURNED: 'returned',
    FAILURE: 'failure',
    UNKNOWN: 'unknown',
    FULFILLED: 'fulfilled'
};

export const ROLE_NAMES = {
    GLOBAL_ADMIN: 'Global Admin',
    COMPANY_ADMIN: 'Company Admin',
    FINANCIAL_MANAGER: 'Financial Manager',
    SALES_MANAGER: 'Sales Manager',
    CUSTOM_SPECIALIST: 'Custom Specialist',
    SALESPERSON: 'Salesperson'
};

export const CUSTOM_QUOTE_STATUSES = {
    SUBMITTED: 'submitted',
    PROCESSING: 'processing request',
    WAITING_FEEDBACK: 'waiting for feedback',
    FEEDBACK_SUBMITTED: 'feedback submitted',
    PROCESSING_FEEDBACK: 'processing feedback',
    CANCELED: 'canceled',
    ORDERED: 'ordered'
};

export const CUSTOM_QUOTE_VERSION_STATUSES = {
    WAITING_FEEDBACK: 'waiting for feedback',
    FEEDBACK_SUBMITTED: 'feedback submitted',
    UNPUBLISHED: 'unpublished',
    APPROVED: 'approved',
    REJECTED: 'rejected'
};

export const prepareUrlParams = (filters) => {
    const combineSortingValues = (sortingValues) => {
        const strings = [];

        sortingValues.forEach(value => {
            strings.push(`${value.desc ? '-' : ''}${value.id}`);
        });

        return strings.join(',');
    };

    const params = {
        page: filters.pagination.page,
        per_page: filters.pagination.perPage,
    };

    if (filters.sorting?.length > 0) {
        params.sort = combineSortingValues(filters.sorting);
    }

    if (filters.orderStatus || filters.userStatus || filters.storeStatus || filters.productStatus || filters.customQuoteStatus) {
        params.status = filters.orderStatus || filters.userStatus || filters.storeStatus || filters.productStatus || filters.customQuoteStatus;
    }

    if (filters.globalFilter) {
        params.filter = filters.globalFilter;
    }

    if (filters.startDate) {
        params.start = filters.startDate;
    }

    if (filters.endDate) {
        params.end = filters.endDate;
    }

    if (filters.storeIds?.length > 0) {
        params.store_ids = filters.storeIds.join(',');
    }

    return params;
};

export const parseSortingParam = (param) => {
    const sortingValues = [];

    param?.split(',').forEach(item => {
        if (item[0] === '-'){
            sortingValues.push({id: item.substring(1), desc: true})
        } else {
            sortingValues.push({id: item, desc: false})
        }
    });

    return sortingValues;
};

export const getProductMainImage = (product) => {
    const media = getJsonObject(product?.media)?.media;
    const images = media?.filter(obj => !obj.video);
    return images?.[0]?.original ?? '/no-image.png';
};


export const generatePreview = (file, setFilePreviews) => {
    const ImagePreview = ({ url }) => {
        return (
            <img
                src={url}
                alt="Preview"
                style={{ width: '50px', marginRight: '5px'}}
            />
        );
    };

    const fileType = file.type;
    let previewComponent;

    if (fileType.startsWith('image/')) {
        if (fileType === 'image/heif') {
            heic2any({ blob: file, toType: 'image/png' }).then(blob => {
                setFilePreviews((prev) => {
                    return [
                        ...prev.filter(item => item.fileName !== file.name),
                        {
                            fileName: file.name,
                            component: <ImagePreview url={URL.createObjectURL(blob)}/>
                        }
                    ];
                });
            });
        } else {
            previewComponent = <ImagePreview url={URL.createObjectURL(file)}/>;
        }
    }
    else if (fileType.startsWith('video/')) {
        const video = document.createElement('video');
        video.crossOrigin = 'anonymous';
        video.src = URL.createObjectURL(file);
        video.currentTime = 1;

        video.addEventListener("loadeddata", () => {
            const canvas = document.createElement('canvas');
            canvas.width = video.videoWidth / 4;
            canvas.height = video.videoHeight / 4;

            const context = canvas.getContext('2d');

            context.drawImage(video, 0, 0, canvas.width, canvas.height);

            setFilePreviews((prev) => {
                return [
                    ...prev.filter(item => item.fileName !== file.name),
                    {
                        fileName: file.name,
                        component: <ImagePreview url={canvas.toDataURL()}/>
                    }
                ];
            });
        });
    }

    else if (fileType === "application/pdf") {
        const fileReader = new FileReader();

        fileReader.onload = async (e) => {
            const typedArray = new Uint8Array(e.target.result);
            const pdf = await pdfjsLib.getDocument(typedArray).promise;
            const page = await pdf.getPage(1);
            const viewport = page.getViewport({ scale: 0.5 });
            const canvas = document.createElement('canvas');
            canvas.width = viewport.width;
            canvas.height = viewport.height;
            const ctx = canvas.getContext('2d');
            await page.render({ canvasContext: ctx, viewport }).promise;

            setFilePreviews((prev) => {
                return [
                    ...prev.filter(item => item.fileName !== file.name),
                    {
                        fileName: file.name,
                        component: <ImagePreview url={canvas.toDataURL()}/>
                    }
                ];
            });
        };

        fileReader.readAsArrayBuffer(file);
    }

    if (!previewComponent) {
        previewComponent = <ImagePreview url={'/default-file.svg'}/>;
    }

    setFilePreviews((prev) => {
        return [...prev, {fileName: file.name, component: previewComponent}];
    });
};


export const generatePreviewForUploadedFile = (file, setFilePreviews) => {
    const ImagePreview = ({ url }) => {
        return (
            <img
                src={url}
                alt="Preview"
                style={{ width: '100%', maxHeight: 'calc(100vh - 150px)'}}
            />
        );
    };

    const fetchFileAndGeneratePreview = async (file, setFilePreviews) => {
        const response = await fetch(file.url, {
            method: 'GET',
            mode: 'cors',
        });

        const blob = await response.blob();
        const fileType = file.mime;

        if (fileType === 'image/heic') {
            heic2any({ blob: blob, toType: 'image/png' }).then(convertedBlob => {
                setFilePreviews((prev) => {
                    return [
                        ...prev.filter(item => item.fileName !== file.original_name),
                        {
                            fileName: file.original_name,
                            component: <ImagePreview url={URL.createObjectURL(convertedBlob)}/>
                        }
                    ];
                });
            });
        } else if (fileType.startsWith('video/')) {
            const video = document.createElement('video');
            video.crossOrigin = 'anonymous';
            video.src = URL.createObjectURL(blob);
            video.currentTime = 1;

            video.addEventListener("loadeddata", () => {
                const canvas = document.createElement('canvas');
                canvas.width = video.videoWidth / 4;
                canvas.height = video.videoHeight / 4;

                const context = canvas.getContext('2d');

                context.drawImage(video, 0, 0, canvas.width, canvas.height);

                setFilePreviews((prev) => {
                    return [
                        ...prev.filter(item => item.fileName !== file.original_name),
                        {
                            fileName: file.original_name,
                            component: <ImagePreview url={canvas.toDataURL()}/>
                        }
                    ];
                });
            });
        } else if (fileType === "application/pdf") {
            const fileReader = new FileReader();

            fileReader.onload = async (e) => {
                const pdf = await pdfjsLib.getDocument(URL.createObjectURL(blob)).promise;
                const page = await pdf.getPage(1);
                const viewport = page.getViewport({ scale: 0.5 });
                const canvas = document.createElement('canvas');
                canvas.width = viewport.width;
                canvas.height = viewport.height;
                const ctx = canvas.getContext('2d');
                await page.render({ canvasContext: ctx, viewport }).promise;

                setFilePreviews((prev) => {
                    return [
                        ...prev.filter(item => item.fileName !== file.original_name),
                        {
                            fileName: file.original_name,
                            component: <ImagePreview url={canvas.toDataURL()}/>
                        }
                    ];
                });
            };

            fileReader.readAsArrayBuffer(blob);
        }
    };

    let previewComponent = <ImagePreview url={'/default-file.svg'}/>;

    if (file.mime.startsWith('image/') && file.mime !== 'image/heic') {
        previewComponent = <ImagePreview url={file.url}/>;
    } else {
        fetchFileAndGeneratePreview(file, setFilePreviews);
    }

    setFilePreviews((prev) => {
        return [...prev, {fileName: file.original_name, component: previewComponent}];
    });
};

export const findPreviewComponent = (fileName, filePreviews) =>
    filePreviews.find((preview) => preview.fileName === fileName)?.component;

export const getCustomQuoteBackgroundColor = (status) => {
    switch (status) {
        case CUSTOM_QUOTE_STATUSES.SUBMITTED:
            return blueGrey[500];
        case CUSTOM_QUOTE_STATUSES.PROCESSING:
            return blue[500];
        case CUSTOM_QUOTE_STATUSES.WAITING_FEEDBACK:
            return orange[500];
        case CUSTOM_QUOTE_STATUSES.FEEDBACK_SUBMITTED:
            return blueGrey[500];
        case CUSTOM_QUOTE_STATUSES.PROCESSING_FEEDBACK:
            return blue[500];
        case CUSTOM_QUOTE_STATUSES.CANCELED:
            return red[500];
        default:
            return green[500];
    }
};