import * as React from 'react';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from '@mui/icons-material/Close';
import { updateProductPriceAction } from "../../store/actions/settings";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import {getProductMainImage, STATE_STATUSES} from "../../utils/helpers";
import AlertComponent from "../../components/AlertComponent";
import {useSelector} from "react-redux";

const UpdateProductDialog = ({ isOpen, product, handleClose, successHandler }) => {
    const [price, setPrice] = React.useState(product.msrp);
    const {status} = useSelector(store => store.settings);
    const [wasSubmit, setWasSubmit] = React.useState(false);

    const handleSubmit = async () => {
        try {
            await updateProductPriceAction(product.id, parseFloat(price).toFixed(2));

            const oldPrice = (+product.msrp).toLocaleString(
                'en-US',
                {
                    style: 'currency',
                    currency: 'USD'
                });

            const newPrice = (+price).toLocaleString(
                'en-US',
                {
                    style: 'currency',
                    currency: 'USD'
                });

            successHandler(`Price for ${product.sku} Updated from ${oldPrice} to ${newPrice}.`);
            setWasSubmit(true);
        } catch (e) {
            AlertComponent.error(e.response.data.message);
        }
    };

    React.useEffect(() => {
        if (wasSubmit && status === STATE_STATUSES.READY) {
            handleClose();
        }
    }, [status, handleClose, wasSubmit]);

    return (
        <Dialog
            aria-labelledby="customized-dialog-title"
            open={true}
        >
            <DialogTitle sx={{ m: 0, p: 2, fontWeight: 700 }} id="customized-dialog-title">
                Edit Product Price
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid item xs={6}>
                        <img
                            src={getProductMainImage(product)}
                            alt={'Main product\'s representation'}
                            loading="lazy"
                            width={'100%'}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <Stack
                            spacing={2}
                            sx={{
                                justifyContent: 'space-between',
                                height: '100%'
                            }}
                        >
                            <Stack spacing={2}>
                                <Typography component={"div"} variant={"h5"}>
                                    {product.title}
                                </Typography>

                                <Typography component={"div"} variant={"h8"}>
                                    SKU: {product.sku}
                                </Typography>

                                <Box
                                    margin={"30px 0"}
                                    component={'p'}
                                    sx={{
                                        wordBreak: "break-word"
                                    }}
                                >
                                    {product.description}
                                </Box>
                            </Stack>

                            <TextField
                                required
                                label="MSRP"
                                value={price}
                                onChange={(e) => {
                                    setPrice(e.target.value);
                                }}
                                placeholder="Enter MSRP..."
                            />
                        </Stack>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions
                sx={{
                    padding: '16px'
                }}
            >
                <Button
                    color={'error'}
                    onClick={handleClose}
                >
                    Cancel
                </Button>
                <Button
                    variant={"contained"}
                    onClick={handleSubmit}
                    disabled={!/^\d+(\.\d{1,2})?$/.test(price)}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UpdateProductDialog