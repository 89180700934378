import * as React from 'react';
import {Link} from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

const OutdatedTokenForm = () => {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Box sx={{ mb: { xs: -0.5, sm: 0.5 } }}>
                    <Typography variant="h3">Oops! Your Password Reset Link Has Expired</Typography>
                    <Typography color="secondary" sx={{ mb: 0.5, mt: 1.25 }}>
                        It looks like the password reset link you clicked on has expired. For security reasons, password reset links are only valid for a limited time. Please request a new password reset by click the button below.
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Button
                    component={Link}
                    to={'/forgot-password'}
                    disableElevation
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    color="primary"
                >
                    Request New Password Reset
                </Button>
            </Grid>
        </Grid>
    );
};

export default OutdatedTokenForm;