import * as React from 'react';
import MainCard from "../../components/MainCard";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { useParams } from 'react-router-dom';
import {convertDraftOrderToCartItemsAction, deleteOrderAction, getOrderAction} from "../../store/actions/orders";
import {useSelector} from "react-redux";
import Grid from "@mui/material/Grid";
import DefaultLayout from "../../components/layouts/DefaultLayout/DefaultLayout";
import OrderItemCartEntity from "../../components/OrderItemCartEntity";
import Box from "@mui/material/Box";
import {Button, TableRow} from "@mui/material";
import {useNavigate} from 'react-router-dom';
import TableBodySkeleton from "./TableBodySkeleton";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import AlertComponent from "../../components/AlertComponent";

const ConvertDraftOrderPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const order = useSelector(store => store.orders.selectedItem);
    const [loading, setLoading] = React.useState(false);
    const [selectedOrderItemIds, setSelectedOrderItemIds] = React.useState([]);

    React.useEffect(() => {
        const getOrder = async () => {
            try {
                setLoading(true);
                await getOrderAction(id);
            } catch (e) {
                AlertComponent.error(e.response.data.message);
            }

            setLoading(false);
        };

        getOrder();
    }, [id]);

    const checkboxClickHandler = (orderItemId) => {
        if (selectedOrderItemIds.includes(orderItemId)) {
            setSelectedOrderItemIds(prev => prev.filter(item => item !== orderItemId));
        } else {
            setSelectedOrderItemIds(prev => [...prev, orderItemId]);
        }
    };

    const convertDraftOrderClickHandler = async () => {
        try {
            await convertDraftOrderToCartItemsAction(order?.id, selectedOrderItemIds);
            await deleteOrderAction(order?.id);
            navigate('/checkout');
        } catch (e) {
            AlertComponent.error(e.response.data.message);
        }
    };

    return (
        <DefaultLayout>
            <MainCard content={false}>
                <Grid container>
                    <Grid item xs={12} sx={{ py: 2.5, pl: 2.5 }}>
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <Typography variant="subtitle1">Draft Order: {order?.generated_order_id}</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell component={'th'}/>
                                        <TableCell
                                            component={'th'}
                                            sx={{
                                                textAlign: 'center'
                                            }}
                                        >
                                            MSRP
                                        </TableCell>
                                        <TableCell
                                            component={'th'}
                                            sx={{
                                                textAlign: 'center'
                                            }}
                                        >
                                            Quantity
                                        </TableCell>
                                        <TableCell
                                            component={'th'}
                                            sx={{
                                                textAlign: 'center'
                                            }}
                                        >
                                            Total Amount
                                        </TableCell>
                                        <TableCell component={'th'}/>
                                    </TableRow>
                                </TableHead>

                                {!loading && <TableBody>
                                    {order?.items.map((singleItem) => {
                                        return (
                                            <OrderItemCartEntity
                                                key={singleItem.id}
                                                singleItem={singleItem}
                                                isForDraftOrder={true}
                                                isSelected={selectedOrderItemIds.includes(singleItem.id)}
                                                checkboxClickHandler={checkboxClickHandler}
                                            />
                                        );
                                    })}
                                </TableBody>
                                }
                                
                                {loading &&
                                    <TableBodySkeleton/>
                                }
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </MainCard>

            <Box
                marginTop={'32px'}
                textAlign={'center'}
            >
                <Button
                    sx={{
                        width: '150px',
                        margin: '0 16px'
                    }}
                    variant="contained"
                    size='small'
                    color={'secondary'}
                    onClick={() => navigate('/draft-orders')}
                >
                    Back to draft orders
                </Button>
                <Button
                    sx={{
                        width: '150px',
                        margin: '0 16px'
                    }}
                    variant="contained"
                    size='small'
                    color={'primary'}
                    onClick={convertDraftOrderClickHandler}
                    disabled={selectedOrderItemIds.length === 0}
                >
                    Add to current Cart
                </Button>
            </Box>
        </DefaultLayout>
    )
};

export default ConvertDraftOrderPage;