import { createSlice } from '@reduxjs/toolkit'
import {STATE_STATUSES} from "../../utils/helpers";

const initialState = {
    currentStore: undefined,
    status: STATE_STATUSES.INIT,
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setPendingStatus: (state) => {
            state.status = STATE_STATUSES.PENDING;
        },

        setReadyStatus: (state) => {
            state.status = STATE_STATUSES.READY;
        },
    },
});

export const {
    setPendingStatus,
    setReadyStatus,
} = userSlice.actions;

export default userSlice.reducer;