import * as React from 'react';
import Box from "@mui/material/Box";
import {Typography} from "@mui/material";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import AddStoneDialog from "./AddStoneDialog";
import Divider from "@mui/material/Divider";
import {useSelector} from "react-redux";
import {getJsonObject} from "../../utils/helpers";
import {getCustomQuoteFormsConfigAction} from "../../store/actions/customQuotes";
import Stack from "@mui/material/Stack";
import {getOriginalTitleAndOptionValue} from "./utils";
import AlertComponent from "../../components/AlertComponent";
import SingleStoneParamItem from "./SingleStoneParamItem";

const StoneListComponent = ({ state, option, onChangeHandler, stateFunction }) => {
    const formsConfig = useSelector(store => store.customQuotes.formsConfig);
    const addStoneFormConfig = React.useMemo(() => getJsonObject(formsConfig)?.addStoneForm, [formsConfig]);

    const [openDialog, setOpenDialog] = React.useState(false);
    const [selectedStoneIndex, setSelectedStoneIndex] = React.useState(undefined);

    React.useEffect(() => {
        const getFormsConfig = async () => {
            if (!addStoneFormConfig) {
                try {
                    await getCustomQuoteFormsConfigAction();
                } catch (e) {
                    AlertComponent.error(e.response.data.message);
                }
            }
        };

        getFormsConfig();
    }, [addStoneFormConfig]);

    const optionsList = React.useMemo(() => {
        const result = [];

        addStoneFormConfig?.sections?.forEach(section => {
            section?.options.forEach(option => {
                result.push(option);
            });
        });

        return result;
    }, [addStoneFormConfig]);

    const createOrUpdateStone = (options) => {
        if (typeof(selectedStoneIndex) !== 'undefined') {
            const newStonesOptions = state[option.name] || [];
            newStonesOptions[selectedStoneIndex] = options;
            onChangeHandler(option, {target: {value: newStonesOptions}}, stateFunction);
        } else {
            onChangeHandler(option, {target: {value: [...(state[option.name] || []), options]}}, stateFunction);
        }
    };

    const saveHandler = (options) => {
        createOrUpdateStone(options);
        setOpenDialog(false);
    };

    const saveAndNewHandler = (options) => {
        createOrUpdateStone(options);
    };

    const deleteStoneHandler = (stoneIndex) => {
        const newStonesOptions = state[option.name] || [];
        newStonesOptions.splice(stoneIndex, 1);
        onChangeHandler(option, {target: {value: newStonesOptions}}, stateFunction);
    };


    const StoneRow = ({ stoneParams, addStoneFormOptions, hiddenParams, onEdit, onDelete }) => {
        return (
            <>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            '& > :not(:first-child):not(:last-child)': {
                                marginLeft: 1,
                                marginRight: 1
                            },
                            '& > :first-child': {
                                marginRight: 1
                            },
                            '& > :last-child': {
                                marginLeft: 1
                            }
                        }}
                    >
                        {addStoneFormOptions.map((option, index) => {
                            if ((hiddenParams || []).includes(option.name)) {
                                return null;
                            }

                            const originalOption = getOriginalTitleAndOptionValue(option.name, stoneParams[option.name], addStoneFormOptions);

                            return (
                                <SingleStoneParamItem
                                    key={option.name + index}
                                    title={originalOption.title}
                                    value={originalOption.value}
                                />
                            )
                        })}
                    </Box>

                    <Stack direction={'row'}>
                        <Link
                            href="#"
                            sx={{
                                mr: 1,
                                width: '45px',
                                textAlign: 'center',
                                display: 'inline-block'
                            }}
                            onClick={onEdit}
                        >
                            Edit
                        </Link>
                        <Link
                            href="#"
                            sx={{
                                width: '45px',
                                textAlign: 'center',
                                display: 'inline-block'
                            }}
                            color="error"
                            onClick={onDelete}
                        >
                            Delete
                        </Link>
                    </Stack>
                </Box>
                <Divider/>
            </>
        );
    };

    return (
        <Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                <Typography
                    variant="h6"
                    sx={{
                        fontWeight: 600
                    }}
                >
                    Stone List
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={
                        () => {
                            setSelectedStoneIndex(undefined);
                            setOpenDialog(true)
                        }
                    }
                >+ Add New</Button>
            </Box>

            <Divider/>

            <Stack spacing={1} sx={{ mt: 1 }}>
                {state[option.name]?.map((stoneParams, index) => {
                    return (
                        <StoneRow
                            key={index}
                            addStoneFormOptions={optionsList}
                            stoneParams={stoneParams}
                            hiddenParams={addStoneFormConfig.hiddenParams}
                            onEdit={() => {
                                setSelectedStoneIndex(index);
                                setOpenDialog(true);
                            }}
                            onDelete={() => deleteStoneHandler(index)}
                        />
                    );
                })}
            </Stack>

            {openDialog &&
                <AddStoneDialog
                    stoneOptions={
                        typeof(selectedStoneIndex) !== 'undefined' ?
                            state[option.name][selectedStoneIndex] :
                            {}
                    }
                    closeHandler={() => {setOpenDialog(false);}}
                    saveAndNewHandler={saveAndNewHandler}
                    saveHandler={saveHandler}
                />
            }
        </Box>
    );
};

export default StoneListComponent;