import * as React from 'react';
import {useSearchParams} from "react-router-dom";
import DefaultLayout from "../../components/layouts/DefaultLayout/DefaultLayout";
import {useSelector} from "react-redux";
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import NotInterestedOutlinedIcon from '@mui/icons-material/NotInterestedOutlined';
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";

import UsersTable from "./UsersTable";
import {getJsonObject, parseSortingParam, prepareUrlParams, ROLE_NAMES} from "../../utils/helpers";
import UserProfilePopup from "./UserProfilePopup";
import {setFiltersAction, getUsersAction, getUserStoresAction} from "../../store/actions/settings";
import Button from "@mui/material/Button";
import TabsComponent from "./TabsComponent";

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import CustomizedSnackbar from "../../components/CustomizedSnackbar";
import AlertComponent from "../../components/AlertComponent";

dayjs.extend(utc);
dayjs.extend(timezone);

const getUserColumns = (setSelectedUserId, setUserUpdatedContent, setOpenUserForm, currentUser) => {
    const isUserHasRichRights = (user) => {
        return [ROLE_NAMES.GLOBAL_ADMIN, ROLE_NAMES.COMPANY_ADMIN, ROLE_NAMES.FINANCIAL_MANAGER].includes(user?.role?.name);
    };

    let columns = [
        {
            id: 'expander',
            enableSorting: false,
            header: () => null,
            cell: ({ row }) => {
                if (isUserHasRichRights(row.original)) {
                    return null;
                }

                return row.getCanExpand() ? (
                    <IconButton color={row.getIsExpanded() ? 'primary' : 'secondary'} onClick={row.getToggleExpandedHandler()} size="small">
                        {row.getIsExpanded() ? <ExpandMoreOutlinedIcon /> : <KeyboardArrowRightIcon />}
                    </IconButton>
                ) : (
                    <IconButton color="secondary" size="small" disabled>
                        <NotInterestedOutlinedIcon />
                    </IconButton>
                );
            },
            meta: {
                sx: {
                    width: '64px'
                }
            }
        },
        {
            header: 'Date',
            accessorKey: 'created_at',
            cell: (cell) => {
                const dateValue = dayjs.utc(cell.row.original.created_at).tz(currentUser.timezone);
                return dateValue.format('MMMM D, YYYY h:mm A');
            },
            meta: {
                sx: {
                    width: '210px'
                }
            }
        },
        {
            header: 'Employee Code',
            accessorKey: 'employee_code',
        },
        {
            header: 'Email',
            accessorKey: 'email'
        },
        {
            header: 'First Name',
            accessorKey: 'first_name'
        },
        {
            header: 'Last Name',
            accessorKey: 'last_name'
        },
        {
            header: 'Store Locations',
            cell: ({ row }) => {
                let result = row.original?.stores_count ?? 0;

                if (isUserHasRichRights(row.original)) {
                    result = `All (${result})`;
                }
                return result;
            }
        },
        {
            header: 'Role Type',
            accessorKey: 'role_type',
        },
        {
            header: 'Status',
            accessorKey: 'status',
            cell: (cell) => {
                switch (cell.getValue()) {
                    case 'active':
                        return <Chip color="primary" label="Active" size="small" variant="light" />;
                    case 'disabled':
                        return <Chip color="info" label="Disabled" size="small" variant="light" />;
                    case 'archived':
                        return <Chip color="error" label="Archived" size="small" variant="light" />;
                    default: return null;
                }
            }
        },
        {
            header: 'Actions',
            enableSorting: false,
            cell: ({ row }) => {
                return (
                    <Button
                        variant={'contained'}
                        color={'warning'}
                        onClick={async () => {
                            try {
                                setUserUpdatedContent(null);
                                setSelectedUserId(row.original.id);

                                await getUserStoresAction(row.original.id);
                                setOpenUserForm(true);
                            } catch (e) {
                                AlertComponent.error(e.response.data.message);
                            }
                        }}
                    >
                        Edit
                    </Button>
                )
            },
            meta: {
                sx: {
                    width: '0px'
                }
            }
        }
    ];

    if (![ROLE_NAMES.GLOBAL_ADMIN, ROLE_NAMES.COMPANY_ADMIN].includes(currentUser?.role?.name)) {
        columns.pop();
    }

    return columns;
};

const storesColumns = [
    {
        header: 'Location ID',
        accessorKey: 'id',
    },
    {
        header: 'Name',
        accessorKey: 'name',
    },
    {
        header: 'Street Address',
        accessorKey: 'address',
        cell: ({ row }) => {
            const jsonObject = getJsonObject(row.original?.shipping_address);
            return [jsonObject.address_line_1, jsonObject.address_line_2].join(', ');
        }
    },

    {
        header: 'City',
        accessorKey: 'city',
        cell: ({ row }) => {
            const jsonObject = getJsonObject(row.original?.shipping_address);
            return jsonObject.city ?? '';
        }
    },
    {
        header: 'State',
        accessorKey: 'state',
        cell: ({ row }) => {
            const jsonObject = getJsonObject(row.original?.shipping_address);
            return jsonObject.state ?? '';
        }
    },
    {
        header: 'Postal code',
        accessorKey: 'postal_code',
        cell: ({ row }) => {
            const jsonObject = getJsonObject(row.original?.shipping_address);
            return jsonObject.postal_code ?? '';
        }
    },
    {
        header: 'Country',
        accessorKey: 'country',
        cell: ({ row }) => {
            const jsonObject = getJsonObject(row.original?.shipping_address);
            return jsonObject.country ?? '';
        }
    },
];

const getUsers = async (filters, setLoading) => {
    const params = prepareUrlParams(filters);
    const searchParams = new URLSearchParams();

    Object.entries(params).forEach(([key, value]) => {
        if (Array.isArray(value)) {
            value.forEach((val) => {
                searchParams.append(`${key}[]`, val);
            });
        } else {
            searchParams.append(key, value);
        }
    });

    try {
        setLoading(true);
        await getUsersAction(searchParams.toString());
    } catch (e) {
        AlertComponent.error(e.response.data.message);
    }

    setLoading(false);
};

const UsersManagementPage = () => {
    const currentUser = useSelector(store => store.auth.authenticatedUser);
    const users = useSelector(store => store.settings.users);
    const quantities = useSelector((store) => store.settings.quantities);
    const filters = useSelector((store) => store.settings.filters);

    const [openUserForm, setOpenUserForm] = React.useState(false);
    const [userUpdatedContent, setUserUpdatedContent] = React.useState(null);

    const [loading, setLoading] = React.useState(true);
    const [storesLoading, setStoresLoading] = React.useState(true);
    const [selectedUserId, setSelectedUserId] = React.useState({});
    const [expanded, setExpanded] = React.useState({});

    const [searchParams, setSearchParams] = useSearchParams();
    const urlParams = React.useMemo(() => Object.fromEntries(searchParams.entries()), [searchParams]);

    React.useEffect(() => {
        if (filters.initialState) {
            const sortingParams = parseSortingParam(urlParams?.sort);

            setFiltersAction({
                ...filters,
                pagination: {
                    ...filters.pagination,
                    page: +urlParams?.page || filters.pagination.page,
                    perPage: +urlParams?.per_page || filters.pagination.perPage,
                },

                sorting: sortingParams.length ? sortingParams : filters.sorting,
                userStatus: urlParams?.status || filters.userStatus,
                globalFilter: urlParams?.filter || filters.globalFilter,
                initialState: false
            });

            return;
        }

        setSearchParams(prepareUrlParams(filters));

    }, [filters, urlParams, setSearchParams]);

    React.useEffect(() => {
        if (!filters.initialState && !filters.pagination.totalWasUpdated) {
            getUsers(filters, setLoading);
        }
    }, [filters]);

    React.useEffect(() => {
        return () => {
            setFiltersAction({resetFilters: true})
        };
    }, []);

    const columns = React.useMemo(() => getUserColumns(setSelectedUserId, setUserUpdatedContent, setOpenUserForm, currentUser),
        [setSelectedUserId, setUserUpdatedContent, setOpenUserForm, currentUser]
    );

    const paginationChangeHandler = (param) => {
        if (param.value === filters.pagination[param.paramName]) {
            return;
        }

        if (param.paramName === 'page') {
            if (param.value <= 0 ||
                param.value > Math.floor(filters.pagination.total / filters.pagination.perPage) + 1) {
                return;
            }
        }

        setExpanded({});

        setFiltersAction({
            ...filters,
            pagination: {
                ...filters.pagination,
                [param.paramName]: param.value
            }
        });
    };

    const setGlobalFilterHandler = (value) => {
        setExpanded({});

        setFiltersAction({
            ...filters,
            globalFilter: value ? value : null,
            pagination: {
                ...filters.pagination,
                page: 1
            }
        });
    };

    const selectedUsersStatusHandler = (value) => {
        setExpanded({});

        setFiltersAction({
            ...filters,
            userStatus: value,
            pagination: {
                ...filters.pagination,
                page: 1
            }
        });
    };

    const setSortingHandler = (value) => {
        const sortingValue = value()[0];
        let newSortingParams = [];
        let itExists = false;

        setExpanded({});

        filters.sorting.forEach(item => {
            if (item.id === sortingValue.id) {
                itExists = true;

                if (item.desc) {
                    newSortingParams.push({id: item.id, desc: false});
                }
            } else {
                newSortingParams.push(item);
            }
        });

        if (!itExists) {
            newSortingParams.push({id: sortingValue.id, desc: true});
        }

        setFiltersAction({
            ...filters,
            sorting: newSortingParams
        });
    };

    const setExpandedHandler = async (value) => {
        const expandedValue = value();

        const key = Object.keys(expandedValue)[0];

        setExpanded(prev => ({
            ...prev,
            [key]: !prev[key]
        }));

        const user = users[key];

        if (!user.stores) {
            try {
                setStoresLoading(true);
                await getUserStoresAction(user.id);
            } catch (e) {
                AlertComponent.error(e.response.data.message);
            }
        }

        setStoresLoading(false);
    };

    return (
        <DefaultLayout>
            <TabsComponent
                currentTabIndex={0}
            />

            <div
                role="tabpanel"
                id={'tabpanel-0'}
                aria-labelledby={'tab-0'}
            >
                <UsersTable {...{
                    caption: 'User List',
                    columns,
                    subColumns: storesColumns,
                    data: users,
                    quantities: quantities,
                    filters,
                    loading,
                    storesLoading,
                    paginationChangeHandler,
                    setGlobalFilterHandler,
                    selectedUsersStatusHandler,
                    setSortingHandler,
                    expanded,
                    setExpandedHandler,
                    setOpenUserForm,
                    setSelectedUserId
                }}
                />

            </div>

            <UserProfilePopup
                openUserForm={openUserForm}
                selectedUserId={selectedUserId}
                handleClose={() => setOpenUserForm(false)}
                getUsersFunction={() => getUsers(filters, setLoading)}
                successHandler={(content) => {
                    setUserUpdatedContent(content);
                }}
            />

            {userUpdatedContent &&
                <CustomizedSnackbar
                    content={userUpdatedContent}
                    handleClose={() => setUserUpdatedContent(null)}
                />
            }
        </DefaultLayout>
    );
};

export default UsersManagementPage;