import * as React from "react";
import OrdersList from "./OrdersList";
import DefaultLayout from "../../components/layouts/DefaultLayout/DefaultLayout";

const OrdersHistoryPage = () => {
    return (
        <DefaultLayout>
            <OrdersList
                caption={"Order History List"}
                selectDrafts={false}
            />
        </DefaultLayout>
    );
};

export default OrdersHistoryPage;