import * as React from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Skeleton from '@mui/material/Skeleton';

// third-party
import {
    flexRender,
    useReactTable,
    getExpandedRowModel,
    getCoreRowModel,
} from '@tanstack/react-table';

// project import
import ScrollX from '../../components/ScrollX';

import MainCard from "../../components/MainCard";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import HeaderSort from "../../components/HeaderSort";
import TablePagination from "../../components/TablePagination";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import DebouncedInput from "../../components/DebouncedInput";
import Button from "@mui/material/Button";
import {useSelector} from "react-redux";
import NoItemsComponent from "../../components/NoItemsComponent";
import {ROLE_NAMES} from "../../utils/helpers";

const RenderSubComponent = ({ row, subColumns, filters, storesLoading }) => {
    return (
        <MainCard
            title={`Store Locations`}
            content={false}
            sx={{ ml: { xs: 2.5, sm: 5, md: 6, lg: 10, xl: 12 } }}
        >
            <UsersTable {
                ...{
                    columns: subColumns,
                    data: row.original.stores || [],
                    storesLoading,
                    isList: true,
                    filters
                }
            } />
        </MainCard>
    );
};

const UsersTable = (
    {
        caption, columns, subColumns, data, quantities, loading, storesLoading, isList,
        paginationChangeHandler, filters, setGlobalFilterHandler, setDateHandler,
        selectedUsersStatusHandler, setSortingHandler, expanded, setExpandedHandler,
        setOpenUserForm, setSelectedUserId
    }) => {

    const currentUser = useSelector(store => store.auth.authenticatedUser);

    const theme = useTheme();

    const groups = ['all', 'active', 'disabled', /*'archived'*/];

    const table = useReactTable({
        data,
        columns,
        state: {
            sorting: filters.sorting,
            expanded
        },
        manualSorting: true,
        getRowCanExpand: () => true,
        getCoreRowModel: getCoreRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        debugTable: true,
        onSortingChange: setSortingHandler,
        onExpandedChange: setExpandedHandler,
    });

    const bottomBorderColor = alpha(theme.palette.secondary.light, 0.5);
    const backColor = 'white';

    if (isList && storesLoading) {
        return (
            <Table
                size="small"
            >
                <TableHead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <TableRow key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                                <TableCell key={header.id} {...header.column.columnDef.meta}>
                                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableHead>
                <TableBody>
                    {[0, 1, 2].map((item) => (
                        <TableRow key={item}>
                            {[0, 1, 2, 3, 4, 5, 6].map((col) => (
                                <TableCell key={col}>
                                    <Skeleton animation="wave" />
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        );
    }

    return (
        <MainCard
            content={false}
            {...(!isList && {
                title: caption,
                secondary: [ROLE_NAMES.GLOBAL_ADMIN, ROLE_NAMES.COMPANY_ADMIN].includes(currentUser?.role?.name) ?
                    <Button
                        onClick={() => {
                            setOpenUserForm(true);
                            setSelectedUserId(0);
                        }}
                        variant={'contained'}
                    >
                        Create New User
                    </Button> : null
            })}
        >
            {!isList &&
            <>
                <Box sx={{p: 2.5, pb: 0, width: '100%'}}>
                    <Tabs
                        value={filters.userStatus || 'all'}
                        onChange={(e, value) => {
                            selectedUsersStatusHandler(value === 'all' ? null : value);
                        }}
                        sx={{borderBottom: 1, borderColor: 'divider'}}
                    >
                        {groups.map((status, index) => {
                            const label = status === 'all' ? quantities['active'] + quantities['disabled'] + quantities['archived'] : quantities[status];
                            let renderedChip;

                            switch (status) {
                                case 'all':
                                    renderedChip = <Chip color="secondary" label={loading ? '-' : label} size="small" variant="dark" />;
                                    break;
                                case 'active':
                                    renderedChip = <Chip color="primary" label={loading ? '-' :label} size="small" variant="main" />;
                                    break;
                                case 'disabled':
                                default:
                                    renderedChip = <Chip color="warning" label={loading ? '-' :label} size="small" variant="main" />;
                                    break;
                            }

                            return (
                                <Tab
                                    key={index}
                                    label={status}
                                    value={status}
                                    icon={renderedChip}
                                    iconPosition="end"
                                />
                            )
                        })}
                    </Tabs>
                </Box>

                <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between"
                       sx={{padding: 2.5}}>
                    <Stack direction="row" spacing={2} alignItems="center">
                        <DebouncedInput
                            value={filters.globalFilter ?? ''}
                            name={'searchInUsers'}
                            id={'search-in-users'}
                            onFilterChange={setGlobalFilterHandler}
                            placeholder={`Type to search`}
                            size={'small'}
                        />
                    </Stack>
                </Stack>
            </>
            }

            <ScrollX>
                <TableContainer component={Paper}>
                    <Table
                        size="small"
                    >
                        <TableHead>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <TableRow key={headerGroup.id}>
                                    {headerGroup.headers.map((header) => {
                                        const canSort = header.column.getCanSort();
                                        const isSorted = header.column.getIsSorted();

                                        if (header.column.columnDef.meta !== undefined && canSort) {
                                            Object.assign(header.column.columnDef.meta, {
                                                className: header.column.columnDef.meta.className + ' cursor-pointer prevent-select'
                                            });
                                        }

                                        return (
                                            <TableCell
                                                key={header.id}
                                                {...header.column.columnDef.meta}
                                                onClick={header.column.getToggleSortingHandler()}
                                                {...(canSort &&
                                                    header.column.columnDef.meta === undefined && {
                                                        className: 'cursor-pointer prevent-select'
                                                    })}
                                            >
                                                {header.isPlaceholder ? null : (
                                                    <Stack direction="row" spacing={1} alignItems="center">
                                                        <Box
                                                            sx={{
                                                                cursor: 'pointer',
                                                                userSelect: 'none',
                                                                color: (isSorted === 'asc' && theme.palette.success.main) ||
                                                                    (isSorted === 'desc' && theme.palette.primary.main) ||
                                                                    'inherit'
                                                            }}
                                                        >
                                                            {flexRender(header.column.columnDef.header, header.getContext())}
                                                        </Box>
                                                        {canSort && <HeaderSort column={header.column} />}
                                                    </Stack>
                                                )}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            ))}
                        </TableHead>
                        {loading &&
                        <TableBody>
                            {[0, 1, 2].map((item) => (
                                <TableRow key={item}>
                                    {[0, 1, 2, 3, 4, 5, 6, 7].map((col) => (
                                        <TableCell key={col}>
                                            <Skeleton animation="wave" />
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                        }
                        {!loading &&
                        <TableBody>
                            {table.getRowModel().rows.map((row) => (
                                <React.Fragment key={row.id}>
                                    <TableRow
                                        sx={isList ? {
                                            borderBottom: '2px solid',
                                            borderBottomColor: bottomBorderColor
                                        } : {}}
                                    >
                                        {row.getVisibleCells().map((cell) => (
                                            <TableCell key={cell.id} {...cell.column.columnDef.meta}>
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                    {row.getIsExpanded() && (
                                        <TableRow
                                            sx={{bgcolor: backColor, '&:hover': {bgcolor: `${backColor} !important`}}}>
                                            <TableCell colSpan={row.getVisibleCells().length}>
                                                <RenderSubComponent {...{row, subColumns, filters, storesLoading}} />
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </React.Fragment>
                            ))}
                        </TableBody>
                        }
                    </Table>
                </TableContainer>
            </ScrollX>

            {data.length === 0 && !loading &&
                <NoItemsComponent
                    caption={`No ${isList ? 'Stores' : 'Users'} Found.`}
                    verticalMargin={32}
                />
            }

            {!isList &&
            <>
                <Divider/>
                <Box sx={{p: 2}}>
                    <TablePagination
                        paginationChangeHandler={paginationChangeHandler}
                        paginationObject={filters.pagination}
                    />
                </Box>
            </>
            }
        </MainCard>
    );
};

export default UsersTable;