import {authApi} from './apiService'

const getOrders = async (params) => authApi.get(`orders?${params}`);
const getOrderProducts = async (id) => authApi.get(`orders/${id}/products`);
const getOrder = async (id) => authApi.get(`orders/${id}`);
//const getAllUserOrders = async (userId, data) => authApi.get(`orders/user/${userId}`);
const storeOrder = async (data) => authApi.post(`orders/`, data);
const deleteOrder = async (id) => authApi.delete(`orders/${id}`);
const convertDraftOrderToCartItems = async (id, data) => authApi.post(`orders/${id}/convert`, data);
const updateOrderStatus = async (id, status) => authApi.patch(`orders/${id}/status`, {status: status});

const exportOrders = async (params) => authApi.get(`orders/export?${params}`,
    {
        headers: {
            accept: 'text/csv'
        },
        responseType: 'blob',
    });

const apiCalls = {
    getOrders,
    getOrderProducts,
    getOrder,
//    getAllUserOrders,
    storeOrder,
    deleteOrder,
    convertDraftOrderToCartItems,
    updateOrderStatus,
    exportOrders
};

export default apiCalls;