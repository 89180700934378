import { createSlice } from '@reduxjs/toolkit'
import {STATE_STATUSES} from "../../utils/helpers";

const filtersInitialState = {
    pagination: {
        page: 1,
        perPage: 25,
        total: 0,
        totalWasUpdated: false
    },

    sorting: [{id: 'created_at', desc: true}],
    orderStatus: null,
    globalFilter: null,
    startDate: null,
    endDate: null,
    storeIds: [],
    initialState: true
};

const initialState = {
    items: [],
    quantities: {
        open: 0,
        closed: 0
    },
    selectedItem: {},
    status: STATE_STATUSES.INIT,
    filters: {...filtersInitialState},
};

export const ordersSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {
        setPendingStatus: (state) => {
            state.status = STATE_STATUSES.PENDING;
        },

        setOrdersItems: (state, action) => {
            state.items = action.payload['orders'].data;

            state.quantities = action.payload['orders_quantities'].reduce((acc, curr) => {
                acc[curr.order_status] = curr.quantity;
                return acc;
            }, {...initialState.quantities});

            state.filters.pagination.totalWasUpdated =
                state.filters.pagination.total !== action.payload['orders'].total;
            state.filters.pagination.total = action.payload['orders'].total;
        },

        setReadyStatus: (state) => {
            state.status = STATE_STATUSES.READY;
        },

        setFilters: (state, action) => {
            if (action.payload.resetFilters) {
                state.filters = {...filtersInitialState};
            } else {
                state.filters = {
                    ...action.payload,
                    pagination: {
                        ...action.payload.pagination,
                        totalWasUpdated: false
                    }
                }
            }
        },

        setOrderProducts: (state, action) => {
            const orders = state.items.map(item => {
                if (item.id === action.payload.orderId) {
                    return {
                        ...item,
                        products: action.payload.products
                    }
                }
                return item;
            });

            state.items = [...orders];
        },

        setOrderItem: (state, action) => {
            state.selectedItem = action.payload.order;
        },
    },
});

export const {
    setPendingStatus,
    setReadyStatus,
    setOrdersItems,
    setFilters,
    setOrderProducts,
    setOrderItem,
} = ordersSlice.actions;

export default ordersSlice.reducer;