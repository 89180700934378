import cartService from "../../services/cartService";
import {
    setCartItems,
    setPendingStatus,
    setReadyStatus
} from "../reducers/cart";
import { dispatch } from "../index";

export const getCartItemsAction = async (activeStoreId, userId) => {
    dispatch(setPendingStatus());

    const response = await cartService.getCartItems(activeStoreId, userId);

    dispatch(setCartItems(response.data.data['cartItems']));
    dispatch(setReadyStatus());
};

export const createCartItemAction = async (data) => {
    dispatch(setPendingStatus());

    const response = await cartService.createCartItem(data);

    dispatch(setCartItems(response.data.data['cartItems']));
    dispatch(setReadyStatus());
};

export const updateCartItemAction = async (cartItemId, data) => {
    dispatch(setPendingStatus());

    const response = await cartService.updateCartItem(cartItemId, data);

    dispatch(setCartItems(response.data.data['cartItems']));
    dispatch(setReadyStatus());
};

export const deleteCartItemAction = async (cartItemId) => {
    dispatch(setPendingStatus());

    const response = await cartService.deleteCartItem(cartItemId);

    dispatch(setCartItems(response.data.data['cartItems']));
    dispatch(setReadyStatus());
};