import * as React from 'react';
import {generateOptionElement} from "../../../components/dynamicForms/utils";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Popover from "@mui/material/Popover";
import {getJsonObject} from "../../../utils/helpers";

const FeaturesAndOptions = ({ product, options, enteredOptions, optionChangeHandler }) => {
    const features = React.useMemo(() => getJsonObject(product?.features), [product?.features]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [tooltip, setTooltip] = React.useState({type: 'text', value: ''});

    const propertyNames = Object.keys(features);
    let arrayPropertyName = null;

    propertyNames.forEach(name => {
        if (Array.isArray(features[name])) {
            arrayPropertyName = name;
        }
    });

    return (
        <Box
            marginBottom={"16px"}
        >

            <Box
                margin={"30px 0"}
                component={'p'}
                sx={{
                    wordBreak: "break-word"
                }}
            >
                {product.description}
            </Box>

            {features && arrayPropertyName &&
            <Box>
                <Typography
                    variant={'h4'}
                    sx={{
                        marginBottom: '8px'
                    }}
                >
                {arrayPropertyName.charAt(0).toUpperCase() + arrayPropertyName.slice(1)}
                </Typography>
                <Box
                    component={'ul'}
                    paddingLeft={'16px'}
                >
                    {features[arrayPropertyName].map((feature) =>
                        (<Box
                            component={'li'}
                            key={feature}
                        >
                            {feature}
                        </Box>)
                    )}
                </Box>
            </Box>
            }

            {options &&
            <Grid container spacing={2}
                  sx={{
                      marginTop: '32px',
                      flexWrap: 'nowrap',
                      flexDirection: 'column'
                  }}
            >
                <Grid item xs={12}>
                    <Typography
                        variant={'h4'}
                        sx={{
                            marginBottom: '16px'
                        }}
                    >
                        Options
                    </Typography>
                </Grid>

                {options?.map((option) => (
                    <Grid item xs={12} sm={6}
                          key={option.name}
                          marginBottom={"16px"}
                    >
                        {generateOptionElement(option, enteredOptions, optionChangeHandler, setAnchorEl, setTooltip)}
                    </Grid>

                ))
                }
            </Grid>
            }

            {!!anchorEl &&
            <Popover
                open={true}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                {tooltip.type === 'img' ?
                    <Box component={'img'}
                         src={tooltip.value}
                         maxWidth={'300px'}
                         border={'1px solid black'}
                    />
                    :
                    <Typography sx={{ p: 2 }}>{tooltip.value}</Typography>
                }
            </Popover>
            }

        </Box>
    );
};

export default FeaturesAndOptions;