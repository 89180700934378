import * as React from 'react';
import {Typography} from "@mui/material";
import Stack from "@mui/material/Stack";
import useTheme from "@mui/material/styles/useTheme";

const SingleStoneParamItem = ({ title, value }) => {
    const theme = useTheme();

    if (Array.isArray(value)) return null;

    return (
        <Stack
            sx={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: 2
            }}
        >
            <Typography
                variant={'body2'}
                sx={{
                    color: theme.palette.secondary.main
                }}
            >
                {title}
            </Typography>
            <Typography>{value}</Typography>
        </Stack>
    );
};

export default SingleStoneParamItem;