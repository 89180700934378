import ordersService from "../../services/ordersService";
import {
    setPendingStatus,
    setReadyStatus,
    setOrdersItems,
    setOrderProducts,
    setOrderItem,
    setFilters,
} from "../reducers/orders";
import { dispatch } from "../index";

export const storeOrderAction = async (data) => {
    dispatch(setPendingStatus());

    await ordersService.storeOrder(data);

    dispatch(setReadyStatus());
};

export const getOrdersAction = async (data) => {
    dispatch(setPendingStatus());

    const response = await ordersService.getOrders(data);

    dispatch(setOrdersItems(response.data.data));
    dispatch(setReadyStatus());
};

export const getOrderProductsAction = async (orderId) => {
    dispatch(setPendingStatus());

    const response = await ordersService.getOrderProducts(orderId);

    dispatch(
        setOrderProducts({
            orderId,
            products: response.data.data['products']
        })
    );
    dispatch(setReadyStatus());
};

export const getOrderAction = async (orderId) => {
    dispatch(setPendingStatus());

    const response = await ordersService.getOrder(orderId);

    dispatch(
        setOrderItem({
            order: response.data.data['order']
        })
    );
    dispatch(setReadyStatus());
};

export const updateOrderStatusAction = async (id, status) => {
    dispatch(setPendingStatus());

    await ordersService.updateOrderStatus(id, status);

    dispatch(setReadyStatus());
};

export const convertDraftOrderToCartItemsAction = async (id, orderItemsIds = []) => {
    dispatch(setPendingStatus());

    await ordersService.convertDraftOrderToCartItems(id, {order_item_ids: orderItemsIds});

    dispatch(setReadyStatus());
};

export const deleteOrderAction = async (id) => {
    dispatch(setPendingStatus());

    await ordersService.deleteOrder(id);

    dispatch(setReadyStatus());
};

export const setFiltersAction = (data) => {
    dispatch(setFilters(data));
    dispatch(setReadyStatus());
};

export const exportOrdersAction = async (params) => {
    dispatch(setPendingStatus());

    const result = await ordersService.exportOrders(params);

    dispatch(setReadyStatus());

    return result;
};