import * as React from 'react';
import {Typography} from "@mui/material";

import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import {Link as RouterLink} from "react-router-dom";
import Box from "@mui/material/Box";
import {getCustomQuoteBackgroundColor} from "../../utils/helpers";

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

const SubmissionInfo = ({ currentUser, customQuote}) => {
    const customQuoteDate = customQuote.id ? dayjs.utc(customQuote.created_at).tz(currentUser.timezone).format('MM/DD/YYYY') : '-';

    return (
        <Paper
            variant={'outlined'}
        >
            <Typography
                variant="h6"
                sx={{
                    fontWeight: 600,
                    padding: '16px'
                }}
            >
                Submission ID: {customQuote.generated_id || '-'}
            </Typography>

            <Divider />

            <Stack
                sx={{
                    padding: '8px 16px'
                }}
                spacing={1}
            >
                <Stack>
                    <Typography color={'secondary'}>
                        Reference #
                    </Typography>
                    <Typography>
                        {customQuote.reference_number || '-'}
                    </Typography>
                </Stack>

                <Divider variant="middle" component="div" />

                <Stack>
                    <Typography color={'secondary'}>
                        Submit Date
                    </Typography>
                    <Typography>
                        {customQuoteDate}
                    </Typography>
                </Stack>

                <Divider variant="middle" component="div" />

                <Stack>
                    <Typography color={'secondary'}>
                        Submitted By
                    </Typography>
                    <Typography>
                        {customQuote.id ? `${customQuote.user?.first_name} ${customQuote.user?.last_name}` : '-'}
                    </Typography>
                </Stack>

                <Divider variant="middle" component="div" />

                <Stack>
                    <Typography color={'secondary'}>
                        Store Location
                    </Typography>
                    <Typography>
                        {customQuote.store ? customQuote.store.name : '-'}
                    </Typography>
                </Stack>

                <Divider variant="middle" component="div" />

                <Box>
                    <Typography color={'secondary'}>
                        Submission Status
                    </Typography>
                    <Box
                        sx={{
                            display: 'inline-block',
                            padding: '1px 4px',
                            backgroundColor: getCustomQuoteBackgroundColor(customQuote.status),
                            color: '#fff',
                            borderRadius: '4px',
                            textAlign: 'center',

                        }}
                    >
                        {customQuote.status?.split(' ')
                            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                            .join(' ')
                        }
                    </Box>
                </Box>

                <Divider variant="middle" component="div" />

                <Stack>
                    <Typography color={'secondary'}>
                        Placed Order ID
                    </Typography>
                    <Typography>
                        {customQuote.order_id ?
                            <Link
                                component={RouterLink}
                                to={`/order-details/${customQuote.order_id}`}
                            >
                            {customQuote.order?.generated_order_id}
                        </Link> : '-'
                        }
                    </Typography>
                </Stack>

            </Stack>
        </Paper>
    );
};

export default SubmissionInfo;