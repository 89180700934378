import * as React from 'react';
import Box from '@mui/material/Box';
import CartWidget from "./CartWidget/CartWidget";
import Header from "./Header";

const DefaultLayout = ({ marginTop = '100px', children }) => {
    const [isCartWidgetOpened, setIsCartWidgetOpened] = React.useState(false);

    return (
        <Box
            sx={{
                marginTop: {marginTop},
                height: `calc(100vh - ${marginTop} - 20px)`,
                overflow: 'auto',
                padding: '0 20px 20px 20px'
            }}
        >
            <Header setIsCartWidgetOpened={setIsCartWidgetOpened}/>

            <Box
                sx={{
                    height: '100%'
                }}
            >
                {children}
            </Box>

            <CartWidget
                isOpened={isCartWidgetOpened}
                setIsOpened={setIsCartWidgetOpened}
            />
        </Box>
    );
};

export default DefaultLayout;