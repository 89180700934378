import * as React from 'react';
import DefaultLayout from "../../components/layouts/DefaultLayout/DefaultLayout";
import StandardProductsList from "./components/StandardProductsList";
import GroupedProductList from "./components/GroupedProductsList";
import {getAllStoreProductsAction} from "../../store/actions/products"
import { useSelector } from "react-redux";

import Paper from "@mui/material/Paper";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from "@mui/material/Box";
import {STATE_STATUSES} from "../../utils/helpers";
import DebouncedInput from "../../components/DebouncedInput";
import { rankItem } from '@tanstack/match-sorter-utils';

import { Typography } from '@mui/material';
import Popper from "@mui/material/Popper";
import AlertComponent from "../../components/AlertComponent";

const analyzeProduct = (product, searchValue) => {
    const productProperties = [
        'title',
        'description',
        'sku',
        'status'
    ];

    if (!searchValue) {
        return true;
    }

    for (let i = 0; i < productProperties.length; i++) {
        const rankedItem = rankItem(product[productProperties[i]], searchValue);
        if (rankedItem.passed && rankedItem.rank > 1.2) {
            return true;
        }
    }

    return false
};

const splitProductsByCategories = (products) => {
    let categories = [];

    products.forEach(product => {
        if (!categories[product.category.name]) {
            categories[product.category.name] = [];
        }

        categories[product.category.name].push(product);
    });

    return categories;
};

const productSortingFunction = (products, sortingProperty, orderBySign) => {
    let newArray = JSON.parse(JSON.stringify(products));

    newArray.sort((a, b) => {
        if (a[sortingProperty] > b[sortingProperty]) {return 1 * orderBySign}
        if (a[sortingProperty] < b[sortingProperty]) {return -1 * orderBySign}
        return 0;
    });

    return newArray;
};

const ProductsPage = () => {
    const ref = React.useRef();
    const [subHeaderHeight, setSubHeaderHeight] = React.useState(0);
    const [listViewType, setListViewType] = React.useState('standard');
    const [filterByProductType, setFilterByProductType] = React.useState(null);
    const [sortingProperty, setSortingProperty] = React.useState('created_at:-1');
    const [fuzzyProductFilter, setFuzzyProductFilter] = React.useState(null);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [popoverText, setPopoverText] = React.useState('');

    const productsObject = useSelector(store => store.products);
    const activeStore = useSelector(store => store.stores.activeStore);

    const products = React.useMemo(() => productsObject.items || [], [productsObject]);
    const productsFuzzyFiltered = React.useMemo(() => products.filter(product => analyzeProduct(product, fuzzyProductFilter)),
        [products, fuzzyProductFilter]
    );

    const productTypes = React.useMemo(
        () => productsFuzzyFiltered.reduce(
            (acc, product) => {
                if (!acc.includes(product.type.name)) {
                    acc.push(product.type.name);
                }

                return acc;
            }, []
        ).sort(), [productsFuzzyFiltered]
    );

    const filteredProductsByProductType = filterByProductType ? productsFuzzyFiltered.filter((product) => product.type.name === filterByProductType) : productsFuzzyFiltered;

    React.useEffect(() => {
        const getProducts = async () => {
            try {
                await getAllStoreProductsAction(activeStore.id);
            } catch (e) {
                AlertComponent.error(e.response.data.message);
            }
        };

        getProducts();

    }, [activeStore.id]);

    React.useEffect(() => {
        setFilterByProductType(null);

    }, [activeStore.id]);

    React.useEffect(() => {
        const element = ref?.current;

        if (element) {
            setSubHeaderHeight(element.offsetHeight);
        }
    }, [listViewType]);

    React.useEffect(() => {
        const handleResize = () => {
            const element = ref?.current;

            if (element && element.offsetHeight !== subHeaderHeight) {
                setSubHeaderHeight(element.offsetHeight);
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [subHeaderHeight]);

    let ProductListComponent;

    if (listViewType !== 'standard') {
        ProductListComponent = <GroupedProductList
            categoriesWithProducts={splitProductsByCategories(filteredProductsByProductType)}
            isProductsLoaded={productsObject.status === STATE_STATUSES.READY}
            setAnchorEl={setAnchorEl}
            setPopoverText={setPopoverText}
        />;
    } else {
        const sortingParams = sortingProperty.split(':');

        ProductListComponent = <StandardProductsList
            products={productSortingFunction(filteredProductsByProductType, sortingParams[0], +sortingParams[1])}
            isProductsLoaded={productsObject.status === STATE_STATUSES.READY}
            setAnchorEl={setAnchorEl}
            setPopoverText={setPopoverText}
        />
    }

    return (
        <DefaultLayout marginTop={ref.current?.offsetHeight + 101 + 'px'}>
                <Paper
                    ref={ref}
                    sx={{
                        position: "fixed",
                        left: "20px",
                        right: "20px",
                        top: "100px",
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        justifyContent: "space-between",
                        padding: "8px 16px",
                        boxShadow: "1px 1px 1px -1px rgba(0,0,0,0.5), -1px -1px 1px -1px rgba(0,0,0,0.5)"
                    }}
                >
                    <FormControl
                        sx={{
                            margin: "5px",
                            width: "200px",
                            minWidth: "200px",
                        }}
                        size="small"
                    >
                        <Select
                            value={listViewType}
                            name={'listViewType'}
                            id={'list-view-type'}
                            onChange={(event) => {
                                setListViewType(event.target.value);
                                setFilterByProductType(null);
                                setSortingProperty('sku:1');
                            }}
                        >
                            <MenuItem value={'linesheet'}>Linesheet View</MenuItem>
                            <MenuItem value={'standard'}>Standard View</MenuItem>
                        </Select>
                    </FormControl>

                    <Box
                        display={"flex"}
                        flexWrap={"wrap"}
                        alignItems={"center"}
                    >
                        <DebouncedInput
                            size={'small'}
                            value={fuzzyProductFilter ?? ''}
                            onFilterChange={value => setFuzzyProductFilter(value)}
                            placeholder={`Type to search`}
                            id={'search-products'}
                            sx={{
                                margin: "5px",
                                height: "2.8em",
                                width: "200px",
                                minWidth: "200px",
                            }}
                        />

                        {listViewType === 'standard' &&
                            <Box
                                display={"flex"}
                                flexWrap={"wrap"}
                                alignItems={"center"}
                            >

                                <Autocomplete
                                    size="small"
                                    options={productTypes}
                                    value={filterByProductType}
                                    onChange={(event, newValue) => setFilterByProductType(newValue)}
                                    renderInput={(params) => <TextField {...params} label="Filter By Category"/>}
                                    sx={{
                                        margin: "5px",
                                        width: "200px",
                                        minWidth: "200px",
                                        '& label.MuiInputLabel-sizeSmall': {
                                            lineHeight: '2em',
                                            fontSize: '0.75rem'
                                        }
                                    }}
                                />

                                <FormControl
                                    sx={{
                                        margin: "5px",
                                        width: "200px",
                                        minWidth: "200px",
                                    }}
                                    size="small"
                                >
                                    <InputLabel htmlFor="sort-by">Sort by</InputLabel>
                                    <Select
                                        value={sortingProperty}
                                        name="sortBy"
                                        id="sort-by"
                                        onChange={(event) => {
                                            setSortingProperty(event.target.value);
                                        }}
                                    >
                                        <MenuItem value={'sku:1'}>Product Style A-Z</MenuItem>
                                        <MenuItem value={'sku:-1'}>Product Style Z-A</MenuItem>
                                        <MenuItem value={'created_at:1'}>Oldest to Newest</MenuItem>
                                        <MenuItem value={'created_at:-1'}>Newest to Oldest</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        }
                    </Box>
                </Paper>

            { ProductListComponent }

            {anchorEl &&
                <Popper
                    open={true}
                    anchorEl={anchorEl}
                    sx={{
                        maxWidth: '550px'
                    }}
                >
                    <Box sx={{ border: 1, p: 1, bgcolor: 'white'}}>
                        <Typography
                            sx={{
                                p: 1
                            }}
                        >
                            {popoverText}
                        </Typography>
                    </Box>
                </Popper>
            }

        </DefaultLayout>
    )
};

export default ProductsPage;
