import * as React from 'react';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';


const DatePickerComponent = ({ state, option, onChangeHandler, stateFunction }) => {
    dayjs.extend(customParseFormat);

    const value = dayjs(state[option.name] || '01/31/2024', 'MM/DD/YYYY');

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                sx={{
                    width: '100%'
                }}
                slotProps={{ textField: { size: 'small' } }}
                label={option.label || "Select Date"}
                value={value}
                format="MM/DD/YYYY"
                onChange={
                    (newValue) => {
                        onChangeHandler(option, {target: {value: newValue.format('MM/DD/YYYY')}}, stateFunction)
                    }
                }
            />
        </LocalizationProvider>
    );
};

export default DatePickerComponent;