import * as React from 'react';
import PropTypes from 'prop-types';
// third-party
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';

import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const textPrimary = '#262626';
const textSecondary = '#8c8c8c';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row'
  },
  image: {
    width: 100,
    height: 100
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'stretch'
  },
  title: {
    color: textPrimary,
    fontSize: '10px',
    fontWeight: 'bold'
  },
  barcode: {
    width: 110,
    height: 40,
  },
  rowHeightCentered: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },

  table: {
    display: "table",
    width: "auto",
    minWidth: "180px",
    fontSize: '10px'
  },

  tableRow: {
    flexDirection: "row",
    marginTop: "5px"
  },

  tableCol: {
  },

  tableCell: {
    color: textSecondary,
  },

  tableCellRight: {
    fontWeight: 'bold',
    color: textPrimary,
    width: '70px',
    textAlign: 'right',
    marginRight: '5px',
  }
});

export default function Header({ logo, order, orderIdBarcodeData, userTimezone }) {
  return (
    <View style={styles.mainContainer}>
      <Image src={logo} style={styles.image} />

      <View>
        <View style={styles.rowHeightCentered}>
          <Text style={styles.title}>Order ID</Text>
            {orderIdBarcodeData && <Image src={orderIdBarcodeData} style={styles.barcode} />}
        </View>


        <View style={styles.table}>

          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellRight}>Reference #</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{order?.reference_number}</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellRight}>Order Date</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{order && dayjs.utc(order.created_at).tz(userTimezone).format('MMMM D, YYYY')}</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellRight}>Ordered By</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{order?.user_name}</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellRight}>Store Location</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{order?.store_name}</Text>
            </View>
          </View>

        </View>

      </View>
    </View>
  );
}

Header.propTypes = { order: PropTypes.any };
