import * as React from "react";
import {StoreOutlined} from "@mui/icons-material";
import {useSelector} from "react-redux";
import MenuWrapper from "../../MenuWrapper";
import MenuItem from "@mui/material/MenuItem";
import {setActiveStoreAction} from "../../../store/actions/user";
import {getAllUserStoresAction} from "../../../store/actions/stores";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {getJsonObject} from "../../../utils/helpers";
import AlertComponent from "../../AlertComponent";

const StoresSelector = ( {currentUserId} ) => {
    const navigate = useNavigate();
    const { activeStore, items: storesList } = useSelector(store => store.stores);

    const [anchorStoresElement, setAnchorStoresElement] = React.useState(null);

    React.useEffect(() => {
        const getAllStores = async () => {
            try {
                await getAllUserStoresAction(currentUserId);
            } catch (e) {
                AlertComponent.error(e.response.data.message);
            }
        };

        getAllStores();
    }, [currentUserId]);

    const handleOpenStoresMenu = (event) => {
        setAnchorStoresElement(event.currentTarget);
    };

    const handleStoreItemClick = async (storeId) => {
        try {
            await setActiveStoreAction(storeId);
            navigate('/');
        } catch (e) {
            AlertComponent.error(e.response.data.message);
        }
    };

    const handleCloseStoresMenu = () => {
        setAnchorStoresElement(null);
    };

    return (
        <>
            <Tooltip title="Change active store">
                <Button
                    size="large"
                    aria-label="Change active store"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenStoresMenu}
                    color='inherit'
                >
                    <StoreOutlined
                        sx={{
                            mx: 0.5
                        }}
                    />
                    {activeStore.name}
                </Button>
            </Tooltip>

            <MenuWrapper
                anchorElement={anchorStoresElement}
                closeHandler={handleCloseStoresMenu}
                minWidth={"200px"}
                horizontalAlign={'left'}
            >
                <MenuItem
                    disabled={true}
                >
                    <Box
                        fontStyle={'italic'}
                    >
                        Select store to switch view
                    </Box>
                </MenuItem>

                {storesList.map(item => {
                    const address = getJsonObject(item.shipping_address);
                    const addressString =
                        address.address_line_1 +
                        (address.address_line_2 ? ', ' + address.address_line_2 : '') + '    ' +
                        address.city + ', ' +
                        address.state;

                    return (
                        <MenuItem
                            key={item.name}
                            onClick={() => handleStoreItemClick(item.id)}
                            selected={activeStore.id === item.id}
                            sx={{
                                flexWrap: 'wrap'
                            }}
                        >
                            <Box
                                fontWeight={activeStore.id === item.id ? '600' : '400'}
                                width={'100%'}
                            >
                                {item.name}
                            </Box>
                            <Typography
                                color={'secondary'}
                                sx={{
                                    whiteSpace: 'pre'
                                }}
                            >
                                {addressString}
                            </Typography>
                        </MenuItem>
                    )
                })}
            </MenuWrapper>
        </>
    )
};

export default StoresSelector;