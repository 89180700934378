import * as React from 'react';
import TableBody from "@mui/material/TableBody";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

const TableBodySkeleton = () => {
    return (
        <TableBody>
            <Stack direction="column" spacing={2} padding={2}>
                {[1, 2, 3].map((item) => {
                    return (
                        <Skeleton
                            key={item}
                            variant="rounded"
                            animation="wave"
                            width={"100%"}
                            height={"100px"}
                        />
                    );
                })}
            </Stack>
        </TableBody>
    )
};

export default TableBodySkeleton;