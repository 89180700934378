import { createSlice } from '@reduxjs/toolkit'
import {STATE_STATUSES} from "../../utils/helpers";

const initialState = {
    items: [],
    selectedItem: {},
    status: STATE_STATUSES.INIT,
};

export const productsSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        setPendingStatus: (state) => {
            state.status = STATE_STATUSES.PENDING;
        },

        setReadyStatus: (state) => {
            state.status = STATE_STATUSES.READY;
        },

        setProducts: (state, action) => {
            state.items = action.payload;
        },

        setSelectedProduct: (state, action) => {
            state.selectedItem = action.payload;
        }
    },
});

export const {
    setPendingStatus,
    setReadyStatus,
    setProducts,
    setSelectedProduct
} = productsSlice.actions;

export default productsSlice.reducer;