import React from 'react';
import ReactDOM from 'react-dom/client';
import {store} from './store';
import { Provider } from 'react-redux';
import AuthProvider from './providers/authProvider';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const setInitialFavicon = () => {
    const faviconUrl = process.env.REACT_APP_FAVICON_IMAGE_URL || '/favicon.ico';
    try {
        const link = document.createElement('link');
        link.rel = 'icon';
        link.type = 'image/png';
        link.href = faviconUrl;
        document.head.appendChild(link);

        const linkApple = document.createElement('link');
        linkApple.rel = 'apple-touch-icon';
        linkApple.href = faviconUrl;
        document.head.appendChild(linkApple);
    } catch (error) {
        console.log('Favicon недоступен, используем стандартную иконку.');
    }
};

setInitialFavicon();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
        <Provider store={store}>
            <AuthProvider>
                <App />
            </AuthProvider>
        </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

