import customQuotesService from "../../services/customQuotesService";
import {
    setPendingStatus,
    setReadyStatus,
    setCustomQuotesItems,
    setCustomQuoteItem,
    setFilters,
    setFormsConfig,
    setFeedbacks
} from "../reducers/customQuotes";
import { dispatch } from "../index";

export const storeCustomQuoteAction = async (data) => {
    dispatch(setPendingStatus());

    await customQuotesService.storeCustomQuote(data);

    dispatch(setReadyStatus());
};

export const getCustomQuotesAction = async (data) => {
    dispatch(setPendingStatus());

    const response = await customQuotesService.getCustomQuotes(data);

    dispatch(setCustomQuotesItems(response.data.data));
    dispatch(setReadyStatus());
};

export const getCustomQuoteAction = async (customQuoteId) => {
    dispatch(setPendingStatus());
    dispatch(setFeedbacks({payload: {feedbacks: []}}));

    const response = await customQuotesService.getCustomQuote(customQuoteId);

    dispatch(
        setCustomQuoteItem({
            custom_quote: response.data.data['custom_quote']
        })
    );
    dispatch(setReadyStatus());
};

export const convertCustomQuoteToOrderAction = async (quoteId, data) => {
    dispatch(setPendingStatus());

    await customQuotesService.convertCustomQuoteToOrder(quoteId, data);

    dispatch(setReadyStatus());
};

export const cancelCustomQuoteAction = async (id) => {
    dispatch(setPendingStatus());

    await customQuotesService.cancelCustomQuote(id);

    dispatch(setReadyStatus());
};

export const getCustomQuoteFormsConfigAction = async () => {
    dispatch(setPendingStatus());

    const response = await customQuotesService.getCustomQuoteFormsConfig();

    dispatch(setFormsConfig(response.data.data));
    dispatch(setReadyStatus());
};

export const setFiltersAction = (data) => {
    dispatch(setFilters(data));
    dispatch(setReadyStatus());
};

export const getCustomQuoteVersionFeedbacksAction = async (quoteId, versionId) => {
    dispatch(setPendingStatus());

    const response = await customQuotesService.getCustomQuoteVersionFeedbacks(quoteId, versionId);
    dispatch(setFeedbacks(response.data.data));
    dispatch(setReadyStatus());
};

export const storeCustomQuoteVersionFeedbackAction = async (quoteId, versionId, data) => {
    dispatch(setPendingStatus());
    await customQuotesService.storeCustomQuoteVersionFeedback(quoteId, versionId, data);
    dispatch(setReadyStatus());
};

export const getPresentationLinkAction = async (quoteId, versionId, forceRefresh = false) => {
    const response = await customQuotesService.getPresentationLink(quoteId, versionId, forceRefresh);
    return response.data.data;
};