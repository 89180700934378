import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import {Formik} from "formik";
import * as Yup from "yup";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Card from "@mui/material/Card";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
//import Divider from "@mui/material/Divider";
import useTheme from "@mui/material/styles/useTheme";
import {useSelector} from "react-redux";
import {
    createUserAction,
    getAvailableStoresAction,
    getRolesAction,
    updateUserAction
} from "../../store/actions/settings";
import {ROLE_NAMES} from "../../utils/helpers";
import AlertComponent from "../../components/AlertComponent";

export default function UserProfilePopup({ selectedUserId, successHandler, openUserForm, handleClose, getUsersFunction}) {
    const formRef = React.useRef(null);
    const roles = useSelector(store => store.settings.roles);
    const users = useSelector(store => store.settings.users);
    const availableStores = useSelector(store => store.settings.availableStores);

    const helpElementRef = React.useRef(null);

    const theme = useTheme();

    React.useEffect(() => {
        const getRoles = async () => {
            try {
                await getRolesAction();
            } catch (e) {
                AlertComponent.error(e.response.data.message);
            }
        };

        if (roles?.length === 0) {
            getRoles();
        }
    }, [roles]);

    React.useEffect(() => {
        const getAvailableStores = async () => {
            try {
                await getAvailableStoresAction();
            } catch (e) {
                AlertComponent.error(e.response.data.message);
            }
        };

        if (availableStores?.length === 0) {
            getAvailableStores();
        }
    }, [availableStores]);

    const selectedUser = users.find(user => user.id === selectedUserId);

    const initialValues = selectedUserId === 0 ? {
            first_name: '',
            last_name: '',
            email: '',
            employee_code: '',
            status: 'active',
            role_id: roles?.find(item => item.name === ROLE_NAMES.COMPANY_ADMIN)?.id,
            store_ids: []
        } :
        {
            ...selectedUser,
            store_ids: selectedUser?.stores?.map(store => store.id)
    };

    return (
        <Dialog
            aria-labelledby="customized-dialog-title"
            open={openUserForm}
        >
            <DialogTitle sx={{ m: 0, p: 2, fontWeight: 700 }} id="customized-dialog-title">
                {selectedUserId === 0 ? 'New User' : 'Edit User'}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                <Formik
                    initialValues={initialValues}
                    validationSchema={Yup.object().shape({
                        first_name: Yup.string().max(255).required('First name is required'),
                        last_name: Yup.string().max(255).required('Last name is required'),
                        email: Yup.string().max(255).email('Must be a valid email').required('Email is required'),
                        employee_code: Yup.string().notRequired().max(255).matches(/^[a-zA-Z0-9]*$/, 'The employee code should be alphanumeric'),
                        store_ids: Yup.array().min(1, 'The user should be assigned with one or more store locations').required('The user should be assigned with one or more store locations')

                    })}

                    onSubmit={async (values) => {
                        try {
                            if (selectedUserId === 0) {
                                await createUserAction(values);
                            } else {
                                await updateUserAction(selectedUserId, values);
                            }

                            await getUsersFunction();

                            successHandler(`User ${values.first_name} ${values.last_name} Successfully ${selectedUserId ? 'Updated' : 'Created'}.`);
                            handleClose();
                        } catch (e) {
                            AlertComponent.error(e.response.data.message);
                        }
                    }}
                >
                    {({ errors, setFieldValue, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => {
                        return (
                            <form noValidate onSubmit={handleSubmit} ref={formRef}>
                                <Stack spacing={2}>
                                    <Card
                                        sx={{
                                            padding: '16px'
                                        }}
                                    >
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Stack spacing={1}>
                                                    <InputLabel htmlFor="first-name">First name</InputLabel>
                                                    <OutlinedInput
                                                        fullWidth
                                                        error={Boolean(touched.first_name && errors.first_name)}
                                                        id="first-name"
                                                        type={'text'}
                                                        value={values.first_name}
                                                        name="first_name"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        placeholder="Enter first name..."
                                                    />
                                                </Stack>
                                                {touched.first_name && errors.first_name && (
                                                    <FormHelperText error id="helper-text-first-name">
                                                        {errors.first_name}
                                                    </FormHelperText>
                                                )}
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Stack spacing={1}>
                                                    <InputLabel htmlFor="last-name">Last name</InputLabel>
                                                    <OutlinedInput
                                                        fullWidth
                                                        error={Boolean(touched.last_name && errors.last_name)}
                                                        id="last-name"
                                                        type={'text'}
                                                        value={values.last_name}
                                                        name="last_name"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        placeholder="Enter last name..."
                                                    />
                                                </Stack>
                                                {touched.last_name && errors.last_name && (
                                                    <FormHelperText error id="helper-text-last-name">
                                                        {errors.last_name}
                                                    </FormHelperText>
                                                )}
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Stack spacing={1}>
                                                    <InputLabel htmlFor="email">Email</InputLabel>
                                                    <OutlinedInput
                                                        fullWidth
                                                        error={Boolean(touched.email && errors.email)}
                                                        id="email"
                                                        type={'text'}
                                                        value={values.email}
                                                        name="email"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        placeholder="Enter email..."
                                                    />
                                                </Stack>
                                                {touched.email && errors.email && (
                                                    <FormHelperText error id="helper-text-email">
                                                        {errors.email}
                                                    </FormHelperText>
                                                )}
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Stack spacing={1}>
                                                    <InputLabel htmlFor="employee-code">Employee Code</InputLabel>
                                                    <OutlinedInput
                                                        fullWidth
                                                        error={Boolean(touched.employee_code && errors.employee_code)}
                                                        id="employee-code"
                                                        type={'text'}
                                                        value={values.employee_code || ''}
                                                        name="employee_code"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        placeholder="Enter employee code..."
                                                    />
                                                </Stack>
                                                {touched.employee_code && errors.employee_code && (
                                                    <FormHelperText error id="helper-text-employee-code">
                                                        {errors.employee_code}
                                                    </FormHelperText>
                                                )}
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Stack spacing={1}>
                                                    <div
                                                        ref={helpElementRef}
                                                    />

                                                    <InputLabel htmlFor="role-type">
                                                        Role Type
                                                    </InputLabel>
                                                    <Select
                                                        id="role-id"
                                                        value={values.role_id}
                                                        onChange={(event) => {
                                                            setFieldValue('role_id', event.target.value);
                                                        }}
                                                        onBlur={handleBlur}
                                                        renderValue={(option) => roles.find(item => item.id === option).name}

                                                        MenuProps={{
                                                            PaperProps: {
                                                                style: {
                                                                    width: `${helpElementRef?.current?.offsetWidth || 10}px`,
                                                                },
                                                            }
                                                        }}
                                                    >
                                                        {roles.map(item => {
                                                                if ((!selectedUser || selectedUser.role.name !== ROLE_NAMES.GLOBAL_ADMIN) && item.name === 'Global Admin') {
                                                                    return null;
                                                                }

                                                                return (
                                                                    <MenuItem
                                                                        key={item.id}
                                                                        value={item.id}
                                                                    >
                                                                        <div>
                                                                            <Typography variant={'h5'}>{item.name}</Typography>
                                                                            <Box
                                                                                sx={{
                                                                                    fontSize: '0.9em',
                                                                                    color: theme.palette.text.secondary,
                                                                                    whiteSpace: 'normal'
                                                                                }}
                                                                            >
                                                                                {item.description}
                                                                            </Box>
                                                                        </div>
                                                                    </MenuItem>
                                                                )
                                                            }
                                                        )}
                                                    </Select>
                                                </Stack>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Stack spacing={1}>
                                                    <InputLabel htmlFor={"store-ids"}>Store Location Access</InputLabel>
                                                    <Select
                                                        id="store-ids"
                                                        name={'store_ids'}
                                                        multiple
                                                        value={values.store_ids}
                                                        onChange={(event) => {
                                                            const value = event.target.value;
                                                            setFieldValue('store_ids', typeof value === 'string' ? value.split(',') : value);
                                                        }}
                                                        onBlur={handleBlur}
                                                        input={<OutlinedInput />}
                                                        renderValue={(selected) => (
                                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                                {selected.map((value) => {
                                                                    const storeName = availableStores.find((item) => item.id === value).name;
                                                                    return (<Chip key={value} label={storeName} />)
                                                                }
                                                                )}
                                                            </Box>
                                                        )}
                                                        MenuProps={{
                                                            PaperProps: {
                                                                style: {
                                                                    width: `${helpElementRef?.current?.offsetWidth}px`,
                                                                },
                                                            }
                                                        }}
                                                    >
                                                        {availableStores.map(item => (
                                                                <MenuItem
                                                                    key={item.id}
                                                                    value={item.id}
                                                                >
                                                                    <div>
                                                                        <Typography variant={'h5'}>{item.name}</Typography>
                                                                        <Box
                                                                            sx={{
                                                                                fontSize: '0.9em',
                                                                                color: theme.palette.text.secondary,
                                                                                whiteSpace: 'normal'
                                                                            }}
                                                                        >
                                                                            {item.description}
                                                                        </Box>
                                                                    </div>
                                                                </MenuItem>
                                                            )
                                                        )}

                                                    </Select>
                                                    {touched.store_ids && errors.store_ids && (
                                                        <FormHelperText error id="helper-text-store-ids">
                                                            {errors.store_ids}
                                                        </FormHelperText>
                                                    )}
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                    <Card
                                        sx={{
                                            padding: '16px'
                                        }}
                                    >
                                        <Stack>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={(values.status || 'active') === 'active'}
                                                        disabled={(values.status || '') === 'archived'}
                                                        onChange={(event) => setFieldValue('status', event.target.checked ? 'active' : 'disabled')}
                                                    />
                                                }
                                                label={<Box>Disable / Enable User</Box>}
                                                name='status'
                                                labelPlacement="start"
                                                sx={{
                                                    justifyContent: 'space-between',
                                                    marginLeft: 0,
                                                    '& .MuiFormControlLabel-label': {
                                                        marginLeft: 0
                                                    }
                                                }}
                                            />
                                            <Typography
                                                variant={'body1'}
                                                color="text.secondary"
                                                sx={{
                                                    width: '85%'
                                                }}
                                            >
                                                Activate or deactivate system user. Enabling grant access while disabling revokes it. Select the appropriate option to manage access.
                                            </Typography>

                                            {/*<Divider/>*/}

                                            {/*<FormControlLabel*/}
                                                {/*control={*/}
                                                    {/*<Switch*/}
                                                        {/*checked={(values.status || '') === 'archived'}*/}
                                                        {/*disabled={(values.status || 'active') === 'active'}*/}
                                                        {/*onChange={(event) => setFieldValue('status', event.target.checked ? 'archived' : 'disabled')}*/}
                                                    {/*/>*/}
                                                {/*}*/}
                                                {/*label={<Box>Archive User?</Box>}*/}
                                                {/*labelPlacement="start"*/}
                                                {/*sx={{*/}
                                                    {/*justifyContent: 'space-between',*/}
                                                    {/*marginLeft: 0,*/}
                                                    {/*'& .MuiFormControlLabel-label': {*/}
                                                        {/*marginLeft: 0*/}
                                                    {/*}*/}
                                                {/*}}*/}
                                            {/*/>*/}
                                            {/*<Typography*/}
                                                {/*variant={'body1'}*/}
                                                {/*color="text.secondary"*/}
                                                {/*sx={{*/}
                                                    {/*width: '85%'*/}
                                                {/*}}*/}
                                            {/*>*/}
                                                {/*Archive this user to remove him from active views. Only disabled users can be archived.*/}
                                            {/*</Typography>*/}
                                        </Stack>
                                    </Card>
                                </Stack>
                            </form>
                        )
                    }}
                </Formik>
            </DialogContent>
            <DialogActions
                sx={{
                    padding: '16px'
                }}
            >
                <Button
                    color={'error'}
                    onClick={handleClose}
                >
                    Cancel
                </Button>
                <Button
                    autoFocus
                    type="submit"
                    form="formik-form"
                    variant={"contained"}
                    onClick={() => formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))}
                >
                    {selectedUserId === 0 ? 'Create' : 'Save'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}