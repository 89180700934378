import * as React from "react";
import OrdersList from "./OrdersList";
import DefaultLayout from "../../components/layouts/DefaultLayout/DefaultLayout";

const DraftOrdersPage = () => {
    return (
        <DefaultLayout>
            <OrdersList
                caption={"Draft Orders List"}
                selectDrafts={true}
            />
        </DefaultLayout>
    );
};

export default DraftOrdersPage;