import * as React from 'react';
import Box from '@mui/material/Box';
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {Typography} from "@mui/material";
import * as Yup from "yup";
import {Formik} from "formik";
import FormHelperText from "@mui/material/FormHelperText";
import ShippingAddressFields from "../../../components/ShippingAddressFields";

const CustomerDeliveryAddress = ({ closeHandler, customerData, setCustomerDataHandler }) => {
    const initialValues = {
        first_name: customerData?.first_name || '',
        last_name: customerData?.last_name || '',
        address_line_1: customerData?.address?.address_line_1 || '',
        address_line_2: customerData?.address?.address_line_2 || '',
        city: customerData?.address?.city || '',
        state: customerData?.address?.state || '',
        postal_code: customerData?.address?.postal_code || '',
        country: customerData?.address?.country || 'USA'
    };

    return (
        <Dialog
            open={true}
            onClose={closeHandler}
        >
            <Typography
                variant={'h2'}
                sx={{
                    textAlign: 'center',
                    marginTop: '16px'
                }}
            >
                Customer shipping info
            </Typography>

            <Formik
                initialValues={initialValues}
                validationSchema={Yup.object().shape({
                    first_name: Yup.string().max(255).required('First name is required'),
                    last_name: Yup.string().max(255).required('Last name is required'),
                    address_line_1: Yup.string().max(255).required('Street Address 1 is required'),
                    address_line_2: Yup.string().notRequired().max(255),
                    city: Yup.string().max(255).required('City is required'),
                    state: Yup.string().max(255).required('State is required'),
                    postal_code: Yup.string()
                        .matches(/^\d{5}$/, 'Postal code must be exactly 5 digits')
                        .required('Postal code is required'),
                    country: Yup.string().max(255).required('Country is required'),
                })}

                onSubmit={async (values) => {
                    setCustomerDataHandler({
                        first_name: values.first_name,
                        last_name: values.last_name,
                        address: {
                            address_line_1: values.address_line_1,
                            address_line_2: values.address_line_2,
                            city: values.city,
                            state: values.state,
                            postal_code: values.postal_code,
                            country: values.country
                        }
                    });

                    closeHandler();
                }}
            >
                {({ errors, setFieldValue, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <Box
                                padding={'32px'}
                            >
                                <Grid container columnSpacing={2} rowSpacing={4}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            sx={{
                                                width: '100%'
                                            }}
                                            required
                                            error={touched.first_name && Boolean(errors.first_name)}
                                            label="First name"
                                            name="first_name"
                                            value={values.first_name}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder="Enter first name..."
                                        />
                                        {touched.first_name && errors.first_name && (
                                            <FormHelperText error id="helper-text-first-name">
                                                {errors.first_name}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            sx={{
                                                width: '100%'
                                            }}
                                            required
                                            error={touched.last_name && Boolean(errors.last_name)}
                                            label="Last name"
                                            name="last_name"
                                            value={values.last_name}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder="Enter last name..."
                                        />
                                        {touched.last_name && errors.last_name && (
                                            <FormHelperText error id="helper-text-last-name">
                                                {errors.last_name}
                                            </FormHelperText>
                                        )}
                                    </Grid>

                                    <ShippingAddressFields
                                        values={values}
                                        errors={errors}
                                        touched={touched}
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                    />

                                </Grid>
                            </Box>

                            <Box
                                margin={'0 auto 32px auto'}
                                textAlign={'center'}
                            >
                                <Button
                                    sx={{
                                        width: '100px'
                                    }}
                                    variant="contained"
                                    type={'submit'}
                                >
                                    Submit
                                </Button>
                            </Box>
                        </form>
                    )
                }}
            </Formik>
        </Dialog>
    );
};

export default CustomerDeliveryAddress;